<template>
    <div class="qr-container">
        <vue-title :title="title" :brand="true"></vue-title>
        <video ref="qrFeed">

        </video>

        <div class="qr-overlay">

        </div>

        <div class="qr-tools">
            <div class="button-group">
                <button :class="{'active': mode == 'scanner'}"
                        @click="mode = 'scanner'">
                    Scan
                </button>
                <button :class="{'active': mode == 'check'}"
                        @click="mode = 'check'">
                    Check
                </button>
                <button @click="switchToLeadMode">
                    Scan List
                </button>
            </div>
        </div>

        <div class="qr-bottom-tools">
            <select class="form-select"
                    v-model="selectedSessionCode">
                <option :value="null">(Select Session...)</option>
                <optgroup v-for="sessionGroup in sessionGroups" 
                          :label="sessionGroup.Name"
                          :key="sessionGroup.Code">
                    <option v-for="session in sessionGroup.Sessions" 
                            :value="session.Code"
                            :key="session.Code">
                        {{ $filters.moment(session.StartDateTime, 'MMM DD h:mm a')}} - {{session.Name}} ({{ session.Duration }})
                    </option>
                </optgroup>
            </select>

            <div class="button-group"
                 v-if="requiresTwoScans">
                <button :class="{'active': scanMode == 'CheckIn'}"
                        @click="scanMode = 'CheckIn'">
                    In
                </button>
                <button :class="{'active': scanMode == 'CheckOut'}"
                        @click="scanMode = 'CheckOut'">
                    Out
                </button>
            </div>            
        </div>

        <div class="qr-loading"
             v-show="submitting">
            <loading>

            </loading>
        </div>

        <div class="qr-check"
             v-if="qrCheckResults">
            
            <div class="qr-check-results">
                <h4>{{ qrCheckResults.Title }}</h4>
                <h5 v-if="qrCheckResults.CheckInTime">
                    ✅ Checked in at {{ qrCheckResults.CheckInTime }}.
                </h5>
                <h5 v-else>
                    ❌ No Check In Scan Recorded
                </h5>
                <h5 v-if="qrCheckResults.CheckOutTime && requiresTwoScans">
                    ✅ Checked out at {{ qrCheckResults.CheckInTime }}.
                </h5>
                <h5 v-else-if="!qrCheckResults.CheckOutTime && requiresTwoScans">
                    ❌ No Check Out Scan Recorded
                </h5>
                <button @click="qrCheckResults = null">
                    Close
                </button>
            </div>
        </div>

        <div class="search-box-overlay"
             v-if="mode == 'leads'">
            <div class="search-box">
                <span class="icomoon">
                    
                </span>
                <input placeholder="Search" 
                       v-model="searchText"
                    ref="searchInput"
                    type="text"/>
            </div>
        </div>

        <div v-if="mode == 'leads'"
                class="lead-list">
                <div class="header-actions">
                    <select class="form-select"
                            v-model="selectedSessionCode">
                        <optgroup v-for="sessionGroup in sessionGroups" 
                                :label="sessionGroup.Name"
                                :key="sessionGroup.Code">
                            <option v-for="session in sessionGroup.Sessions" 
                                    :value="session.Code"
                                    :key="session.Code">
                                {{ $filters.moment(session.StartDateTime, 'MMM DD h:mm a')}} - {{session.Name}} ({{ session.Duration }})
                            </option>
                        </optgroup>
                    </select>                
                    <div class="actions">
                        <button class="icomoon" @click="exportToCsv">
                            
                        </button>
                        <button class="icomoon" @click="switchToScanMode">
                            
                        </button>
                    </div>
                </div>

                <div class="item-list">
                    <div v-if="leads.length == 0">
                        No scans recorded.
                    </div>
                    <div v-for="item in filteredLeads"
                        :key="item.AttendeeCode"
                        class="favorite-list-item list-item scan-list-item"
                        @click="checkSessionScan(showCode, item.AttendeeCode)">
                        <div>
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${item.AttendeeCode}/profile`"
                                    class="round-photo"
                                fallbackSrc="/img/person.png" />
                            <div>
                                <span class="title">
                                    {{item.Attendee.DisplayName}}
                                </span>
                                <span class="subtitle"
                                        v-if="item.Attendee.Title">
                                    {{item.Attendee.Title}}
                                </span>
                                <span class="subtitle"
                                        v-if="item.Attendee.SubTitle">
                                    {{item.Attendee.SubTitle}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!mode"
                class="launch-page">
                <button @click="switchToScanMode">
                    Scan Badges
                </button>
                <button @click="switchToLeadMode">
                    View Scans
                </button>
            </div>        

        <div class="qr-loading qr-error"
             v-show="error">
            <span>
                {{ error }}
            </span>

            <button @click="getSessions()">
                Retry
            </button>
        </div>
    </div>
</template>
<script>
import QrScanner from 'qr-scanner'; 
import moment from 'moment'
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import { nextTick } from '@vue/runtime-core';


export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
            sessionGroups: [],
            selectedSessionCode: null,
            mode: '',

            submitting: false,
            scanMode: "CheckIn",
            scanner: null,
            lastScannedText: null,
            showLastScannedText: false,
            scanStatus:"",
            error: null,
            qrCheckResults: null,
            leads: [],
            searchText: '',
        }
    },

    computed: {
        title() {
            return AppState.show.Sections[this.sectionCode].Title;
        },
        requiresTwoScans() {
            return AppState.show.Sections[this.sectionCode].Settings?.Mode == "TwoScan"
                || !AppState.show.Sections[this.sectionCode].Settings;
        },
        showCode() {
            return AppState.showCode;
        },

        filteredLeads() {
            let leads = this.leads;

            if(!this.searchText) {
                return leads;
            }

            let toReturn = Array.from(this.leads);

            if(this.searchText && this.searchText.length > 0) {
                let searchTest = new RegExp(this.searchText, 'i');

                toReturn = toReturn.filter(x => {

                    return searchTest.test(x.Attendee.DisplayName);

                });
            }

            return toReturn;
        }
    },

    watch: {
        scanMode() {
            this.lastScannedText = null;
            this.scanStatus = "";
        },

        selectedSessionCode(newValue, oldValue){
            this.lastScannedText = null;
            this.scanStatus = "";

            if(this.selectedSessionCode
                && localStorage) {
                
                localStorage.setItem("SELECTED_SESSION", this.selectedSessionCode);
            }

            if(oldValue) {
                try {
                    window.$signalRConnection.invoke(
                        'UnregisterForPageAnalytics', 
                        `${AppState.showCode}_${oldValue}_LEADS`.toUpperCase());
                } catch {

                }
            }

            if(this.mode == 'leads') {
                this.reloadLeads();
            }

        },

        mode(){
            this.lastScannedText = null;
            this.scanStatus = "";
        }
    },

    methods: {
        switchToLeadMode() {
            if(this.scanner) {
                this.scanner.stop();
                this.scanner = null;
            }

            this.mode = 'leads';

            this.connectToControlChannel();

            this.reloadLeads();
        },

        switchToScanMode() {
            this.mode = 'scanner'
            this.scanner = new QrScanner(
            this.$refs.qrFeed,
            this.onScanSuccess,
            {
                preferredCamera: 'environment',
                maxScansPerSecond: 5
            });

            this.scanner.start();    

            window.$signalRConnection.invoke(
                'UnregisterForPageAnalytics', 
                `${AppState.showCode}_${this.selectedSessionCode}_LEADS`.toUpperCase());
        },

        async reloadLeads() {
            this.submitting = true;

            try {
                let result = await this.tryGet(`/api/scans/${this.showCode}/check/session/${this.selectedSessionCode}`);
                this.leads = result.Result || [];
            } catch {
                this.$awn.alert("Could not reload the scan list.");
            }

            this.submitting = false;
        },

        async connectToControlChannel(){
            if(window.$signalRConnection.notInitialized) {
                return;
            }

            if(!this.selectedSessionCode) {
                return;
            }

            if(this.mode != 'leads') {
                return;
            }

            window.$signalRConnection.invoke(
                'RegisterForPageAnalytics', 
                `${AppState.showCode}_${this.selectedSessionCode}_LEADS`.toUpperCase());
        },

        async testScanSuccess(){
            await this.onScanSuccess({
                "data": "tractus://"
            });
        },

        async getSessions(){
            this.error = null;

            this.submitting = true;

            let section = AppState.show.Sections[this.sectionCode];

            var showCategories = [];
            var sectionCategories = section.Categories;


            for(var i = 0; i < sectionCategories.length; i++){
                var sessionCategory = AppState.show.SessionCategories.filter( n => sectionCategories[i].Code == n.Code);

                if( sessionCategory.length > 0){
                    showCategories.push(sessionCategory[0]);
                }
            }

            let categoryQuery = showCategories.map(x => x.Code).join(';');
            let result = await this.tryGet(`/api/show/${AppState.show.Code}/agenda?c=${categoryQuery}`)

            this.sessionGroups = [];

            console.log(result.Result.Categories)
            result.Result.Categories.forEach(c => {
                let group = {
                    'Name': c.Name,
                    'Code': c.Code,
                    'Sessions': []
                };

                this.sessionGroups.push(group);

                let sessions = result.Result.Sessions
                    .filter(x => x.Session.Category.Code == c.Code)
                    .map(sessionModel => {
                        let s = sessionModel.Session;

                        return {
                            Code: s.Code,
                            CategoryCode: s.Category.Code,
                            Name: s.Name,
                            StartDateTime: s.SessionStartDateTime,
                            Duration: `${moment(s.SessionEndDateTime).subtract(s.SessionStartDateTime).minutes()} minutes`
                        }; 
                    });
                group.Sessions = [...sessions];
                
            });

            if(localStorage) {
                let lastSession = localStorage.getItem("SELECTED_SESSION");

                if(lastSession) {
                    this.selectedSessionCode = lastSession;
                }
            }

            this.submitting = false;

            nextTick(() => {
                this.switchToScanMode();
            });
        },

        async saveSessionScan(showCode, attendeeCode) {
            let scannedByAttendeeCode = AppState.attendeeCode;
           
            let scanDateTime = moment();
            let sessionCode = this.selectedSessionCode;

            let toSave = {
                'ShowCode': showCode,
                'AttendeeCode': attendeeCode,
                'ScannedByAttendeeCode': scannedByAttendeeCode,
                'ScanDateTime': scanDateTime,
                'SessionCode': sessionCode,
                'Mode': this.scanMode
            };

            try{
                let result = await this.tryPost('/api/scan/session', JSON.stringify(toSave), 'application/json');

                if(result && result.Result) {
                    this.$awn.info("Scan successful.");
                } else {
                    this.$awn.alert("Scan not saved. Please try again.");
                }
            } catch(ex){
                this.$awn.alert("Scan not saved. Please try again.");
            }
        },

        async checkSessionScan(showCode, attendeeCode) {
            // Query the API to get the scan history for the attendee
            this.submitting = true;
            this.qrCheckResults = null;

            let sessionCode = this.selectedSessionCode;

            try {
                let result = await this.tryGet(`/api/scans/${showCode}/${attendeeCode}/check/session/${sessionCode}`)

                let qrCheckResults = {
                    Title: '❌ Not Checked In',
                    Scans: [],
                    CheckInTime: null,
                    CheckOutTime: null,
                    CheckIns: [],
                    CheckOuts: [],
                };

                let earliestCheckIn = result.Result.find(e => e.Mode == 'CheckIn');
                let earliestCheckOut = result.Result.find(e => e.Mode == 'CheckOut');

                qrCheckResults.CheckInTime = earliestCheckIn ? moment(earliestCheckIn.ScanDateTime).format('MMM DD h:mm a') : null;
                qrCheckResults.CheckOutTime = earliestCheckOut ? moment(earliestCheckOut.ScanDateTime).format('MMM DD h:mm a') : null;

                qrCheckResults.CheckIns = result.Result.filter(e => e.Mode == "CheckIn");
                qrCheckResults.CheckOuts = result.Result.filter(e => e.Mode == "CheckOut");

                if(!earliestCheckIn && earliestCheckOut) {
                    qrCheckResults.Title = "⚠️ Checked Out, Not Checked In"
                }

                if(earliestCheckIn && !earliestCheckOut) {
                    qrCheckResults.Title = "⚠️ Checked In, Not Checked Out"
                }

                if(earliestCheckIn && earliestCheckOut) {
                    qrCheckResults.Title = "✅ Checked Out"
                }

                if(!this.requiresTwoScans && earliestCheckIn) {
                    qrCheckResults.Title = "✅ Checked In";
                }

                this.qrCheckResults = qrCheckResults;
            } catch(ex) {
                console.log(ex);
                this.$awn.alert("Could not retrieve scan results. Please try again later.");
            }

            this.submitting = false;
        },

        async onScanSuccess(result) {

            if(!this.selectedSessionCode){
                this.$awn.alert("No Session Selected")
                return;
            }

            if(this.lastScannedText == result.data) {
                this.scanStatus = "Successfully Scanned";
                return;
            }

            this.scanStatus = "";

            this.lastScannedText = result.data;

            if(this.lastScannedText.startsWith('tractus://')) {
                
                let url = new URL(result.data);
                let entityType = result.data
                        .replace("tractus://", "")
                        .split("?")[0]
                        .toLowerCase();

                if(entityType.toLowerCase() != "attendee") {

                    return;

                }

                let searchDetails = url.searchParams;

                let attendeeCode = searchDetails.get("c");
                let showCode = searchDetails.get("s");

                if(showCode != AppState.showCode) {
                    this.$awn.alert("This badge is not for this show.");
                    return;
                }



                if(this.mode == 'scanner') {
                    await this.saveSessionScan(showCode, attendeeCode);
                } else if (this.mode == 'check') {
                    await this.checkSessionScan(showCode, attendeeCode);
                }

            } else {
                this.$awn.alert("Sorry. QR code not recognized.");
            }
            

        },

        onLeadCaptured(lead) {
            if(!lead) {
                return;
            }

            if(lead.SessionCode != this.selectedSessionCode) {
                return;
            }

            let existingLead = this.leads.find(x => x.AttendeeCode == lead.AttendeeCode);

            if(!existingLead) {
                this.leads.push(lead);
            } else {
                Object.assign(existingLead, lead);
            }
        },

    },

    beforeUnmount() {
        window.$bus.$off('connected-to-signalr', this.connectToControlChannel);       
        window.$bus.$off('LeadCaptured', this.onLeadCaptured);    

        if(this.scanner) {
            this.scanner.stop();
        }

        window.$signalRConnection.invoke(
            'UnregisterForPageAnalytics', 
            `${AppState.showCode}_${this.selectedSessionCode}_LEADS`.toUpperCase());

    },

    async mounted() {
        window.$bus.$on('connected-to-signalr', this.connectToControlChannel);
        window.$bus.$on('LeadCaptured', this.onLeadCaptured);    
        await this.getSessions();
    }

}
</script>