<template>
    <div class="video-player-container"
         ref="videoPlayerRoot"
         :class="{'video-player-container-controls': showControls}">
        <video ref="videoPlayer"
               v-show="showPlayer"
               autoplay
               playsinline
               preload="false"
               crossorigin="anonymous"
               @timeupdate="onTimeUpdate"
               @contextmenu.stop.prevent
               @click="togglePlayPause"
               @playing="onPlaying"
               @pause="onPaused"
               @ended="onPaused">
        </video>
        <div class="video-controls"
             v-if="showControls && showPlayer">
            <button @click="togglePlayPause"
                    class="icomoon">
                {{playing ? '' : ''}}
            </button>
            <div class="seek-bar-timer">
                    {{videoPlayerControls.seekBarTimerDisplay}}
            </div>
            <div class="seek-bar"
                 @click="trySeekUsingBar">
                <div class="seek-bar-progress"
                     :style="`width: ${timePercentage}%`">

                </div>
            </div>
            <button class="icomoon"
                    @click="muted = !muted">
                {{muted ? '' : ''}}
            </button>
            <div class="volume-slider">
                <input type="range" min="0.0" max="1.0" step="0.05" v-model="volumeLevel"> 
            </div>
            <div class="misc-controls">
                <select v-model="playbackRate">
                    <option value="0.75">0.75x</option>
                    <option value="1">1x</option>
                    <option value="1.25">1.25x</option>
                    <option value="1.5">1.5x</option>
                    <option value="1.75">1.75x</option>
                    <option value="2">2x</option>
                    <option value="2.25">2.25x</option>
                    <option value="2.5">2.5x</option>
                </select>
                <button @click="toggleFullScreen"
                        class="icomoon">
                    
                </button>
            </div>
        </div>
        <div class="v-overlay" v-show="!showPlayer" @click="onOverlayClick">
            <div class="overlay-preview">
                <img v-if="previewImage" :src="previewImage" class="responsive">
            </div>
            <div class="play-button" v-show="!showPlayer && !loading && !inPreviewMode && src">
                <span class="icomoon"></span>
            </div>
            <div class="loader" v-if="loading">
                <loading>
                </loading>
            </div>
        </div>
    </div>
</template>
<script>
// TODO: Figure out why when we include the NPM package, we can't do video playback
import Hls from 'hls.js'

export default {
    props: [
        'src', 
        
        'waitForStream', 
        'inPreviewMode', 
        'previewImage', 
        'showControls',
        'clearStartAtTime',
        'suppressEventEmit'
    ],

    watch: {
        src(to, from) {
            this.videoSource = this.src;
            this.initializePlayer();
        },
    },

    data() {
        return {
            showPlayer: false,
            loading: false,
            hls: null,
            lastVideoEvent: null,
            lastTime: 0,
            videoSource: null,
            startAtTime: null,

            videoPlayerControls: {
                playbackRate: 1.0,
                volumeLevel: 1.0,
                muted: false,
                timePercentage: 0.0,
                playing: false,
                seekBarTimerDisplay: '',
            },
            wasWarnedAboutAutoplayBlock: false,
        }
    },

    computed: {
        playbackRate: {
            get() {
                return this.videoPlayerControls.playbackRate;
            },
            set(v) {
                this.videoPlayerControls.playbackRate = v;
                this.$refs.videoPlayer.playbackRate = v;
            }
        },

        volumeLevel: {
            get() {
                return this.videoPlayerControls.volumeLevel;
            },
            set(v) {
                this.videoPlayerControls.volumeLevel = v;
                this.$refs.videoPlayer.volume = v;
            }
        },

        muted: {
            get() {
                return this.videoPlayerControls.muted;
            },
            set(m) {
                this.videoPlayerControls.muted = m;
                this.$refs.videoPlayer.muted = m;
            }
        },

        timePercentage() {
            return this.videoPlayerControls.timePercentage;

        },

        playing() {
            return this.videoPlayerControls.playing;
        }
    },

    methods: {
        onPlaying() {
            this.videoPlayerControls.playing = true;
        },

        onPaused() {
            this.videoPlayerControls.playing = false;
        },

        togglePlayPause() {
            if(!this.showControls) {
                return;
            }

            if(this.$refs.videoPlayer.paused) {
                this.$refs.videoPlayer.play();
            } else {
                this.$refs.videoPlayer.pause();
            }
        },

        onTimeUpdate() {
            if(!this.$refs.videoPlayer) {
                return;
            }
            
            let duration = this.$refs.videoPlayer.duration;
            let time = this.$refs.videoPlayer.currentTime;

            this.videoPlayerControls.timePercentage = (time / duration) * 100.0;

            let hoursDuration = Math.floor(duration / 3600.0)
            let minutesDuration = Math.floor(duration / 60.0)
            let secondsDuration = Math.floor(duration % 60.0)
            
            let hoursTime = Math.floor(time / 3600.0)
            let minutesTime = Math.floor(time / 60.0)
            let secondsTime = Math.floor(time % 60.0)
            
            let displayTime = `${String(minutesTime).padStart(2, '0')}:${String(secondsTime).padStart(2, '0')}`;
            if(hoursTime > 0) {
                displayTime = `${hoursTime}:${displayTime}`
            }

            let displayDuration = `${String(minutesDuration).padStart(2, '0')}:${String(secondsDuration).padStart(2, '0')}`;
            if(hoursDuration > 0) {
                displayDuration = `${hoursDuration}:${displayDuration}`
            }

            this.videoPlayerControls.seekBarTimerDisplay = `${displayTime} / ${displayDuration}`;
        },

        trySeekUsingBar(e) {
            let x = e.offsetX;
            let width = e.srcElement.clientWidth;

            let newPosition = x / width;

            let newTime = newPosition * this.$refs.videoPlayer.duration;

            if(newTime > this.$refs.videoPlayer.duration) {
                newTime = newTime - 1.0;
            }

            this.$refs.videoPlayer.currentTime = newTime;
        },

        toggleFullScreen() {
            if(!document.fullscreenElement) {
                this.$refs.videoPlayerRoot.requestFullscreen();
            } else {
                document.exitFullscreen();
            }
        },

        reportedPosition() {
            let videoPlayer = this.$refs.videoPlayer;

            return videoPlayer 
                && videoPlayer.currentTime != NaN
                ? videoPlayer.currentTime
                : NaN;            
        },

        playVideoAtTime(src, time) {
            if(this.videoSource == src) {
                this.seek(time, true);
                return;
            }

            this.startAtTime = time;
            this.videoSource = src;
            this.initializePlayer();
            this.play();
        },

        seek(time, force) {
            this.startAtTime = time;

            let timeStamp = new Date(time);

            if(timeStamp == NaN) {
                return;
            }

            let seekTimeSeconds = (new Date() - timeStamp) / 1000.0;

            let currentPosition = this.$refs.videoPlayer.currentTime;

            if(currentPosition != NaN && currentPosition > 0 && !force) {
                
                let difference = Math.abs(currentPosition - seekTimeSeconds);

                if(difference <= 1.0) {
                    // We're OK with a 1 second time difference.
                    return;
                }
            }

            if(seekTimeSeconds > this.$refs.videoPlayer.duration) {
                seekTimeSeconds = this.$refs.videoPlayer.duration - 1.0;
            }

            this.$refs.videoPlayer.currentTime = seekTimeSeconds;
        },

        onOverlayClick() {
            this.play();
        },

        resume() {
            try {
                this.$refs.videoPlayer.play();
            } catch {

            }
        },

        onVideoEvent(e) {
            if(this.suppressEventEmit) {
                return;
            }
           
            this.lastVideoEvent = e.type;
            this.lastTime = e.target.currentTime;

            let ev = {
                eventType: e.type,
                time: e.target.currentTime,
                raised: new Date(),
                src: this.videoSource
            };

            window.$bus.$emit('videoevent', ev);

            this.$emit('videoevent', ev);
        },

        stop() {
            try {
                if(this.$refs.videoPlayer) {

                    if(!this.suppressEventEmit) {

                        window.$bus.$emit('videoevent', {
                            eventType: 'stop:exit',
                            time: this.$refs.videoPlayer.currentTime,
                            raised: new Date(),
                            src: this.videoSource
                        });        

                    }

                    this.$refs.videoPlayer.pause();
                    if(this.hls) {
                        this.hls.destroy();
                        this.hls = null;
                    }
                }
            } catch {

            }

            this.videoSource = null;
            this.$refs.videoPlayer.src = null;
        },

        logDebug() {
            let v = this.$refs.videoPlayer;
        },

        async onCanPlay() {
            let v = this.$refs.videoPlayer;

            this.showPlayer = true;
            this.loading = false;

            if(this.startAtTime) {
                this.seek(this.startAtTime);
            }

            this.logDebug();

            try {
                await v.play();
            } catch(ex) {
                if(this.wasWarnedAboutAutoplayBlock) {
                    return;
                }

                this.wasWarnedAboutAutoplayBlock = true;

                let promise = new Promise(r => {
                    this.$awn.confirm(
                        "Your browser has blocked auto-play of the conference audio. Click 'Join Audio' to listen.",
                        () => {
                            r('End');
                        },
                        false,
                        {
                            labels: {
                                confirm: "Join Audio",
                            }
                        }
                    )
                });

                await promise;

                if(this.startAtTime) {
                    this.seek(this.startAtTime);
                }

                await v.play();
            }

            if(this.clearStartAtTime) {
                this.startAtTime = null;
            }            
        },

        onHlsError(e, d) {
            console.error("Error on HLS.", e, d);
            let hls = this.hls;

            if(d.type == Hls.ErrorTypes.NETWORK_ERROR) {
                if(d.fatal) {
                    setTimeout(() => hls.startLoad(), 3000);
                }
            } else if(d.type == Hls.ErrorTypes.MEDIA_ERROR) {
                if(d.fatal) {
                    hls.recoverMediaError();
                }
            } else {
                if(d.fatal) {

                    if(!this.suppressEventEmit)
                    {
                        let ev = {
                            eventType: "fatalerror",
                            time: this.$refs.videoPlayer.currentTime,
                            raised: new Date(),
                            src: this.videoSource
                        };

                        window.$bus.$emit('videoevent', ev);
                    }

                    
                    //alert("We're sorry. The video stream has stopped. Please try playing it again later.");
                    hls.destroy();
                    this.showPlayer = false;
                    this.loading = false;
                }
            }
        },

        async play() {
            if(!this.videoSource && this.src) {
                this.videoSource = this.src;
                this.initializePlayer();
            }

            if(this.$refs.videoPlayer 
                && this.videoSource 
                && this.$refs.videoPlayer.src == this.videoSource) {

                return;

            }

            if(!this.videoSource) {
                return;
            }

            if(this.hls) {
                this.hls.destroy();
            }

            this.loading = true;
            let v = this.$refs.videoPlayer;

            let videoUrl = this.videoSource;

            v.addEventListener('play', this.onVideoEvent);
            v.addEventListener('pause', this.onVideoEvent);
            v.addEventListener('ended', this.onVideoEvent);
            //v.addEventListener('stalled', this.onVideoEvent);
            v.addEventListener('canplay', this.onCanPlay);


            let mseSupported = Hls.isSupported();
            let canPlayNativeHls = v.canPlayType('application/vnd.apple.mpegurl')
                ;

            let useNativePlayer = 
                videoUrl.toLowerCase().endsWith(".webm")
                || videoUrl.toLowerCase().endsWith(".mp4");

            if(mseSupported && !useNativePlayer) {
                let debugHls = false;

                try {
                    if(this.$route && this.$route.query && this.$route.query.hlsd) {
                        debugHls = true;
                    }
                } catch {

                }

                let hls = new Hls({
                    debug: debugHls,
                    maxBufferLength: 20,
                    maxBufferSize: 20
                });
                this.hls = hls;

				//hls.on(Hls.Events.MANIFEST_PARSED, this.onCanPlay);
                hls.on(Hls.Events.ERROR, this.onHlsError);
                
				hls.loadSource(videoUrl);
                this.showPlayer = true;
				hls.attachMedia(v);

            } else {

                v.crossOrigin = 'anonymous';
                v.addEventListener('error', e => {
                    this.showPlayer = false;
                    this.loading = false;

                    console.error("Error on HLS native.", {
                        eventError: e,
                        videoElement: this.$refs.videoPlayer,
                        videoError: this.$refs.videoPlayer.error
                    });

                    let ev = {
                        eventType: "fatalerror",
                        time: this.$refs.videoPlayer.currentTime,
                        raised: new Date(),
                        src: this.videoSource
                    };

                    if(!this.suppressEventEmit) {
                        window.$bus.$emit('videoevent', ev);
                    }

                });


                v.src = this.videoSource;
                this.showPlayer = true;
            }
        },

        initializePlayer() {
            this.showPlayer = false;
            this.loading = false;
            if(!this.waitForStream) {
                this.play();
            }
        }
    },

    beforeUnmount() {

        let v = this.$refs.videoPlayer;
        let finalTimeStamp = v ? v.currentTime : 0;

        if(this.hls) {
            this.hls.destroy();
            this.hls = null;
        }

        let currentTime = Math.ceil(finalTimeStamp);

        if(!this.suppressEventEmit) {
            window.$bus.$emit('videoevent', {
                eventType: 'stopped:destroy',
                time: currentTime,
                raised: new Date(),
                src: this.videoSource
            });     

        }
        
        // if(this.watchDog) {
        //     clearInterval(this.watchDog);
        //     this.watchDog = null;
        // }

        v.removeEventListener('play', this.onVideoEvent);
        v.removeEventListener('pause', this.onVideoEvent);
        v.removeEventListener('ended', this.onVideoEvent);
        v.removeEventListener('canplay', this.onCanPlay);
    },

    mounted() {
        this.initializePlayer();
    }
}
</script>