<template>
    <div class="mixer-layout"
         :class="[mode]"
         ref="stagePart">
        <div class="mixer-debug" 
             v-show="debugMode">
            <input :type="debugMode" 
                   value="accessToken" 
                   id="accessToken" 
                   name="accessToken"
                   ref="accessToken" />
                   
            <input :type="debugMode" 
                    value="catToken" 
                    id="catToken" 
                    ref="catToken"
                    name="catToken"/>

            <input :type="debugMode" 
                   value="refreshToken" 
                   id="refreshToken"
                   name="refreshToken"
                   ref="refreshToken" />

            <input :type="debugMode" 
                   value="refreshUrl"
                   id="refreshUrl"
                   name="refreshUrl"
                   ref="refreshUrl" />

            <input :type="debugMode" 
                   value="conferenceId" 
                   id="conferenceId" 
                   name="conferenceId"
                   ref="conferenceId" />

            <input :type="debugMode" 
                   value="1234" 
                   id="thirdPartyId" 
                   name="thirdPartyId"
                   ref="thirdPartyId"/>

            <input :type="debugMode" 
                   value="stream" 
                   id="layoutType" 
                   name="layoutType"
                   ref="layoutType"/>

            <button id="joinConference"
                    @click="onJoinConference">Join</button>
            <button id="replayConference"
                    @click="onReplayConference">Replay</button>            
            <button @click="simulateDone">SimLeave</button>            
        </div>


        <div class="main-webinar-container video-container"
             ref="cameraContainer"
             v-show="conference && galleryPresenters && galleryPresenters.length > 0">
             <div class="camera"
                  v-for="participant in galleryPresenters"
                  :key="participant.id">
                  <webinar-view-video :participant="participant.Participant"
                                    :stream="participant.Stream"
                                    :showPresenterName="true"
                                    :mirrorVideo="false"
                                    :attendeeCode="'MIXER'">

                </webinar-view-video>                        
            </div>
            <div class="camera" 
                        v-for="i in debugPresenters"
                        :key="i">
                <webinar-view-video isDebug="true">

                </webinar-view-video>
            </div>
        </div>
        <div v-show="mainStageParticipant"
             class="stage-container video-container"
             ref="stageContainer">
             <div class="camera"
                  v-if="mainStageParticipant">
                <webinar-view-video :participant="mainStageParticipant.Participant"
                                    :stream="mainStageParticipant.Stream"
                                    :blurIfMyStream="true">

                </webinar-view-video>
            </div>
            <div v-else-if="mainStageDebugParticipant"
                        class="camera">
                <webinar-view-video isDebug="true">

                </webinar-view-video>
            
            </div>
        </div>
        <component :is="'style'" v-html="customLayoutCss">
        </component>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Common from './common.js'
import Token from './authentication/token.js'
import VoxeetSDK from '@voxeet/voxeet-web-sdk'
import WebinarViewVideo from './webinar.view.video.vue';
import { isProxy, toRaw } from 'vue'

export default {
    components: { WebinarViewVideo },

    mixins: [
        Common,
        Token,
    ],

    data() {
        return {
            voxeetReady: false,
            voxeetEnded: false,
            conference: null,

            participants: [],

            mainStageParticipant: null,
            debugPresenters: 0,
            mainStageDebugParticipant: false,

            debugMode: false,

            showCode: null,
            roomCode: null,
            customLayoutCss: '',
            hidePresenterIfVideoOff: false,
        }
    },

    computed: {
        inputType() {
            return this.debugMode
                ? 'input'
                : 'hidden';
        },

        mode() {
            return !this.mainStageParticipant && !this.mainStageDebugParticipant
                ? 'gallery'
                : 'stage';
        },

        galleryPresenters() {
            let toReturn = this.participants;

            if(this.hidePresenterIfVideoOff) {
                toReturn = toReturn.filter(x => x.Stream);
            }

            return toReturn;
        },
    },

    methods: {
        async build() {
            VoxeetSDK.conference.on('left', this.onConferenceEnded);
            VoxeetSDK.conference.on('ended', this.onConferenceEnded);

            VoxeetSDK.conference.on('streamAdded', this.onStreamUpdated);
            VoxeetSDK.conference.on('streamUpdated', this.onStreamUpdated);
            VoxeetSDK.conference.on('streamRemoved', this.onStreamRemoved);
            VoxeetSDK.conference.on('participantUpdated', this.onParticipantUpdated);
            VoxeetSDK.command.on('received', this.onVoxeetCommandReceived);
            

            if(this.$route.query.debug) {
                this.debugMode = true;

                this.$nextTick(() => {
                    if(this.$route.query.c) {
                        this.$refs.conferenceId.value = this.$route.query.c;
                    }

                    if(this.$route.query.a) {
                        this.$refs.accessToken.value = this.$route.query.a;
                        this.$refs.catToken.value = this.$route.query.a;
                    }

                })
            } else {
            }

            this.scheduleResize();
        },

        addVoxeetReadyDiv() {
            const startedDiv = document.createElement('div');
            startedDiv.id = 'conferenceStartedVoxeet';

            document.body.appendChild(startedDiv);
        },

        addVoxeetFinishedDiv() {
            try {
                let node = document.getElementById('conferenceStartedVoxeet')
                document.body.removeChild(node);

            } catch {

            }

            try {
                const startedDiv = document.createElement('div');
                startedDiv.id = 'conferenceEndedVoxeet';

                document.body.appendChild(startedDiv);
            } catch {

            }
        },

        simulateDone() {
            this.onConferenceEnded();
        },

        async onVoxeetCommandReceived(participant, message) {
            const command = JSON.parse(message);
            console.warn("COMMAND RECEIVED ---", command);


            if(command.Code == 'WebinarSettingsUpdated') {
                this.loadConferenceDetails();
            }
        },


        async onStreamUpdated(participant, stream) {

            if(stream.type == 'ScreenShare') {
                
                let existingParticipantDetails = 
                    this.mainStageParticipant 
                    && this.mainStageParticipant.Participant.id == participant.id
                    ? this.mainStageParticipant
                    : null;

                if(!existingParticipantDetails) {
                    existingParticipantDetails = {
                        Participant: participant,
                        Stream: stream
                    };

                    this.mainStageParticipant = existingParticipantDetails;
                }

                if(stream.getVideoTracks().length) {

                    existingParticipantDetails.Stream = stream;

                } else {

                    existingParticipantDetails.Stream = null;

                }


            } else {
                let arrayToUpdate = this.participants;

                let existingParticipantDetails = arrayToUpdate.find(x => x.Participant.id == participant.id);

                if(!existingParticipantDetails) {
                    existingParticipantDetails = {
                        Participant: participant,
                        Stream: stream
                    };

                    arrayToUpdate.push(existingParticipantDetails);
                }

                if(stream.getVideoTracks().length) {

                    existingParticipantDetails.Stream = stream;

                } else {

                    existingParticipantDetails.Stream = null;

                }


            }


            this.scheduleResize();
        },

        async onStreamRemoved(participant, stream) {

            if(stream.type == 'ScreenShare') {
                this.mainStageParticipant = null;
            } else {

                let arrayToUpdate = this.participants;

                let existingParticipantDetails = arrayToUpdate.find(x => x.Participant.id == participant.id);

                if(!existingParticipantDetails) {
                    return;
                }

                existingParticipantDetails.stream = null;
            }

            this.scheduleResize();
        },  

        
        async onParticipantUpdated(participant) {

            if(participant.status == 'Left') {
                let existingParticipantDetails = this.participants.find(x => x.Participant.id == participant.id);

                let existingParticipantDetailsStage = 
                    this.mainStageParticipant
                        && this.mainStageParticipant.Participant.id == participant.id
                    ? this.mainStageParticipant
                    : null;

                if(existingParticipantDetails) {
                    let index = this.participants.indexOf(existingParticipantDetails);

                    this.participants.splice(index, 1);
                }

                if(existingParticipantDetailsStage) {
                    this.mainStageParticipant = null;
                }

                this.scheduleResize();
            }
        },


        async simulateReady() {
            await this.initializeVoxeetSdk();
        },

        async loadConferenceDetails() {
            let conferenceId = toRaw(this.conference).id;
            // "api/webinar/mixerbootstrap/{showCode}/{roomCode}"

            let url = `/api/webinar/mixerbootstrap/${conferenceId}`;

            let details = await this.tryGetAnonymous(url);

            this.showCode = details.Result.ShowCode;
            this.roomCode = details.Result.RoomCode;
            this.customLayoutCss = details.Result.CustomLayoutCss;
            this.hidePresenterIfVideoOff = details.Result.HidePresenterIfVideoOff;

            this.voxeetReady = true;
        },

        async initializeVoxeetSdk() {
            const accessToken = this.$refs.accessToken.value;
            const refreshToken = this.$refs.refreshToken.value;
            const refreshUrl = this.$refs.refreshUrl.value;
            const conferenceId = this.$refs.conferenceId.value;
            console.log(accessToken, refreshToken, refreshUrl, conferenceId);

            if(!VoxeetSDK.session.isOpen()) {
                VoxeetSDK.initializeToken(accessToken, async () => {
                    let result = fetch(refreshUrl, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken};`
                        },
                        body: {
                            refresh_token: refreshToken
                        }
                    });

                    let resultTokenRaw = await result.json();
                    let resultToken = resultTokenRaw.access_token;
                    return resultToken;
                });

                const thirdPartyId = this.$refs.thirdPartyId.value;
                const layoutType = this.$refs.layoutType.value;

                const mixer = {
                    name: 'Mixer',
                    externalId: `Mixer_${layoutType}`,
                    thirdPartyId: thirdPartyId
                };
                await VoxeetSDK.session.open(mixer);
            }

            this.conference = await VoxeetSDK.conference.fetch(conferenceId);
            
            await this.loadConferenceDetails();
        },

        onConferenceEnded() {
            this.voxeetReady = false;
            this.voxeetEnded = true;
            this.addVoxeetFinishedDiv()
        },


        async onReplayConference() {
            await VoxeetSDK.conference.replay(
                toRaw(this.conference),
                0, 
                {enabled: true});
        },

        async onJoinConference() {
            await this.initializeVoxeetSdk();

            const catToken = this.$refs.catToken.value;

            const joinOptions = {
                conferenceAccessToken: (catToken && catToken.length > 0 ? catToken : null),
                constraints: {
                    video: false,
                    audio: false
                },
                mixing: {
                    enabled: true
                }, 
            };

            console.log('JOINING')
            try {
                await VoxeetSDK.conference.join(toRaw(this.conference), joinOptions);
                console.log('JOINED')
                this.addVoxeetReadyDiv();
                this.scheduleResize();
            } catch (ex) {
                console.error("Error on join.", { error: ex });
            }
        },


        scheduleResize() {
            this.$nextTick(() => {
                if(this.$refs.cameraContainer) {
                    this.refreshVideoSizes(this.$refs.cameraContainer, 'cameras');
                }

                if(this.$refs.stageContainer) {
                    this.refreshVideoSizes(this.$refs.stageContainer, 'stage');
                }
            });
        },

        _area(increment, 
              containerWidth, 
              containerHeight, 
              children, 
              ratio,
              marginBetweenVideos) {

            let i = 0;
            let w = 0;
            
            let h = increment * ratio + marginBetweenVideos;


            while (i < (children.length)) {
                if ((w + increment) > containerWidth) {
                    w = 0;
                    h = h + (increment * ratio) + marginBetweenVideos;
                }
                w = w + increment + marginBetweenVideos;
                i++;
            }
            if (h > containerHeight || increment > containerWidth) return false;
            else return increment;
        },

        refreshVideoSizes(container, mode) {
            let children = [...container.childNodes].filter(x => x.nodeName == 'DIV');

            if(children.length == 0) {
                return;
            }
            
            let marginBetweenVideos = 4;
            marginBetweenVideos *= 2;

            let max = 0;
            let i = 1;
            let width = container.offsetWidth - marginBetweenVideos;
            let height = container.offsetHeight - marginBetweenVideos;
            let ratio = 9.0 / 16.0;

            if(children.length > 1 || mode == 'cameras' && this.mode == 'stage') {
                while(i < 5000) {
                    let area = this._area(
                        i, 
                        width, 
                        height, 
                        children, 
                        ratio,
                        marginBetweenVideos);

                    if(area === false) {
                        max = i - 1;
                        break;
                    }

                    i++;
                }

                max = max - marginBetweenVideos;

                for(let s = 0; s < children.length; s++) {
                    let child = children[s];

                    child.style.margin = `${marginBetweenVideos / 2}px`;

                    child.style.width = `${max}px`;
                    child.style.height = `${max * ratio}px`;
                    
                }
                

            } else {

                let child = children[0];

                child.style.margin = `${marginBetweenVideos}px`;

                child.style.width = `${width}px`;

                let maxHeight = Math.min(width * (9.0 / 16.0), height);
                child.style.height = `${maxHeight}px`;

                child.style.width = `${maxHeight * (16.0 / 9.0)}px`;
            }
           
        }, 
 
    },

    mounted() {
        window.addEventListener('resize', this.scheduleResize);
        this.build();
    }
}
</script>