<template>
    <div class="page-side-panel">
        <div class="header-actions">
            <span class="title">Chats & Meetings</span>

            <div class="actions">            
                <button class="icomoon"
                        :class="{'button-outline': !showSettings}" 
                        @click="showSettings = !showSettings">
                    
                </button>
                <button class="icomoon" @click="exportBookingsToCsv">
                    
                </button>
                <button class="icomoon" @click="closeShowBookings">
                    
                </button>
            </div>
        </div>

        <div v-if="!selectedBooking && !showSettings && !submittingRequest"
             class="input-control filter-select">
            <select v-model="filterMode">
                <option v-for="mode in filterModes"
                        :key="mode.Code"
                        :value="mode.Code">
                    {{mode.Name}}
                </option>
            </select>
        </div>
        
        <div class="page-side-panel-content"
             :class="{'settings-mode': showSettings}">
            <div class='booking-table'
                 v-if="!selectedBooking && !showSettings && !submittingRequest">

                <template v-if="filterMode != 'AllChats'">
                    <div v-for="booking in filteredBookings"
                            v-bind:key="booking.Id"
                            class="booking-item">
                        <div>
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${booking.FromCode}/profile`"
                                    fallbackSrc="/img/person.png" />
                            <div>
                                <span class="title">
                                    <div class="online-icon" v-if="booking.IsOnline"></div>
                                    {{booking.FromName}}
                                </span>
                                <span class="subtitle contact-title">
                                    {{booking.FromTitle}}
                                </span>
                                <span class="subtitle">
                                    Received {{$filters.moment(booking.SentDateTime, 'lll')}}
                                </span>
                                <template v-if="booking.AssignedToCode">
                                    <span class="subtitle">
                                        Assigned to {{booking.AssignedToName}}
                                    </span>
                                </template>
                                <span class="subtitle"
                                        v-if="booking.SlotCode && booking.MeetingDateTime">
                                    <span class="icomoon">
                                        
                                    </span>
                                    <span>
                                        {{$filters.moment(booking.MeetingDateTime, 'lll')}}
                                    </span>
                                </span> 
                            </div>
                        </div>

                        <div class="booking-notes">
                            <linkify-area :input="booking.Notes" />
                        </div>

                        <div class="button-group">
                            <router-link class="btn" :to="{name: 'attendeeDetails', params: {code: booking.FromCode}}">
                                <span class="icomoon"></span>
                            </router-link>
                            <button @click="sendMessage(booking)">
                                <span class="icomoon"></span>
                            </button>
                            <button @click="selectedBooking = booking">
                                <span class="icomoon"></span>
                                <span>Details</span>
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-for="chat in chats"
                            v-bind:key="chat.Discussion.Code"
                            class="booking-item"
                            @click="sendMessage(chat.Discussion.OriginatingAttendeeCode)">
                        <div>
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${chat.Discussion.OriginatingAttendeeCode}/profile`"
                                    fallbackSrc="/img/person.png" />
                            <div>
                                <span class="title">
                                    <div class="online-icon" v-if="chat.IsOnline"></div>
                                    {{chat.OverrideName}}
                                </span>
                                <span class="subtitle contact-title">
                                    {{chat.Title}}
                                </span>
                                <span class="subtitle">
                                    {{chat.SubTitle}}
                                </span>
                            </div>
                        </div>                       
                    </div>
                </template>
            </div>
            <div class="booking-details"
                 v-if="selectedBooking && !showSettings">
                <router-link :to="{name: 'attendeeDetails', params: {code: selectedBooking.FromCode}}">
                    <h3>
                        <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${selectedBooking.FromCode}/profile`"
                            fallbackSrc="/img/person.png" />
                        <div>
                            <span>{{selectedBooking.FromName}}</span>
                            <span class="attendee-title" v-if="selectedBooking.FromTitle">{{selectedBooking.FromTitle}}</span>
                        </div>
                    </h3>
                </router-link>
                <h4>Received {{$filters.moment(selectedBooking.SentDateTime, 'lll')}}</h4>
                <h4 v-if="selectedBooking.SlotCode && selectedBooking.MeetingDateTime">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        Meeting: {{$filters.moment(selectedBooking.MeetingDateTime, 'lll')}}
                    </span>
                </h4>

                <div class="input-control">
                    <label>
                        Assigned To
                    </label>
                    <select v-model="selectedBooking.AssignedToCode"
                            @change="updateBookingAssignment(selectedBooking)">
                        <option :value="''">
                            (Not Assigned)
                        </option>
                        <option v-for="item in pageAdmins"
                                :key="item.Code"
                                :value="item.Code">
                            {{item.DisplayName}}
                        </option>
                    </select>
                </div>


                <div v-if="selectedBooking.MeetingDateTime && selectedBooking.MeetingRoomCode"
                     class="button-group">

                    <router-link class="btn"
                                 :to="{name: 'AppointmentList', params: {code: selectedBooking.Id}}">
                        <span class="icomoon">
                            
                        </span>
                        <span>
                            View Meeting Details
                        </span>
                    </router-link>
                </div>

                
                <linkify-area class="post-body" :input="selectedBooking.Notes" />
            
                <div class="button-group">
                    <button @click="deselectBooking()">
                        <span class="icomoon"></span>
                    </button>
                    <button @click="sendMessage(selectedBooking.FromCode)">
                        <span class="icomoon"></span>
                    </button>
                    <button v-if="selectedBooking.PleaseContact && selectedBooking.Email"
                            @click="openSendEmailRequest(selectedBooking)">
                        <span class="icomoon"></span>
                    </button>
                    <button @click="attemptDeleteAsync(selectedBooking)">
                        <span class="icomoon"></span>
                    </button>
                    <router-link class="btn" :to="{name: 'attendeeDetails', params: {code: selectedBooking.FromCode}}">
                        <span class="icomoon"></span>
                    </router-link>
                    <button type="button"
                    @click="saveBookingChangesAsync()">
                        <span class="icomoon"></span>
                    </button>
                </div>
            </div>
            <div v-if="showSettings">
                <appointment-settings :attendee="attendee"
                                      :entityTypeCode="'VENDOR'"
                                      :entityCode="code"
                                      :canAdjustScheduleSettings="canAdjustScheduleSettings">

                </appointment-settings>
            </div>
            <div v-if="submittingRequest">
                <loading></loading>
            </div>

        </div>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'code', 'typeCode', 'requestcode'],

    watch: {
        requestcode(from, to) {
            if(to) {
                this.selectedBooking = this.bookings.find(x => x.Id == to);
            }
        }
    },

    computed: {
        showCode() {
            return AppState.showCode
        },

        filterModes() {
            return [
                { Code: 'AllChats', Name: 'All Page Chats'},
                { Code: 'AllAppointments', Name: 'All Appointment Requests'},
                { Code: 'MyAppointments', Name: 'My Appointment Requests'},
            ]
        },

        filteredBookings() {
            if(this.filterMode != 'MyAppointments') {
                return this.bookings;
            }

            let toReturn = this.bookings;

            toReturn = toReturn.filter(x => x.AssignedToCode == AppState.attendeeCode);
            
            return toReturn;
        }
    },

    methods: {
        deselectBooking() {
            if(this.selectedBooking.PreviousAssignedToCode) {
                this.selectedBooking.AssignedToCode = this.selectedBooking.PreviousAssignedToCode;
                this.selectedBooking._Modified = false;
            }

            this.selectedBooking = null;
        },

        updateBookingAssignment(selectedBooking) {
            if(!selectedBooking.PreviousAssignedToCode) {

                selectedBooking.PreviousAssignedToCode = selectedBooking.AssignedToCode;
            }

            selectedBooking._Modified = true;            
        },

        async saveBookingChangesAsync() {
            this.submittingRequest = true;

            try {

                await this.tryPost(`/api/appointment/set`,
                    JSON.stringify(this.selectedBooking),
                    'application/json')

                this.selectedBooking._Modified = false;            
                this.selectedBooking.PreviousAssignedToCode = this.selectedBooking.AssignedToCode;            

                this.$awn.tip("Changes have been saved.");                
            } catch(ex) {
                console.error(ex);
                this.$awn.alert("We could not save changes to this booking. Please try again later.");
            }
            
            this.submittingRequest = false;
        },

        exportBookingsToCsv() {

            let csvFile = 'Date,From,Title,E-Mail Address,Last Handled By,Last Updated,Message,Notes\n';

            for(let i = 0; i < this.bookings.length; i++) {
                let booking = this.bookings[i];

                csvFile += `"${moment(booking.SentDateTime).format("YYYY-MM-DD HH:mm")}","${booking.FromName.replace('"', '')}","${(booking.FromTitle || '').replace('"', '')}","${booking.Email || ''}","${booking.HandledByName || ''}","${(booking.HandledOnDate ? moment(booking.HandledOnDate).format("YYYY-MM-DD HH:mm") : 'NEW')}","${(booking.Notes || '').replace('"', '')}","${(booking.HandledNotes || '').replace('"', "")}"\n`;
            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", 'export_page_requests.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        },

        editBooking(booking) {
            this.selectedBooking = booking;

            if(!this.selectedBooking) {
                return;
            }
        },

        async attemptDeleteAsync(booking) {
            let result = confirm(`Are you sure you want to delete this ${booking.SlotCode ? 'meeting' : 'message'}? This cannot be undone.`);

            if(!result) {
                return;
            }

            this.submittingRequest = true;
            await this.tryDelete(`/api/appointments/my/${booking.Id}`);

            this.selectedBooking = null;
            this.loadBookings();
        },

        openSendEmailRequest(request) {
            window.location = `mailto:${request.Email}`;
        },

        closeShowBookings() {
            this.goBackOneRoute();
        },

        async build() {
            this.submittingRequest = true;
            try {
                await this.loadBookings();
                await this.loadChats();

                this.postAnalytics(
                    AppState.attendeeCode,
                    'PageAppointments',
                    this.code,
                    this.typeCode,
                    AppState.showCode);

            } catch {
                alert("We couldn't load your bookings. Please try again later.");
                this.closeShowBookings();
            }
            this.submittingRequest = false;

            if(this.requestcode) {
                this.selectedBooking = this.bookings.find(x => x.Id == this.requestcode);
            }
        },

        async loadBookings() {
            let bookings = await this.tryGet(`/api/appointments/for/${this.typeCode.toUpperCase()}/${this.code}`);

            if(bookings.Result.Appointments) {
                this.bookings = bookings.Result.Appointments;
            }

            this.canAdjustScheduleSettings = bookings.Result.CanScheduleAppointments;

            this.pageAdmins = bookings.Result.PageAdmins || [];
        },

        async loadChats() {
            let chats = await this.tryGet(`/api/chats/${AppState.showCode}/${this.typeCode.toUpperCase()}/${this.code}`);

            this.chats = chats.Result || [];
        },

        async sendMessage(attendeeCode) {
            let routeToFetch = `/api/chats/${AppState.showCode}/VENDOR/${this.code}/${attendeeCode}`;

            try {
                let result = await this.tryGet(routeToFetch);
                console.log(result);

                if(!result?.Result?.Discussion) {
                    throw "Not found";
                }

                let route = {
                    name: 'DiscussionForVendor', 
                    params: { code: this.code, typeCode: 'vendor', discussionCode: result.Result.Discussion.Code }};
                
                this.$router.push(route);

            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not start the chat. Please try again later.");
            }
        },

        async onAppointmentUpdated(appointment) {
            await this.loadBookings();

            if(this.selectedBooking && this.selectedBooking.Id == appointment.Id) {
                let foundBooking = this.bookings.find(x => x.Id == this.selectedBooking.Id);

                this.selectedBooking = foundBooking;
            }
        },

        onAppointmentDeleted(appointment) {
            let foundBooking = this.bookings.find(x => x.Id == appointment.Id);

            if(!foundBooking) {
                return;
            }

            if(this.selectedBooking && this.selectedBooking.Id == appointment.Id) {
                this.selectedBooking = null;
            }

            // TODO: Instead of replace, merge the details
            let index = this.bookings.indexOf(foundBooking);
            if(index == -1) {
                return;
            }

            this.bookings.splice(index, 1);
        }

    },

    data() {
        return {
            bookings: [],
            submittingRequest: false,
            selectedBooking: null,
            showSettings: false,
            canAdjustScheduleSettings: false,
            filterMode: 'AllChats',
            pageAdmins: [],
            chats: [],
        }
    },
    
    created() {
        this.build();
    },

    mounted() {
        window.$bus.$on('On-New-Announcements', this.loadBookings);
        window.$bus.$on('On-Appointment-Updated', this.onAppointmentUpdated);
        window.$bus.$on('On-Appointment-Deleted', this.onAppointmentDeleted);
    },

    beforeUnmount() {
        window.$bus.$off('On-New-Announcements', this.loadBookings);
        window.$bus.$off('On-Appointment-Updated', this.onAppointmentUpdated);
        window.$bus.$off('On-Appointment-Deleted', this.onAppointmentDeleted);
    },
}
</script>