<template>
    <div v-if="!submitting"
            class='app-main-container'
            :class="{'main-sidebar-visible': showSidebar}">
        <div v-if="environmentIsDevTest && forceShowTestBadge"
             class="test-env-badge"
             @click="displayTestEnvironmentMessage"
             @contextmenu.stop="onTestEnvironmentContextMenu">
            {{environmentBadge}}
        </div>
        <header>
            <div class="header-container">
                <div class="icomoon menu-button"
                     @click="showNavigation = !showNavigation">
                    
                </div>
            </div>

            <span class="header-location-name">
                {{ lastKnownTitle}} 
            </span>

            <div class="header-container">
               <template v-for="headerSection in headerSections">

                    <template v-if="headerSection.SectionTypeCode == 'AppLink' && headerSection.AppLinkTypeCode == 'MyFavorites'">
                        <router-link class="icomoon show-on-xs"
                                    :to="{path:'/favorites'}">
                            {{headerSection.IconUrl}}
                        </router-link>
                        <a class="icomoon hide-on-xs"
                           @click="showFavoriteList">
                            {{headerSection.IconUrl}}
                        </a>
                    </template>

                    <template v-else-if="headerSection.SectionTypeCode == 'AppLink' && headerSection.AppLinkTypeCode == 'Announcements'">
                        <router-link class="icomoon show-on-xs"
                                    :to="{path:'/myactivity'}"
                                    :class="{'blink-notification': AppState.notifications.UnreadAnnouncementCodes.length > 0}">
                            {{headerSection.IconUrl}}
                            <span class="notification-badge" v-if="AppState.notifications.UnreadAnnouncementCodes.length > 0"></span>
                        </router-link>
                        <a class="icomoon hide-on-xs"
                           @click="showMiniAnnouncementList">
                            {{headerSection.IconUrl}}
                            <span class="notification-badge" v-if="AppState.notifications.UnreadAnnouncementCodes.length > 0"></span>
                        </a>
                    </template>
                    
                    <template v-else-if="headerSection.SectionTypeCode == 'AppLink' && headerSection.AppLinkTypeCode == 'DiscussionArea'">
                        <router-link class="icomoon show-on-xs"
                                    :to="{name:'DiscussionList'}">
                            
                            <span class="notification-badge" v-if="AppState.notifications.UnreadChatCodes.length > 0"></span>
                        </router-link>
                        <a class="icomoon hide-on-xs"
                           @click="showMiniChatList">
                            
                            <span class="notification-badge" v-if="AppState.notifications.UnreadChatCodes.length > 0"></span>
                        </a>
                    </template>

                    <template v-else-if="headerSection.SectionTypeCode == 'AppLink' && headerSection.AppLinkTypeCode == 'Networking'">
                        <router-link class="icomoon show-on-xs"
                                    :to="{name:'mynetwork'}">
                            {{headerSection.IconUrl}}
                            <span class="online-users-badge" v-if="AppState.onlineAttendeeCount > 1">{{AppState.onlineAttendeeCount}}</span>
                        </router-link>
                        <a class="icomoon hide-on-xs"
                           @click="showMiniNetworkList">
                            {{headerSection.IconUrl}}
                            <span class="online-users-badge" v-if="AppState.onlineAttendeeCount > 1">{{AppState.onlineAttendeeCount}}</span>
                        </a>
                    </template>

                    <template v-else-if="headerSection.SectionTypeCode == 'Discussion'">
                        <router-link class="icomoon show-on-xs"
                                    :class="{'active': unreadGlobalChats.indexOf(headerSection.RelatedItemCode) != -1}"
                                    :to="{name:'DiscussionInternalDirect', params: {discussionCode: headerSection.RelatedItemCode}}">
                            {{headerSection.IconUrl}}
                            <span class="notification-badge" 
                                  v-if="unreadGlobalChats.indexOf(headerSection.RelatedItemCode) != -1">
                            </span>
                        </router-link>
                        <a class="icomoon hide-on-xs"
                           :class="{'active': unreadGlobalChats.indexOf(headerSection.RelatedItemCode) != -1}"
                           @click="showInlineChat(headerSection.RelatedItemCode)">
                            {{headerSection.IconUrl}}
                            <span class="notification-badge" 
                                  v-if="unreadGlobalChats.indexOf(headerSection.RelatedItemCode) != -1">
                            </span>
                        </a>
                    </template>

                    <a v-else class="icomoon" @click="tryNavigate(getRouterLink(headerSection))">
                        <img-g v-if="headerSection.IconUrl && headerSection.IconUrl.includes('http')"
                                :src="headerSection.IconUrl"
                                class="responsive home-item-icon" />
                        <span v-else-if="headerSection.IconUrl" class="icomoon home-item-icon">
                            {{ headerSection.IconUrl }}
                        </span>
                    </a>
                </template>
                <router-link :to="{name: 'EditMyProfile'}"
                            class="round-button">
                    <img-g :src="AppState.attendee.ContactInfo.PhotoUrl"
                            fallbackSrc="/img/person.png" />
                </router-link>
            </div>
        </header>

        <main class="app-main-content-container">
            <nav class="app-main-nav"
                 :class="{'nav-show': showNavigation}">
                <!-- Dismiss menu on click -->
                <div class="app-main-nav-link app-main-home-link text-home-item"
                     @click="goHome">
                    <span class="icomoon home-item-icon"></span>
                    <span class="home-item-title">
                        Home
                    </span>
                </div>            
                <template v-for="(section, i) in menuSections">
                    <div v-if="attendeeAllowedToViewSection(section) && isSectionVisible(section)"
                         v-bind:key="section.Code"
                            @click="tryNavigate(getRouterLink(section,i))"
                            class="app-main-nav-link active-class"
                            :class="[section.Size == 'menuonly-image' ? 'image-home-menu-item' : 'text-home-item']">
                        <img-g v-if="section.Size == 'menuonly-image' && section.BackgroundImage"
                            :src="section.BackgroundImage"
                            class="responsive" />
                        <span v-else-if="section.IconUrl" class="icomoon home-item-icon">
                            {{ section.IconUrl }}
                        </span>
                        <span class="home-item-title">
                            <template v-if="section.SectionTypeCode == 'AppLink' && section.AppLinkTypeCode == 'DiscussionArea'">
                                <span class="notification-badge" v-if="AppState.notifications.UnreadChatCodes.length > 0"></span>
                            </template>
                            <template v-else-if="section.SectionTypeCode == 'AppLink' && section.AppLinkTypeCode == 'Announcements'">
                                <span class="notification-badge" v-if="AppState.notifications.UnreadAnnouncementCodes.length > 0"></span>
                            </template>
                            {{section.Title}} 
                            <template v-if="AppState.onlineAttendeeCount > 1 && section.SectionTypeCode == 'AppLink' && section.AppLinkTypeCode == 'Networking'">
                                <span v-if="AppState.onlineAttendeeCount > 1">&nbsp;({{AppState.onlineAttendeeCount}})</span>
                            </template>
                        </span>
                    </div>
                </template>
            </nav>

            <div class="inner-app-content">
                <router-view ref="mainContentViewer"
                             v-slot="{ Component }">
                    <transition name="fade" mode="out-in">
                        <component :is="Component" />
                    </transition>

                </router-view>

            </div>

            <mini-call-window ref="callWindow">

            </mini-call-window>

            <lite-poll-answer>

            </lite-poll-answer>

            <div class="main-sidebar"
                 v-if="showSidebar">
                <chat-list :isInline="true"
                           @request-close="sidebarMode = null"
                           v-if="sidebarMode == 'chat'">
                </chat-list>
                <network-list :onlineOnly="true"
                              :code="'home'"
                              @request-close="sidebarMode = null"
                              v-else-if="sidebarMode == 'network'">
                </network-list>
                <announcement-list @request-close="sidebarMode = null"
                                   v-else-if="sidebarMode == 'announcement'">

                </announcement-list>
                <favorites-list :isInline="true"
                                @request-close="sidebarMode = null"
                                v-else-if="sidebarMode == 'favorites'">
                </favorites-list>
                <agenda-schedule :sidebarParams="sidebarParams"
                                 @request-close="sidebarMode = null"
                                 v-else-if="sidebarMode == 'schedule'">

                </agenda-schedule>
                <chat-area v-else-if="sidebarMode == 'chatroom'"
                           v-on:closerequest="onSetSidebarMode(null)"
                           :discussionCode="sidebarParams.discussionCode"
                           :isInline="true">
                </chat-area>
                <contact-info-editor-pane v-else-if="sidebarMode == 'contact-info'"
                                          @closerequest="onSetSidebarMode(null)"
                                          :sidebarParams="sidebarParams" />
                <page-detail-editor-pane v-else-if="sidebarMode == 'page-editor'"
                                         @closerequest="onSetSidebarMode(null)"
                                         :sidebarParams="sidebarParams" />
                    
            </div>


            <div class="mini-chat-container">
                <div class="mini-chat-window hide-on-xs"
                     v-for="miniChatCode in miniChatCodes"
                     v-bind:key="miniChatCode">
                    <chat-area :discussionCode="miniChatCode"
                               :isInline="true"
                               v-on:closerequest="handleCloseChatRequest">
                    </chat-area>
                </div>
            </div>

            <div class="nav-overlay"
                 @click="showNavigation = false"
                 :class="{'nav-show': showNavigation}">

            </div>

        </main>
        <div class="iframe-overlay"
             :class="{'iframe-show': showIFrameContainer}">
            <div>
                <div class="page-side-panel">
                    <div class="iframe-controls">
                        <span>{{iFrameDetails.Title}}</span>

                        <button class="icomoon" 
                                type="button"
                                @click="closeIFrame">
                            
                        </button>
                    </div>
                    <iframe v-if="iFrameDetails.Url" 
                            :src="iFrameDetails.Url">

                    </iframe>
                </div>
            </div>
        </div>
        <div class="signalr-connection-overlay"
                :class="[`state-${signalRConnectMode}`]">
            <button @click="showSignalRStateMessage"
                    class="round-button icomoon">
                
            </button>
        </div>
        <!-- <audio preload="auto" class="ringtone" ref="messagetone" src="/incomingmessage.mp3"></audio> -->

        <div class="debugger-window"
             v-if="showDebugger && bus && bus.log">
            <button @click="bus.dump()">
                Dump to Console
            </button>
            <h2>unreadGlobalChats</h2>
            <div>
                {{unreadGlobalChats}}
            </div>

            <div>
                sidebarMode: {{sidebarMode}}
            </div>

            <h2>sidebarParams</h2>
            <div>
                {{sidebarParams}}
            </div>

            <h2>Announcements</h2>
            <div>
                {{announcements}}
            </div>

            <h2>headerSections</h2>
            <div>
                {{headerSections}}
            </div>
            <div v-for="item in bus.log">
                {{item}}
            </div>
        </div>
    </div>
    <div v-else class="main-page-loading-container">
        <loading-container />
        <div v-if="!loadingErrorMessage">
            Loading your passport, please wait... {{loadingErrorMessage}}
        </div>
        <div v-else>
            {{loadingErrorMessage}}
        </div>
    </div>
</template>
<script>

import Token from './authentication/token'
import { IconLookups, Lookups } from './common'

import * as signalR from '@microsoft/signalr';

import Common from './common'
import litePollAnswer from './lite.poll.answer.vue';

import { AppState } from './appstate.js';
import tinycolor from "tinycolor2";

class TractusLogger {
    constructor(debugBus) {
        this.debugBus = debugBus;
    }

    log(logLevel, message) {
        this.debugBus.$emit('on-signalr-debug', {
            received: new Date(),
            level: logLevel,
            message: message
        });
    }
}

const DoNotShowPopupRouteNames = [
    'broadcastConsole',
    'DiscussionForBroadcast',
    'shareddocumentinternalbroadcast'
];

export default {
    components: { litePollAnswer },
    mixins: [
        Token,
        Common
    ],   

    watch: {
        'announcements.UnreadAnnouncementCodes'() {
            this.checkAndEmitAppAlertIconChange();
        },

        'announcements.UnreadChatCodes'() {
            this.checkAndEmitAppAlertIconChange();
        },
    },

    computed: {

        AppState() {
            return AppState;
        },

        headerSections(){
            if(!AppState.attendee?.Show?.HeaderSections) {
                return [];
            } 

            let toReturn = [];
            for(let i = 0; i < AppState.attendee.Show.HeaderSections.length; i++){
                let section = AppState.attendee.Show.HeaderSections[i];
                if(this.attendeeAllowedToViewSection(section)){
                    toReturn.push(section);
                }
            }
            return toReturn;
        },
        //criteria of if attendee is allowed to view ak004 online attendees no
        environmentBadge() {

            if(window.location.hostname.indexOf('localhost') != -1) {
                return "Local";
            }

            return 'Testing';
        },

        environmentIsDevTest() {
            return window.location.hostname.indexOf('localhost') != -1
                || window.location.hostname.indexOf('eventtest.tractus') != -1;
        },

        menuSections() {
            if(!AppState.attendee?.Show?.Sections) {
                return [];
            } 

            return AppState.attendee.Show.Sections;
        },

        routeName() {
            return this.$route.name;
        },

        isHome() {
            return this.$route &&
                this.$route.name == 'home';
        },

        doNotShowPopups() {
            return this.$route
                && DoNotShowPopupRouteNames.indexOf(this.$route.name) != -1;
        },

        showIFrameContainer() {
            return (this.iFrameDetails.Title && this.iFrameDetails.Url) ? true : false;
        },

        showSidebar() {
            return this.sidebarMode;
        }
    },

    methods: {
        isSectionVisible(x) {
            return x.Size.indexOf('-home-image') == -1
                && x.SectionTypeCode != 'HomeContent';
        },

        onTestEnvironmentContextMenu() {
            window.$bus.changeLogEventStatus();
            this.showDebugger = window.$bus.toggleLog;
        },

        displayTestEnvironmentMessage() {
            this.testBadgeClicks++;

            if(this.testBadgeClicks == 5) {
                this.forceShowTestBadge = false;
                this.$awn.warning('Badge disabled. I hope you know what you are doing...');
            }

            console.log(this.$awn);
            this.$awn.warning('This is a testing environment. Functions like sending emails may not work as expected.');
        },

        showFavoriteList(){
            this.sidebarMode = (this.sidebarMode == 'favorites') ? null : 'favorites';
        },
        closeIFrame() {
            this.iFrameDetails.Url = null;
            this.iFrameDetails.Title = null;
        }, 
        
        onIFrameOpenRequest(request){

            request.Url = request.Url.replace("INTERNAL:::", "")
                    .replace('{{showCode}}', AppState.attendee.Show.Code)
                    .replace("{{attendeeCode}}", AppState.attendee.Code);
            
            request.Title = request.Title || 'View Item';

            this.iFrameDetails = request; 

            if(request.EntityTypeCode && request.EntityCode) {
                this.postAnalytics(
                    AppState.attendee.Code,
                    'ViewDownload',
                    request.EntityCode,
                    request.EntityTypeCode,
                    AppState.attendee.Show.Code,
                    this.iFrameDetails.Url);

            }
        },

        onLinkOpenRequest(request) {
            this.openLink(
                request.Url,
                AppState.attendee, 
                request.EntityTypeCode, 
                request.EntityCode);
        },

        handleCloseChatRequest(discussionCode) {
            let index = this.miniChatCodes.indexOf(discussionCode);
            if(index == -1) {
                return;
            }

            this.miniChatCodes.splice(index, 1);
        },

        onChatSelected(chatCode) {
            if(!chatCode) {
                return;
            }

            if(this.$route 
                && this.$route.params 
                && this.$route.params.discussionCode
                && this.$route.params.discussionCode == chatCode) {
                return;
            }

            this.handleShowChat(chatCode);
        },

        handleShowChat(chatCode) {
            if(this.isOnExtraSmallPlatform()) {
                this.$router.push({
                    name: 'DiscussionInternal',
                    params: {
                        discussionCode: chatCode
                    }
                });
                return;
            }

            if(this.miniChatCodes.indexOf(chatCode) != -1) {
                return;
            }

            if(this.$route.name == 'DiscussionList'
                || this.$route.name == 'DiscussionInternal') {
                this.$router.push({
                    name: 'DiscussionInternal',
                    params: {
                        discussionCode: chatCode
                    }
                });

                return;
            } 

            if(this.miniChatCodes.length >= 5) {
                this.handleCloseChatRequest(this.miniChatCodes[0]);
            }

            this.miniChatCodes.push(chatCode);
        },

        showMiniAnnouncementList() {
            if(this.sidebarMode == 'announcement') {
                this.sidebarMode = null;
                return;
            }
            this.sidebarMode = "announcement";
        },


        showMiniChatList() {
            if(this.sidebarMode == 'chat') {
                this.sidebarMode = null;
                return;
            }
            this.sidebarMode = "chat";
        },

        showMiniNetworkList() {
            if(this.sidebarMode == 'network') {
                this.sidebarMode = null;
                return;
            }
            this.sidebarMode = "network";
        },

        showInlineChat(discussionCode) {
            this.onSetSidebarMode("chatroom", {
                discussionCode: discussionCode
            });

            let unreadIndex = this.unreadGlobalChats.indexOf(discussionCode);

            if(unreadIndex != -1) {
                this.unreadGlobalChats.splice(unreadIndex, 1);
            }
        },

        onSetSidebarMode(mode, sidebarParams) {
            if(!mode) {
                this.sidebarMode = null;
                this.sidebarParams = null;
                return;
            }

            if(mode == this.sidebarMode
                && mode != 'contact-info') {
                this.sidebarMode = null;
                this.sidebarParams = null;
                return;
            }

            this.sidebarParams = sidebarParams;
            this.sidebarMode = mode;
        },

        attemptNavigate(announcement) {
            if (
                !announcement.RelatedTypeCode ||
                announcement.RelatedTypeCode == "null" ||
                !announcement.RelatedCode ||
                announcement.RelatedCode == "null"
            ) {
                return;
            }

            if(announcement.RelatedTypeCode.toLowerCase() == 'discussion'
                && !this.isOnExtraSmallPlatform()) {
                
                this.handleShowChat(announcement.RelatedCode);
                return;
            } 

            if(announcement.RelatedTypeCode.indexOf("_BOOKING") != -1) {
                // Special case - navigate to booking area.
                let relatedTypeCode = announcement.RelatedTypeCode.split('_')[0];
                let relatedCode = announcement.RelatedCode.split('_')[0];
                let bookingCode = announcement.RelatedCode.split('_')[1];

                let route = {
                    name: `ViewAppointmentRequests${relatedTypeCode[0]}${relatedTypeCode.toLowerCase().substr(1)}`,
                    params: {
                        code: relatedCode,
                        requestcode: bookingCode
                    }
                };

                this.$router.push(route);

                return;
            }

            let tractusLink = `tractus://${announcement.RelatedTypeCode}?c=${announcement.RelatedCode}&s=${AppState.attendee.Show.Code}`;

            this.openLink(tractusLink, AppState.attendee);
        },

        navigateToAnnouncementPage(){
            this.$router.push({name: 'Notifications' });
        },

        async loadPassportFromWeb(includeBootstrap) {
            try {
                let r = await this.tryGet(`/api/attendee/passport?b=${includeBootstrap}`);

                if(r.Errors.length > 0) {
                    throw r.Errors[0];
                } else {

                    let attendeeDetails = r.Result.Attendee;
                    attendeeDetails.Show = r.Result.Show;

                    AppState.attendee = attendeeDetails;
                    AppState.attendeeCode = attendeeDetails.Code;
                    AppState.show = r.Result.Show;
                    AppState.showCode = r.Result.Show.Code;

                    if(includeBootstrap) {
                        await this.updateAnnouncements(r.Result);
                    }

                    AppState.attendee.DiscussionPermissions = r.Result.DiscussionPermissions;

                    this.rebuildTheme();
                }

                this.set('attendee', r.Result);

            } catch(e) { 
                console.error("On loadPassportFromWeb: ", e);
                throw e;
            }
        },

        async onUpdatedProfilePhoto(photoUrl) {
            AppState.attendee.ContactInfo.PhotoUrl = photoUrl;
            this.set('attendee', AppState.attendee);
        },

        goHome() {
            this.showNavigation = false;
            this.$router.replace({name: 'home' });
        },

        tryNavigate(link) {
            this.showNavigation = false;
            this.doNavigate(link);
        },

        rebuildTheme() {

            let style = document.getElementById('theme-stylesheet');
            if(style) {
                style.innerHTML = '';
            }

            let newMetaColor = '#203864';

            if(AppState.attendee.Show.Theme
                && AppState.attendee.Show.Theme.StyleSheet) {
                
                let candidateSheet = AppState.attendee.Show.Theme.StyleSheet;
                style.innerHTML = candidateSheet;

                let hiddenTitleElem = document.querySelector('.-title-bar');
                
                if(hiddenTitleElem) {
                    let computedStyle = getComputedStyle(hiddenTitleElem);
                    if(computedStyle 
                        && computedStyle
                        && computedStyle.backgroundColor) {
                        try {
                            newMetaColor = `#${this.rgba2hex(computedStyle.backgroundColor)}`;
                        } catch(ex) {
                            newMetaColor = '#203864';
                        }
                    }
                } 

                if(AppState.attendee.Show.SessionCategories
                    && AppState.attendee.Show.SessionCategories.forEach){
                    
                    AppState.attendee.Show.SessionCategories.forEach(c => {
                        let suggestedTextColor = 'var(--text-color)';
                        let color = getComputedStyle(document.body).getPropertyValue(`--S-${c.Code}`);
                        if(color) {
                            try {
                                let l = tinycolor(color).getLuminance();
                                if(l < 0.5) {
                                    suggestedTextColor = tinycolor(color).lighten(90);
                                } else {
                                    suggestedTextColor = tinycolor(color).darken(90);
                                }
                            } catch(ex) {
                                console.error(ex)
                            }
                        }

                        candidateSheet += `.S-${c.Code}-tab{ border-left-color: var(--S-${c.Code}) !important; }`
                        candidateSheet += `.S-${c.Code}-filter{ background: var(--S-${c.Code}); }`
                        candidateSheet += `.S-${c.Code}-Cell{ border-left: 6px solid var(--S-${c.Code}); }`
                        candidateSheet += `.S-${c.Code}-Pill{ background: var(--S-${c.Code}); color: ${suggestedTextColor}; }`
                    });

                }


                if(AppState.attendee.Show.VendorCategories
                    && AppState.attendee.Show.VendorCategories.forEach){
                    

                    AppState.attendee.Show.VendorCategories.forEach(c => {

                        let suggestedTextColor = 'var(--text-color)';
                        let color = getComputedStyle(document.body).getPropertyValue(`--A-${c.Code}`);
                        if(color) {
                            try {
                                let l = tinycolor(color).getLuminance();
                                if(l < 0.15) {
                                    suggestedTextColor = tinycolor(color).lighten(70);
                                } else {
                                    suggestedTextColor = tinycolor(color).darken(70);
                                }
                            } catch(ex) {
                                console.error(ex)
                            }
                        }


                        candidateSheet += `.V-${c.Code}-tab{ border-left-color: var(--V-${c.Code}) !important; }`
                        candidateSheet += `.V-${c.Code}-filter{ background: var(--V-${c.Code}); }`
                        candidateSheet += `.V-${c.Code}-Pill{ background: var(--V-${c.Code}); color: ${suggestedTextColor}; }`
                    }); 
                }

                if(AppState.attendee.Show.AttendeeCategories
                    && AppState.attendee.Show.AttendeeCategories.forEach){

                    AppState.attendee.Show.AttendeeCategories.forEach(c => {

                        let suggestedTextColor = 'var(--text-color)';
                        let color = getComputedStyle(document.body).getPropertyValue(`--A-${c.Code}`);
                        if(color) {
                            try {
                                let l = tinycolor(color).getLuminance();
                                if(l < 0.15) {
                                    suggestedTextColor = tinycolor(color).lighten(70);
                                } else {
                                    suggestedTextColor = tinycolor(color).darken(70);
                                }
                            } catch(ex) {
                                console.error(ex)
                            }
                        }

                        candidateSheet += `.A-${c.Code}-tab{ border-left-color: var(--A-${c.Code}) !important; }`
                        candidateSheet += `.A-${c.Code}-filter{ background: var(--A-${c.Code}); }`
                        candidateSheet += `.A-${c.Code}-Pill{ background: var(--A-${c.Code}); color: ${suggestedTextColor}; }`
                    }); 
                }

                try {
                    let suggestedTextColor = 'var(--inverse-text-color)';
                    let color = getComputedStyle(document.body).getPropertyValue(`--home-item-background`);

                    if(color) {
                        let l = tinycolor(color);
                        if(l.getLuminance() < 0.15) {
                            suggestedTextColor = tinycolor(color).lighten(90);
                        } else {
                            suggestedTextColor = tinycolor(color).darken(90);
                        }

                    }

                    candidateSheet += `.section-item{color: ${suggestedTextColor};}`
        
                } catch {

                }

                style.innerHTML = candidateSheet;   
            }

            if(!newMetaColor) {
                newMetaColor = '#203864';
            }

            let metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute('content', newMetaColor);
        },
        
        rgba2hex(orig) {
            var a, isPercent,
                rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
                alpha = (rgb && rgb[4] || "").trim(),
                hex = rgb ?
                (rgb[1] | 1 << 8).toString(16).slice(1) +
                (rgb[2] | 1 << 8).toString(16).slice(1) +
                (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

            if (alpha !== "") {
                a = alpha;
            } else {
                a = 1;
            }
            // multiply before convert to HEX
            // a = ((a * 255) | 1 << 8).toString(16).slice(1)
            // hex = hex + a;

            return hex;
        },

        browserVisibilityChanged() {
            let state = document.visibilityState;
            let eventName = `AppState:${state}`;
            this.postAnalytics(AppState.attendee.Code,eventName,null,null,AppState.attendee.Show.Code);
        },

        showSignalRStateMessage() {
            let connectionStateFormal = this.signalRConnectMode[0].toUpperCase() + this.signalRConnectMode.substr(1);

            let connectionStateMessage = '';

            if(this.signalRConnectMode == 'connected') {
                connectionStateMessage = 'You are connected to Tractus. Everything is working normally.';
            } else if(this.signalRConnectMode == 'reconnecting') {
                connectionStateMessage = 'We are trying to reconnect you to Tractus. Some functions such as chat may not work.';
            } else if(this.signalRConnectMode == 'connecting') {
                connectionStateMessage = 'We are trying to connect you to Tractus. Some functions such as chat may not work.';
            } else if(this.signalRConnectMode == 'disconnected') {
                connectionStateMessage = 'You have been disconnected from Tractus. We will try to reconnect you soon. Some functions such as chat may not work.';
            }

            this.$awn.confirm(connectionStateMessage, false, false, {
                labels: {
                    confirm: `Connection Status - ${connectionStateFormal}`
                }
            });

        },

        async loadAttendeePassport(){

            document.onvisibilitychange = this.browserVisibilityChanged;

            this.submitting = true;

            try {
                this.loadingErrorMessage = null;
                await this.loadPassportFromWeb(true);
            } catch(ex) {
                this.loadingErrorMessage = "Could not load your information. We will try again in a few moments. Please check that you are connected to the internet.";
                setTimeout(this.loadAttendeePassport, 5000);
                return;
            }

            if(window.$signalRConnection.notInitialized) {
                this.signalRConnectMode = 'connecting';

                // Need to build the signalR connection
                window.$signalRConnection = new signalR.HubConnectionBuilder()
                    .withUrl(this.getApiUrl() + '/hub/da', {
                        accessTokenFactory: () => {
                            let t = this.getUserDetails().token;
                            return t;
                        }})
                    .withAutomaticReconnect([0, 1000, 2000, 4000, 8000, 16000, 30000, 30000, 30000, 30000])
                    // .configureLogging(signalR.LogLevel.Error)
                    // .configureLogging(new TractusLogger(window.$bus))
                    .build();

                window.$signalRConnection.onreconnecting(() => {
                    this.signalRConnectMode = 'reconnecting';
                    window.$bus.$emit('reconnecting-to-signalr');
                });

                window.$signalRConnection.onreconnected(() => {
                    this.signalRConnectMode = 'connected';
                    window.$bus.$emit('connected-to-signalr');
                });

                window.$signalRConnection.onclose((ex) => {
                    this.signalRConnectMode = 'disconnected';
                    setTimeout(this.ensureSignalRConnected, 5000);
                });

                window.$signalRConnection.on('ControlChannelMessage', this.onControlChannelMessage);

                window.$signalRConnection.on('UnreadItemUpdate', this.onNotifiedOfUnreadItem);

                window.$signalRConnection.on('MarkRead', this.onNotifiedOfReadItem);

                window.$signalRConnection.on('DiscussionNameChange', this.onDiscussionNameChange);

                await this.ensureSignalRConnected();
            }

            this.postLoginEvent(
                AppState.attendee.Code,
                AppState.attendee.Show.Code);

            this.requestPermissionToSendNotifications();

            this.submitting = false;
        },

        async ensureSignalRConnected() {
            if(window.$signalRConnection.notInitialized) {
                return;
            }

            try {
                await window.$signalRConnection.start();

                this.signalRConnectMode = 'connected';

                window.$bus.$emit('connected-to-signalr');

            } catch(ex) {
                if(ex == "Error: Cannot start a HubConnection that is not in the 'Disconnected' state.") {
                    console.warn("Already connected to SignalR - aborting ensure.");
                    return;
                }
                this.signalRConnectMode = 'disconnected';
                console.error('ENSURE SIGNALR CONNECTED ==---', ex);
                setTimeout(this.ensureSignalRConnected, 5000);
            }
        },

        onDiscussionNameChange(details) {
            window.$bus.$emit('Discussion-Name-Change', details);
        },

        /**
         * Fired when a new item is unread, such as a chat post or an announcement
         * that goes unclicked. Received from the server.
         * 
         * @param details The item that was marked as read.
         * @param details.TypeCode The type of item marked as new/unread. Will be Discussion, Chat, or Announcement.
         * @param details.EntityCode The specific entity that is new/unread.
         */
        onNotifiedOfUnreadItem(details) {
            if(details.TypeCode == 'Announcement' && AppState.notifications.UnreadAnnouncementCodes.indexOf(details.EntityCode) == -1) {
                AppState.notifications.UnreadAnnouncementCodes.push(details.EntityCode);
            } else if (details.TypeCode == 'Chat' && AppState.notifications.UnreadChatCodes.indexOf(details.EntityCode) == -1) {
                AppState.notifications.UnreadChatCodes.push(details.EntityCode);
            } else if (details.TypeCode == 'Discussion' && AppState.notifications.UnreadDiscussionCodes.indexOf(details.EntityCode) == -1) {
                AppState.notifications.UnreadDiscussionCodes.push(details.EntityCode);
            }   
            
            window.$bus.$emit('Unread-Item', details);
        },

        /**
         * Fired when an item is marked as read. This is received
         * by the server, even if we were the ones that marked the
         * item as read.
         * 
         * @param details The item that was marked as read.
         * @param details.TypeCode The type of item marked as read. Will be Discussion, Chat, or Announcement.
         * @param details.EntityCode The specific entity read. If null/undefined, mark ALL entities of that type read.
         */
        onNotifiedOfReadItem(details) {
            if(details.TypeCode == 'Announcement') {

                if(details.EntityCode) {
                    let index = AppState.notifications.UnreadAnnouncementCodes.indexOf(details.EntityCode);
                    if(index != -1) {
                        AppState.notifications.UnreadAnnouncementCodes.splice(index, 1);
                    } 
                } else {
                    AppState.notifications.UnreadAnnouncementCodes = [];
                }

            } else if (details.TypeCode == 'Chat') {
                
                if(details.EntityCode) {
                    let index = AppState.notifications.UnreadChatCodes.indexOf(details.EntityCode);
                    if(index != -1) {
                        AppState.notifications.UnreadChatCodes.splice(index, 1);
                    } 
                } else {
                    AppState.notifications.UnreadChatCodes = [];
                }

            } else if (details.TypeCode == 'Discussion') {

                if(details.EntityCode) {
                    let index = AppState.notifications.UnreadDiscussionCodes.indexOf(details.EntityCode);
                    if(index != -1) {
                        AppState.notifications.UnreadDiscussionCodes.splice(index, 1);
                    } 
                } else {
                    AppState.notifications.UnreadDiscussionCodes = [];
                }
            }          
            
        },

        /**
         * Notifies the server that an item has been read. Will also update
         * the local copy of the unread codes array.
         * 
         * @param details The item that was marked as read.
         * @param details.TypeCode The type of item marked as read. Will be Discussion, Chat, or Announcement.
         * @param details.EntityCode The specific entity read. If null/undefined, mark ALL entities of that type read.
         */
        notifyMarkAsRead(details) {
            this.onNotifiedOfReadItem(details);

            if(details.EntityCode) {

                window.$signalRConnection.invoke('NotifyItemRead', 
                    AppState.attendee.Show.Code,
                    AppState.attendee.Code,
                    details.TypeCode,
                    details.EntityCode);

            } else {

                window.$signalRConnection.invoke('NotifyAllRead', 
                    AppState.attendee.Show.Code,
                    AppState.attendee.Code,
                    details.TypeCode);

            }
        },

        onJoinedCall(roomCode) {
            // We are using CHANNEL message here because only people
            // who are in the DA care that someone's in the call.

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.attendee.Show.Code,
                FromCode: AppState.attendee.Code,
                TypeCode: 'JoinedCall',
                DID: this.getDeviceId(),
                DiscussionAreaCode: roomCode,
                Message: {}
            });
        }, 

        onLeftCall(roomCode) {
            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.attendee.Show.Code,
                FromCode: AppState.attendee.Code,
                TypeCode: 'LeftCall',
                DID: this.getDeviceId(),
                DiscussionAreaCode: roomCode,
                Message: {}
            });
        },

        async onControlChannelMessage(message) {
            if(message.ShowCode != AppState.attendee.Show.Code) {
                return;
            }
            
            let isInWebinar = false;
        
            try {

                let content = this.$refs.mainContentViewer;

                if(content) {
                    isInWebinar = content.showWebinarView;
                }

            } catch {

            }


            if(message.TypeCode == 'NewAnnouncement') {
                window.$bus.$emit('On-New-Announcements', message.Message);

                if(isInWebinar) {
                    return;
                }

                if(this.sidebarMode == 'announcement'
                    && document.visibilityState != 'hidden') {
                    return;
                }

                this.tryDisplayAnnouncement(message.Message);                                
            } else if (message.TypeCode == 'Hello'
                || message.TypeCode == 'Goodbye') {
                window.$bus.$emit('Received-Hello-Goodbye', message.Message, message.TypeCode, message.DiscussionAreaCode);
            } else if ((message.TypeCode == 'JoinedCall' || message.TypeCode == 'LeftCall') && message.FromCode != AppState.attendee.Code) {

                window.$bus.$emit('notify-discussion-call', message.DiscussionAreaCode);

            } else if (message.TypeCode == 'ChatPost') {

                let ignoreCode = this.$route.params
                    && this.$route.params.discussionCode
                    && this.$route.params.discussionCode == message.Message.DiscussionAreaCode
                    ? this.$route.params.discussionCode
                    : null;

                let isMiniChatOpen = this.miniChatCodes.find(x => x == message.Message.DiscussionAreaCode);

                if(isMiniChatOpen) {
                    return;
                }

                if(ignoreCode) {
                    return;
                }

                let doNotNotify = this.$route.name.includes('Discussion')
                    || this.$route.name.includes('discussion')
                    || message.Message.Quiet
                    || isInWebinar;
                    
                if(doNotNotify) {
                    return;
                }

                if(this.$refs.mainContentViewer 
                    && this.$refs.mainContentViewer.showWebinarView
                    || this.$refs.callWindow 
                    && this.$refs.callWindow.roomCode) {
                    return;
                }

                this.tryDisplayAnnouncement({
                    RelatedTypeCode: 'Discussion',
                    RelatedCode: message.Message.DiscussionAreaCode,
                    DisplayMode: 'Toast',
                    Subject: `${message.Message.From || 'New Message'}`,
                    Body: message.Message.Body,
                    GoMode: 'Immediate',
                    Timeout: 10000,
                    ChatRelatedEntityTypeCode: message.Message.EntityTypeCode,
                    ChatRelatedEntityCode: message.Message.EntityCode,
                });

            } else if (message.TypeCode == 'IncomingCall') {

                // If we are already in a webinar, then ignore the incoming call.

                if(this.$refs.mainContentViewer 
                    && this.$refs.mainContentViewer.showWebinarView
                    || this.$refs.callWindow 
                    && this.$refs.callWindow.roomCode) {

                    window.$bus.$emit('incoming-call-while-busy', message.Message);

                    return;
                }

                window.$bus.$emit('incoming-call', message.Message);

            } else if (message.TypeCode == 'OnlineUsers') {
                AppState.onlineAttendeeCodes = message.Message;
            } else if (message.TypeCode == 'UpdatedBooking') {
                window.$bus.$emit('On-Appointment-Updated', message.Message);
            } else if (message.TypeCode == 'DeletedBooking') {
                window.$bus.$emit('On-Appointment-Deleted', message.Message);
            } else if (message.TypeCode == 'ProfileView') {
                window.$bus.$emit('profile-view'); 
            } else if (message.TypeCode == 'ForceReboot') {
                location.reload();
            } else if (message.TypeCode == 'EntityUpdated') {
                this.handleEntityUpdated(message.Message);
            } else if (message.TypeCode == 'WebinarUpdate') {
                window.$bus.$emit('webinar-update', message.Message);
            } else if (message.TypeCode == 'WebinarControl') {
                window.$bus.$emit('webinar-control-message', message.Message);
            } else if(message.TypeCode == 'JoinChatForce'){
                window.$bus.$emit('join-chat-force', message.Message.Message);
            } else if(message.TypeCode == 'TypingIndicator') {
                window.$bus.$emit('typing-indicator', message.DiscussionAreaCode, message.Message)
            } else if(message.TypeCode == 'LitePollUpdated') {
                window.$bus.$emit('lite-poll-updated', message.Message);
            } else if(message.TypeCode == 'LitePollChange') {
                window.$bus.$emit('lite-poll-state-change', message.Message);
            } else if(message.TypeCode == 'LitePollAnswer') {
                window.$bus.$emit('lite-poll-answered', message.Message);
            } else if(message.TypeCode == 'RefreshOnlineUsers') {
                AppState.onlineAttendeeCount = message.Message.Count;
                window.$bus.$emit('refresh-online-users', message.Message);
            } else if(message.TypeCode == 'FavoritesUpdated') {
                window.$bus.$emit('favorites-updated', message.Message);
            } else if (message.TypeCode == 'PostDeleted') {
                window.$bus.$emit('post-deleted', message.Message);
            } else if(message.TypeCode == 'DeleteAnnouncement') {
                window.$bus.$emit('deleted-announcement', message.Message);
            } else if(message.TypeCode == 'RecordPermUpdate') {
                window.$bus.$emit('recording-perm-update', message.Message);
            } else if(message.TypeCode == 'RemovedFromDiscussion' || message.TypeCode == 'KickedFromDiscussion') {
                window.$bus.$emit('removed-from-discussion', {
                    Kicked: message.TypeCode == 'KickedFromDiscussion',
                    DiscussionCode: message.DiscussionCode
                });
            } else if(message.TypeCode == 'AddedToDiscussion') {
                window.$bus.$emit('added-to-discussion', {
                    DiscussionCode: message.DiscussionCode
                });
            } else if(message.TypeCode == 'DiscussionInvitePermissionsUpdated') {
                AppState.attendee.DiscussionPermissions = message.Message.Updates;                
            } else if(message.TypeCode == 'WebinarLiveChange') {
                window.$bus.$emit(message.TypeCode, message.Message);
            } else if(message.TypeCode == 'GlobalChatNewPost') {
                this.handleGlobalChatUpdated(message);
            } else if(message.TypeCode == 'WebinarHook') {
                window.$bus.$emit('WebinarHook', message.Message);
            } else if(message.TypeCode == 'VideoConvertUpdate') {
                window.$bus.$emit('VideoConvertUpdate', message.Message);
            } else {
                window.$bus.$emit(message.TypeCode, message.Message, message);
            }
        },

        handleGlobalChatUpdated(message) {
            if(this.sidebarParams 
                && this.sidebarParams.discussionCode
                && this.sidebarParams.discussionCode == message.DiscussionAreaCode) {
            
                return;
            }

            let discussionCode = message.DiscussionAreaCode;

            if(this.unreadGlobalChats.indexOf(discussionCode) != -1) {
                return;
            }

            this.unreadGlobalChats.push(discussionCode);
        },

        handleEntityUpdated(message) {
            window.$bus.$emit('entity-updated', message);

            let attendeeUpdated = message.EntityTypeCode == 'ATTENDEE' && message.EntityCode == AppState.attendee.Code;
            let showUpdated = message.EntityTypeCode == 'SHOW' && message.EntityCode == AppState.attendee.Show.Code;

            if(attendeeUpdated || showUpdated) {
                this.loadPassportFromWeb(false);
            }
        },

        internalShowAnnouncement(announcement) {
            let doNotNotify = this.$route.name == 'DiscussionList'
                || this.$route.name == 'DiscussionInternal'
                || this.$route.name == 'broadcastConsole'
                || this.$route.name == 'DiscussionForBroadcast'
                || this.$route.name.includes('Discussion');
                
            if(doNotNotify && !announcement.OverrideDoNotNotify) {
                return;
            }

            this.tryDisplayAnnouncement({
                DisplayMode: announcement.DisplayMode || 'Toast',
                Subject: announcement.Subject,
                Body: announcement.Body,
                GoMode: 'Immediate',
                Timeout: 10000
            });
        },

        createModalPopup(announcement,options,imageUrl,textIcon){
            if(this.doNotShowPopups) {
                return;
            }

            Object.assign(options, {
                labels: {
                        confirm: `<span class="announcement-title">${announcement.Subject}</span>`,
                        confirmOk: (announcement.RelatedTypeCode && announcement.RelatedCode) ? "Go" : "Close",
                        confirmCancel: "Close"
                }
            });

            Object.assign(options, {icons: {enabled: false}})

            let imgTag = imageUrl
                ? `<img src="${imageUrl}" class="responsive hide-until-create fade-in-on-create"><br>`
                : '';

            this.$awn.confirm(`${imgTag}<p>${announcement.Body}</p>`,
            () => {
                //Go button or close
                if(announcement.RelatedTypeCode && announcement.RelatedCode){
                    this.attemptNavigate(announcement);
                }
                
            },
            (announcement.RelatedTypeCode && announcement.RelatedCode) ?
            () => {

            }
            : false,
            options
            );
        },

        async updateAnnouncements(result) {
            
            AppState.notifications.HasUnreadAnnouncements = result.HasUnreadAnnouncements;
            AppState.notifications.UnreadChatCodes = result.UnreadChatCodes;
            AppState.notifications.UnreadDiscussionCodes = result.UnreadDiscussionCodes;

            AppState.onlineAttendeeCount = result.OnlineAttendeeCount;

            console.log(AppState, result)
        },

        async tryDisplayAnnouncement(announcement) {
            // We need a way to gracefully ask the user's permission to show
            // SILENT notifications. 
            //
            // Once we have that we can run with this code.
            
            // Special case - don't display booking info if we're in the vendor area.
            if(announcement.RelatedTypeCode && announcement.RelatedTypeCode.indexOf("_BOOKING") != -1
                && this.$route.name == 'ViewAppointmentRequestsVendor') {
                return;
            }

            let options = {};

            let imageUrl = null;
            let textIcon = null;

            

            if(announcement.Image){
                imageUrl = announcement.Image;
            } else if (
                announcement.RelatedTypeCode 
                && announcement.RelatedCode
                && (announcement.RelatedTypeCode == 'Attendee' || announcement.RelatedTypeCode == 'Vendor')) {
                imageUrl = this.getEntityPhotoFromTypeAndCode(
                    announcement.RelatedTypeCode,
                    announcement.RelatedCode);

            } else if (announcement.RelatedTypeCode && announcement.RelatedTypeCode.indexOf('_BOOKING') != -1) {

                imageUrl = this.getEntityPhotoFromTypeAndCode(
                    announcement.RelatedTypeCode.split('_')[0],
                    announcement.RelatedCode.split("_")[0]);

            } else if(announcement.ChatRelatedEntityCode 
                && announcement.ChatRelatedEntityTypeCode) {

                imageUrl = this.getEntityPhotoFromTypeAndCode(
                    announcement.ChatRelatedEntityTypeCode,
                    announcement.ChatRelatedEntityCode);

            } else if(announcement.RelatedTypeCode 
                && announcement.RelatedCode) {

                textIcon = this.getEntityPhotoFromTypeAndCode(
                    announcement.RelatedTypeCode,
                    announcement.RelatedCode);
            } else {
                textIcon = Lookups.Notification;
            }

            if(announcement.DisplayMode == 'Modal'){
                this.createModalPopup(announcement,options,imageUrl,textIcon);

                if(announcement.Code) {
                    this.notifyMarkAsRead({
                        TypeCode: 'Announcement',
                        EntityCode: announcement.Code
                    });
                }

                return;
            }

            let imgLinkPrefix = `<div class='notification-icon icomoon'`;
            let imgLinkSuffix = "></div>";

            if(imageUrl) {
                imgLinkPrefix +=  `><img src="${imageUrl}" class="responsive hide-until-create fade-in-on-create"></img`;
            } else if(textIcon) {
                imgLinkPrefix += `><span>${textIcon}</span`;
            }
            
            Object.assign(options,{labels: {tip: `${announcement.Subject}`},
                                    icons: {prefix: imgLinkPrefix, suffix: imgLinkSuffix}});

            //should be commented unless testing
            // Object.assign(options, {durations: {tip:0}});
            
            if(this.doNotShowPopups) {
                return;
            }

            Object.assign(options, {durations: {tip:(announcement.Timeout || 20000)}});

            try {

                this.playSoundEffect('messageTone');

            } catch(ex) {
                console.error(ex);
            }

            let b = this.$awn.tip(`${announcement.Body}`,
                        options);

            if(announcement.GoMode 
                && announcement.GoMode == 'Immediate'
                && announcement.RelatedTypeCode
                && announcement.RelatedCode) {
                
                if(announcement.ChatRelatedEntityCode && announcement.ChatRelatedEntityTypeCode) {

                    b.children[0].addEventListener('click', (e) => {

                        if(announcement.Code) {
                            this.notifyMarkAsRead({
                                TypeCode: 'Announcement',
                                EntityCode: announcement.Code
                            });
                        }
                        
                        let route = {
                            name: 'DiscussionForVendor',
                            params: {
                                code: announcement.ChatRelatedEntityCode,
                                typeCode: "vendor",
                                discussionCode: announcement.RelatedCode
                            }
                        };

                        this.$router.push(route);
                    });                    


                } else {
                    b.children[0].addEventListener('click', (e) => {

                        if(announcement.Code) {
                            this.notifyMarkAsRead({
                                TypeCode: 'Announcement',
                                EntityCode: announcement.Code
                            });
                        }
                        this.attemptNavigate(announcement);
                    });                    
                }

            } else {

                //when a side bar notification is clicked, pop up a modal of the notification
                b.children[0].addEventListener('click', (e) => {
                    if(announcement.Code) {
                        this.notifyMarkAsRead({
                            TypeCode: 'Announcement',
                            EntityCode: announcement.Code
                        });
                    }
                    this.createModalPopup(announcement,options,imageUrl,textIcon);
                });

            }

            this.displayBrowserAnnouncement(announcement);
        },

        async requestPermissionToSendNotifications() {
            if(!('Notification' in window)) {
                return;
            }

            if(Notification.permission === 'denied'
                || Notification.permission === 'granted') {
                return;
            }

            await Notification.requestPermission();
        },

        async displayBrowserAnnouncement(announcement) {
            if(document.visibilityState != 'hidden') {
                return;
            }

            if(!("Notification" in window)) {
                return;
            }

            if(Notification.permission === 'denied') {
                return;
            }

            try {
                if(Notification.permission != 'granted') {
                    return;
                }

                let options = {
                    body: announcement.Body,
                    silent: true,
                };

                if(announcement.Image) {
                    options['icon'] = announcement.Image;
                } else {
                    options['icon'] = '/assets/logo.png';
                }

                let toDisplay = new Notification(announcement.Subject, options);

                toDisplay.onclick = () => {
                    this.attemptNavigate(announcement);
                    try {
                        parent.focus();
                    } catch {
                        try {
                            window.focus();
                        } catch {

                        }
                    }
                };

            } catch(ex) {

            }
        },

        onRootTitleUpdated(newTitle) {
            this.lastKnownTitle = newTitle;
        },

        async onVideoEvent(e) {
            if(!e.src) {
                return;
            }

            window.$signalRConnection.invoke('VideoAnalytics', {
                ShowCode: AppState.attendee.Show.Code,
                AttendeeCode: AppState.attendee.Code,
                VideoUrl: e.src,
                Type: e.eventType,
                Time: parseInt(e.time, 10),
                Raised: e.raised,
                DID: this.getDeviceId(),
            });
        },

        onCloseAllChats() {
            while(this.miniChatCodes.length > 0) {
                this.handleCloseChatRequest(this.miniChatCodes[0]);
            }
        },

        onCloseChat(chatCode) {
            this.handleCloseChatRequest(chatCode);
        },

        onWindowMessage(event) {
            if(event.data.Type && event.data.Type == 'Passport') {
                AppState.attendee = reactive(event.data.Attendee);
                this.rebuildTheme();
            }
        },
        joinChatForceReplace(daCode){
            if(this.miniChatCodes && this.miniChatCodes.indexOf(daCode) != -1) {
                return;
            }
            
            this.miniChatCodes.push(daCode);
        },

        onUpdateAttendeeCountInternal(count) {
            AppState.onlineAttendeeCount = count;
        },

        onWindowResized() {
            let windowWidth = window.innerWidth;

            if(windowWidth <= 630 && this.sidebarMode) {
                this.sidebarMode = null;
            }
        },

        onSignalRDebug(message) {
            this.signalRDebugMessages.unshift(message);
        },

        async onRequestToggleFavorite(modelToPost) {
            let r = await this.tryPost('/api/favorites/my', 
                JSON.stringify(modelToPost), 
                'application/json');
        },

        checkAndEmitAppAlertIconChange() {
            window.$bus.$emit('trigger-app-badge-alert', {
                AnnouncementCount: this.announcements.UnreadAnnouncementCodes.length,
                ChatCount: this.announcements.UnreadChatCodes.length
            });
        },

        playSoundEffect(effect) {
            try {
                this.notificationSounds[effect].play();
            } catch(ex) {
                console.error("Error in playSoundEffect", effect, ex);
            }
        },

        onCloseSidebarRequested(mode) {
            if(!mode) {
                this.onSetSidebarMode(null);
            } else if (this.sidebarMode == mode) {
                this.onSetSidebarMode(null);
            }
        }
    },

    data() {
        return {
            signalRDebugMessages: [],

            showNavigation: false,

            lastKnownTitle: null,

            miniChatCodes: [],

            unreadGlobalChats: [],

            sidebarMode: null,

            iFrameDetails: {
                Title: null,
                Url: null
            },

            loadingErrorMessage: null,

            signalRConnectMode: 'disconnected',
            forceShowTestBadge: false,
            testBadgeClicks: 0,

            sidebarParams: null,
            showDebugger: false,

            notificationSounds: {
                messageTone: null,
            }
        }
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onWindowResized);
        window.onvisibilitychange = null;

        try {
            window.$signalRConnection.onclose(() => {});

            window.$signalRConnection.off('ControlChannelMessage', this.onControlChannelMessage);

            window.$signalRConnection.stop();
        } catch {

        }

        window.$signalRConnection = { notInitialized: true };

        //this.$root.$off('title-change', this.onRootTitleUpdated);
        window.$bus.$off('notifymessageread', this.notifyMarkAsRead);
        window.$bus.$off('selectedchat', this.onChatSelected);
        window.$bus.$off('updatedprofilephoto', this.onUpdatedProfilePhoto);
        window.$bus.$off('showannouncementinternal', this.internalShowAnnouncement);
        window.$bus.$off('Joined-Call', this.onJoinedCall);
        window.$bus.$off('Left-Call', this.onLeftCall);
        window.$bus.$off('videoevent', this.onVideoEvent);
        window.$bus.$off('close-all-chats', this.onCloseAllChats);
        window.$bus.$off('close-chat', this.onCloseChat);
        window.$bus.$off('iframe-open-request',this.onIFrameOpenRequest);
        window.$bus.$off('link-open-request',this.onLinkOpenRequest);
        window.$bus.$off('join-chat-force',this.joinChatForceReplace);
        window.$bus.$off('update-online-attendee-count-internal', this.onUpdateAttendeeCountInternal);
        window.$bus.$off('on-signalr-debug', this.onSignalRDebug);
        window.$bus.$off('request-toggle-favorite', this.onRequestToggleFavorite);
        window.$bus.$off('set-sidebar-mode', this.onSetSidebarMode);
        window.$bus.$off('title-change', this.onRootTitleUpdated);
    },

    created: function() {
        if(this.environmentIsDevTest) {
            this.forceShowTestBadge = true;
        }
        
        window.addEventListener('resize', this.onWindowResized);
        //this.$root.$on('title-change', this.onRootTitleUpdated);

        window.addEventListener('message', this.onWindowMessage, false);

        window.$bus.$on('notifymessageread', this.notifyMarkAsRead);
        window.$bus.$on('selectedchat', this.onChatSelected);
        window.$bus.$on('updatedprofilephoto', this.onUpdatedProfilePhoto);
        window.$bus.$on('showannouncementinternal', this.internalShowAnnouncement);
        window.$bus.$on('Joined-Call', this.onJoinedCall);
        window.$bus.$on('Left-Call', this.onLeftCall);
        window.$bus.$on('videoevent', this.onVideoEvent);
        window.$bus.$on('close-all-chats', this.onCloseAllChats);
        window.$bus.$on('close-chat', this.onCloseChat);
        window.$bus.$on('iframe-open-request',this.onIFrameOpenRequest);
        window.$bus.$on('link-open-request',this.onLinkOpenRequest);
        window.$bus.$on('join-chat-force',this.joinChatForceReplace);
        window.$bus.$on('update-online-attendee-count-internal', this.onUpdateAttendeeCountInternal);
        window.$bus.$on('on-signalr-debug', this.onSignalRDebug);
        window.$bus.$on('request-toggle-favorite', this.onRequestToggleFavorite);
        window.$bus.$on('set-sidebar-mode', this.onSetSidebarMode);
        window.$bus.$on('closeSidebar', this.onCloseSidebarRequested);
        window.$bus.$on('title-change', this.onRootTitleUpdated);
        
        
        try {
            this.notificationSounds.messageTone = new Audio('/incomingmessage.mp3');
        } catch {

        }

        this.loadAttendeePassport();
    },
}
</script>
