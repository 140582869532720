<template>
    <div v-if="hasValidElementToShow"
         class="root-media-container">
        <div class="media-container media-video-container"
             :class="{'media-video-container-full-height': isFullHeightContent}">

            <div v-if="media.MediaType == 'LiveWebSource' && !editMode"
                class="embed-container">

                <iframe :src="iFrameSrc" allow="camera;microphone;fullscreen" allowfullscreen>

                </iframe>
            </div>

            <div v-else-if="media && media.MediaType == 'HlsSource' && !editMode"
                class="hls-container">
                <hls-video-player :src="media.Uri" 
                                  :previewImage="media.ThumbnailUri"
                                  :waitForStream="true"
                                  :showControls="true"
                                  ref="videoplayer"
                                  :inPreviewMode="false">
                </hls-video-player>
            </div>
            <div v-else
                class="cover-container"
                @click="onMediaPhotoClick">
                <img-g v-if="media.ThumbnailUri"
                    :src="media.ThumbnailUri" />
                <img-g v-else :src="fallbackPhoto" />
            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'

export default {
    props: [
        'entityCode',
        'entityTypeCode',
        'media',
        'fallbackPhoto',
        'forceShow',
        'editMode'
    ],

    mixins: [
        Token,
        Common,
        //ElementMixin
    ],   

    computed: {
        isFullHeightContent() {
            return this.iFrameSrc
                && this.iFrameSrc.startsWith('/zoom.html');
        },

        hasValidElementToShow() {
            if(this.forceShow) {
                return true;
            }
            
            if(!this.media && !this.fallbackPhoto) {
                return false;
            }

            if(this.media 
                && !this.media.MediaType 
                && !this.media.ThumbnailUri 
                && !this.fallbackPhoto) {
                return false;
            }

            if(this.media
                && this.media.MediaType
                && !this.media.Uri
                && !this.media.ThumbnailUri
                && !this.fallbackPhoto)
            {
                return false;                
            }

            return true;
        },

        iFrameSrc() {

            if(this.media 
                && this.media.MediaType == 'LiveWebSource') {

                let link = this.media.Uri;

                if(link.indexOf('{{showCode}}') != -1) {
                    link = link.replace('{{showCode}}', AppState.attendee.Show.Code);
                } 

                if(link.indexOf('{{attendeeCode}}') != -1) {
                    link = link.replace('{{attendeeCode}}', AppState.attendee.Code);
                }

                if(link.indexOf('{{entityTypeCode}}') != -1) {
                    link = link.replace('{{entityTypeCode}}', this.entityTypeCode);
                }

                if(link.indexOf('{{entityCode}}') != -1) {
                    link = link.replace('{{entityCode}}', this.entityCode);
                }

                return link;
            }

        },        
    },

    methods: {
        onMediaPhotoClick() {
            this.$emit('cover-click');
        }
    }

}
</script>