<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else-if="showWebinarView"
         class="webinar-page">
        <webinar-view :attendee="attendee" 
                      :roomCode="appointment.MeetingRoomCode" 
                      :show="attendee.Show"
                      
                      :entityTypeCode="'VENDOR'"
                      :entityCode="appointment.EntityCode"
                      :entityName="appointment.DisplayName"
                      @exit-request="onWebinarExitRequested"
                      :webinarStatus="'Public'"
                      ref="webinarView">
        </webinar-view>
    </div>    

    <div class="exhibitor-detail-page detail-page-container" v-else>
        <div v-if="appointment && !submitting"
             class="detail-page">

            <div class="detail-page-summary">
               
                <div class="exhibitor-summary">

                    <div class="exhibitor-logo hide-on-xs">
                        <img-g :src="`${getApiUrl()}/api/asset/${attendee.Show.Code}/vendor/${appointment.EntityCode}/profile`"
                               fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
                    </div>

                    <div class="exhibitor-name-and-menu">
                        <h1 class="exhibitor-name">
                            {{appointment.DisplayTitle}}
                        </h1>
                        <h2 class="exhibitor-headline">
                            Booked with {{appointment.AssignedToName}}
                        </h2>

                        <h3 class="exhibitor-location">
                            <span class="icomoon"></span>{{$filters.moment(appointment.MeetingDateTime, 'LLLL')}} ({{appointment.DurationMinutes}} minutes)
                        </h3>

                        <div class='horizontal-menu'>
                            <button type="button"
                                    @click="switchWebinarModeForce">
                                <span class="icomoon"></span><span>Join Meeting</span>
                            </button>
                            <button type="button"
                                    @click="requestCancelMeeting">
                                <span class="icomoon"></span><span>Cancel Meeting</span>
                            </button>
                            <router-link class="btn"
                                         :to="{name: 'vendorDetails', params: {code: appointment.EntityCode}}">
                                <span class="icomoon"></span><span>View Page</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import webinarView from './webinar.view.vue';

export default {
    props: [
        'attendee', 
        'appointment'
    ],

    mixins: [
        Token,
        Common,
        //ElementMixin
    ],   

    components: {
        webinarView
    },

    watch: {
        attendee() {
            this.build();
        },
    },

    computed: {
        showWebinarView() {
            return this.forceWebinarMode;
        },
    },

    async beforeRouteLeave(to, from, next) {
        let isInWebinar = this.forceWebinarMode;

        let webinarView = this.$refs.webinarView;

        if(webinarView) {

            let isConferenceLive = webinarView.conference;
            let isVideoLive = webinarView.videoUrl && webinarView.videoStartDateTime && !webinarView.isSessionLive;

            isInWebinar = isConferenceLive || isVideoLive;

        }

        if(isInWebinar || isInZoom) {

            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you want to leave this page?",
                    () => {
                        r("Ok");
                    },
                    () => {
                        r("Cancel");
                    },
                    {
                        labels: {
                            confirm: "Confirm Exit",
                            confirmOk: "Yes",
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;
            if(result == 'Cancel') {
                next(false);
                return;
            }

            this.postExitAnalytics();
            next(); 
        } else {
            this.postExitAnalytics();
            next();
        }
    },

    methods: {
        requestCancelMeeting() {
            let result = confirm("Are you sure you want to cancel this meeting? This cannot be undone.");

            if(!result) {
                return;
            }

            this.$emit('cancel-meeting', this.appointment);
        },

        onWebinarExitRequested() {
            this.forceWebinarMode = false;
        },

        postExitAnalytics() {
            // this.postAnalytics(AppState.attendeeCode,'VendorExit',this.code,'Vendor',AppState.showCode);
        },


        async build() {
            this.submitting = false;
        },

        switchWebinarModeForce() {
            this.forceWebinarMode = !this.forceWebinarMode;
        },


    },

    data() {
        return {
            forceWebinarMode: false
        }
    },

    beforeUnmount() {
    },

    created: function() {
        this.build();
    },
}
</script>