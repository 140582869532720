<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else-if="showWebinarView"
         class="webinar-page">
        <vue-title :title="vendor.Name" :brand="true"></vue-title>
        
        <webinar-view :roomCode="vendor.DiscussionCode" 
                      :entityTypeCode="'VENDOR'"
                      :entityCode="vendor.Code"
                      :entityName="vendor.Name"
                      @exit-request="onWebinarExitRequested"
                      :webinarStatus="vendor.DiscussionWebinarStatus"
                      ref="webinarView">

            <template v-slot:holding>
                <div class="webinar-session-holding">
                    <div class="webinar-title">
                        <h1>{{vendor.Name}}</h1>
                    </div>
                    <div class="webinar-holding-website" v-if="vendor.PrimaryMedia.Uri">
                        <iframe :src="vendor.PrimaryMedia.Uri">

                        </iframe>
                    </div>
                    <div class="webinar-holding-website" v-else-if="vendor.PrimaryMedia.ThumbnailUri">
                        <img-g :src="vendor.PrimaryMedia.ThumbnailUri" />
                    </div>
                </div>
            </template>
        </webinar-view>
    </div>    
    <div class="exhibitor-detail-page detail-page-container" v-else>
        <div v-if="vendor && !submitting"
             class="detail-page"
             :class="{'edit-mode': showEditControls}"
             ref="mainScrollContainer">
            <vue-title :title="vendor.Name" :brand="true"></vue-title>

            <media-container v-if="vendor.PrimaryMedia"
                             :media="vendor.PrimaryMedia"
                             :entityTypeCode="'VENDOR'"
                             :entityCode="vendor.Code"
                             :fallbackPhoto="vendor.ContactInfo.PhotoUrl"
                             :forceShow="showEditControls"
                             :editMode="showEditControls && !showVideoEditor"
                             @cover-click="onUploadCoverPhoto"
                             @dragover="onDragOverProfileOrCoverPhoto"
                             @drop="onDropCoverPhoto">

            </media-container>

            <div class="detail-page-summary">
               
                <div class="exhibitor-summary"
                     ref="summaryToolbar">

                    <div class="exhibitor-logo"
                         v-if="vendor.ContactInfo.PhotoUrl || showEditControls"
                         @click="onUploadProfilePhoto">
                        <img-g :src="vendor.ContactInfo.PhotoUrl"
                               @dragover="onDragOverProfileOrCoverPhoto"
                               @click="onUploadProfilePhoto"
                               @drop="onDropProfilePhoto" />
                    </div>

                    <div class="exhibitor-name-and-menu">
                        <h1 class="exhibitor-name">
                            <div class="online-icon online" 
                                 v-if="vendor.DiscussionWebinarStatus == 'Public'"></div>
                            {{vendor.Name}}
                        </h1>
                        <h2 class="exhibitor-headline"
                            v-if="vendor.Headlines">
                            {{vendor.Headlines}}
                        </h2>
                        <!-- <input v-else v-model="vendor.Name"> -->

                        <h3 v-if="vendor.Location"
                            class="exhibitor-location">
                            <span class="icomoon"></span>{{vendor.Location}}
                        </h3>

                        <div class='horizontal-menu'>
                            <button v-if="!editMode && showJoinRoomButton"
                                    type="button"
                                    @click="switchWebinarModeForce">
                                <span class="icomoon"></span><span>Join Room</span>
                            </button>

                            <button type="button"
                                    class="icomoon button-outline"
                                    v-if="!editMode"
                                    :class="[vendorModel.CanEditFavorite ? '' : 'button-noninteractive-fill']"
                                    @click="onToggleFavorite">
                                {{vendorModel.IsFavorite ? '' : ''}}
                            </button>

                            <router-link :to="{name: 'DiscussionForVendor', params: { discussionCode: vendor.DiscussionCode }}"
                                        v-if="!editMode && vendor.ShowPublicChat && this.vendor.DiscussionWebinarStatus != 'Public' && vendor.DiscussionCode"
                                        class="button-outline btn">
                                <span class="icomoon"></span><span>Public Chat</span>
                            </router-link>

                            <button v-if="!editMode && canLaunchDM"
                                    class="button-outline btn"
                                    @click="launchDMAsync">
                                <span class="icomoon"></span><span>Start Chat</span>
                            </button>

                            <router-link v-if="bookable && !editMode"
                                         class="btn button-outline"
                                         :to="{name: 'CreateAppointmentRequestVendor'}">
                                <span class="icomoon"></span><span>Request Info</span>
                            </router-link>
                            <router-link class="btn button-outline"
                                         v-if="canViewBookings && !editMode"
                                         :to="{name: 'ViewAppointmentRequestsVendor'}">
                                <span class="icomoon"></span><span>Inbox</span>
                            </router-link>
                            <router-link class="btn button-outline"
                                         v-if="canViewVisits && !editMode"
                                         :to="{name: 'ProfileViewsVendor'}">
                                <span class="icomoon"></span><span>Visitors</span>
                            </router-link>

                            <button class="button-outline"
                                    v-if="canLaunchScheduler && !editMode"
                                    @click="launchScheduler">
                                <span class="icomoon"></span><span>Schedule</span>
                            </button>

                            <button @click="editMode = true"
                                    v-if="!editMode && canEditPage"
                                    class="button-outline"
                                    type="button">
                                <span class="icomoon"></span><span>Edit</span>
                            </button>

                            <button type="button"
                                    v-if="editMode && canEditPage"
                                    @click="showPageEditorPane">
                                <span class="icomoon">
                                    
                                </span>
                                <span>
                                    Details
                                </span>
                            </button>

                            <button @click="previewChangesMode = !previewChangesMode"
                                    :class="{'button-outline' : !previewChangesMode}"
                                    type="button"
                                    v-if="editMode && canEditPage">
                                <span class="icomoon">
                                    
                                </span>
                                <span v-if="previewChangesMode">
                                    Exit Preview
                                </span>
                                <span v-else>
                                    Preview
                                </span>
                            </button>

                            <button v-if="vendor.DiscussionCode && showEditControls"
                                    :class="{'button-outline' : !showVideoEditor}"
                                    @click="showVideoEditor = !showVideoEditor">
                                <span class="icomoon"></span>
                                <span>
                                    Videos
                                </span>
                            </button>

                            <button v-if="vendor.DiscussionCode && showEditControls"
                                    :class="{'button-outline' : !vendor.ShowPublicChat}"
                                    @click="vendor.ShowPublicChat = !vendor.ShowPublicChat">
                                <span class="icomoon"
                                      v-if="vendor.ShowPublicChat"></span>
                                <span class="icomoon"
                                      v-else></span>
                                <span>
                                    {{vendor.ShowPublicChat ? "Show Chat" : "Hide Chat" }}
                                </span>
                            </button>

                            <button @click="savePageChanges"
                                    type="button"
                                    v-if="showEditControls && canEditPage">
                                <span class="icomoon">
                                    
                                </span>
                                <span>
                                    Save
                                </span>
                            </button>

                            <button @click="abortPageChanges"
                                    type="button"
                                    v-if="showEditControls && canEditPage">
                                <span class="icomoon">
                                    
                                </span>
                                <span>
                                    Cancel
                                </span>
                            </button>
                            <template v-if="showEditControls && canEditPage">
                                <button v-for="item in newElementTypes" 
                                        :key="item.Code"
                                        draggable="true" 
                                        @dragstart="onDragStartNewElement(item.Code)"
                                        @dragend="onDropCleanup"
                                        @drag="onDragNewElement"
                                        @click="addItem(item.Code)">Add {{item.Name}}
                                </button>
                            </template>
                        </div>
                    </div>


                </div>

                <p v-if="vendor.ContactInfo.Bio && !showEditControls"
                   class="detail-page-bio-text"
                   v-html="biography">
                </p>

                <contact-info-list :contactInfo="vendor.ContactInfo.ContactInfo"
                                   :entityType="'Vendor'"
                                   :entityCode="code"
                                   :displayOnlineStatus="true"
                                   :editMode="editMode && !previewChangesMode"
                                   ref="contactInfoEditor"
                                   v-if="!showVideoEditor"
                                   @upload-event="onContactInfoUploadEvent"
                                   @drag-scroll-requested="onAutoScrollTimer"
                                   @drag-update="onDragUpdate">
                </contact-info-list> 

                <template v-if="showEditControls">

                    <file-upload ref="uploaderCover"
                        style="display: none;"
                        :assetType="`vendor_${this.code}`"
                        @uploadSuccess="onFileUploadedCover"
                        @uploadFailed="onUploadFailed"
                        @uploadStarted="onUploadStarted" />


                    <file-upload ref="uploaderProfilePhoto"
                        style="display: none;"
                        :assetType="`vendorprofile_${this.code}`"
                        :acceptFileType='"image/png, image/gif, image/jpeg"'
                        @uploadSuccess="onProfilePhotoUploaded"
                        @uploadFailed="onUploadFailed"
                        @uploadStarted="onUploadStarted" />
                </template>

                <div v-if="showEditControls && showVideoEditor">
                    <h1>Page Videos</h1>

                    <div v-if="loadingVideos">
                        <loading-container></loading-container>
                    </div>

                    <div v-if="videoPermissions.CanUpload && !loadingVideos"
                         class="button-group">

                        <button type="button"
                                @click="requestVideoUpload()">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Upload Video...
                            </span>
                        </button>
                        <button type="button"
                                @click="setVendorMedia()">
                            <span>
                                Unset Video
                            </span>
                        </button>

                    </div>

                    <div class="video-list"
                         v-if="!loadingVideos">
                        <div class="video-item"
                             v-for="video in videos"
                             :key="video.Code">
                            
                            <div>
                                <label>Video Name</label>
                                <input v-model="video.Name"
                                       readonly
                                    placeholder="Video Name">
                            </div>


                            <button @click="setVendorMedia(video)"
                                    v-if="video.TractusM3U8Url && vendor.PrimaryMedia.Uri != video.TractusM3U8Url"
                                    type="button">
                                Set as Page Video
                            </button>
                            <div v-else-if="vendor.PrimaryMedia.Uri && video.TractusM3U8Url && video.ConversionStatusCode == 'Success' && vendor.PrimaryMedia.Uri == video.TractusM3U8Url"
                                 class="page-video-indicator icomoon">
                                
                            </div>

                            <div v-else
                                 class="video-status">
                                <span v-if="video.ConversionStatusCode == 'NotDownloaded'">
                                    Waiting for Review
                                </span>
                                <span v-else-if="video.ConversionStatusCode != 'Success'">
                                    Converting
                                </span>
                            </div>
                            <button @click="editVideoDetails(video)"
                                    class="icomoon"
                                    type="button"
                                    v-if="video.ConversionStatusCode == 'Success'">
                                
                            </button>
                        </div>
                    </div>

                    
                </div>

                <div class="upload-page-modal-blocker"
                     v-if="uploadInProgress">
                    <h1>
                        Uploading file, please wait...
                    </h1>
                    <loading-container />
                </div>

                <div class="upload-video-modal-blocker"
                     v-if="videoUploadRequest && !uploadInProgress">

                    <div class="video-upload-form">

                        <h1>Add Video</h1>

                        <input type="file"
                                accept=".mp4,.mov"
                                style="display: none"
                                ref="videopicker">

                        <label>Video Name</label>
                        <input v-model="videoUploadRequest.Name"
                               placeholder="Video Name">

                        <label>Video Description</label>
                        <textarea v-model="videoUploadRequest.Description"
                               placeholder="Description">

                        </textarea>

                        <button @click="selectVideo"
                                type="button">
                            {{this.videoUploadRequest.File ? 'Change Video' : 'Select Video'}}
                        </button>

                        <div class="upload-form-actions">
                            <button @click="cancelVideoUpload"
                                    type="button">
                                Cancel
                            </button>
                            <button @click="performVideoUpload"
                                    :disabled="!this.videoUploadRequest.File"
                                    type="button">
                                Upload Video
                            </button>
                        </div>                        
                    </div>
                </div>

                <div class="upload-video-modal-blocker"
                     v-if="videoEditRequest && !uploadInProgress">

                    <div class="video-upload-form">

                        <h1>Edit Video</h1>

                        <hls-video-player :src="videoEditRequest.TractusM3U8Url" 
                                          :waitForStream="true"
                                          :showControls="true"
                                          :inPreviewMode="false">
                        </hls-video-player>

                        <label>Video Name</label>
                        <input v-model="videoEditRequest.Name"
                               placeholder="Video Name">

                        <label>Video Description</label>
                        <textarea v-model="videoEditRequest.Description"
                               placeholder="Description">

                        </textarea>

                        <div class="upload-form-actions">
                            <button @click="videoEditRequest = null"
                                    type="button">
                                Cancel
                            </button>
                            <button @click="saveChangesToVideo(videoEditRequest)"
                                    type="button">
                                Save
                            </button>
                        </div>                        
                    </div>
                </div>



            </div>
        </div>
        <router-view :typeCode="'Vendor'" 
                     :code="code"
                     class="right-pane">
        </router-view>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import webinarView from './webinar.view.vue';
import { AppState } from './appstate';

import { BlobServiceClient, ContainerClient  } from '@azure/storage-blob'

export default {
    props: ['code'],
    mixins: [
        Token,
        Common
    ],   

    components: {
        webinarView
    },

    watch: {
        code(to, from) {
            this.build();
        },
        editMode(to, from) {
            if(to) {
                this.previewChangesMode = false;
                this.showVideoEditor = false;
                this.loadEditPageVideosAsync();
                this.showPageEditorPane();
            } else {
                window.$bus.$emit('closeSidebar', 'contact-info');
                window.$bus.$emit('closeSidebar', 'page-editor');
            }
        }
    },

    computed: {
        bookable() {
            return this.vendorModel?.IsBookable;
        },

        canLaunchDM() {
            return this.vendorModel?.CanHavePageMessages;
        },

        canLaunchScheduler() {
            if(!this.canEditPage) {
                return false;
            }

            let vendorCategoryCode = this.vendor.Category.Code;
            
            let vendorCategory = AppState.show.VendorCategories.find(x => x.Code == vendorCategoryCode);

            if(!vendorCategory) {
                return false;
            }

            if(!vendorCategory.AddSessionsToCategoryCodes
                || vendorCategory.AddSessionsToCategoryCodes.length == 0) {
                return false;
            }

            return true;
        },

        biography() {
            return this.markdown(this.vendor.ContactInfo.Bio);
        },

        showJoinRoomButton() {
            return this.vendor
                && this.vendor.DiscussionCode
                && (this.vendor.DiscussionWebinarStatus == 'Public'
                || (this.vendor.DiscussionWebinarStatus == 'Private' && this.allowRehearsal))
        },

        showWebinarView() {
            return this.forceWebinarMode;
        },

        editableElements: {
            get() {
                return this.selectedSection.Elements.filter(x => {
                    let isSkippable = x.SkipInEditMode;
                    let containsInternal = x.Type == 'Website' && x.Value && x.Value.indexOf('INTERNAL:::') != -1;
                    let isImage = x.Type == 'Image'
                    return !isSkippable && !containsInternal && !isImage;
                });
            }, 
            set(value) {
                if(this.selectedSection == this.sections[0]) {
                    let fixedItem = this.selectedSection.Elements.filter(x => x.SkipInEditMode);
                    
                    this.selectedSection.Elements = [...fixedItem, ...value];
                } else {
                    this.selectedSection.Elements = value;
                }
            }
        },

        canEditPage() {
            let isAdmin = AppState.attendee.RoleCode && AppState.attendee.RoleCode == 'Administrator';

            return isAdmin || this.canViewVisits;
        },

        showEditControls() {
            return this.editMode && !this.previewChangesMode;
        }
    },

    async beforeRouteLeave(to, from, next) {
        let isInWebinar = this.vendor
            && this.vendor.DiscussionWebinarStatus == "Public"
            && this.forceWebinarMode;

        let isInZoom = this.vendor
            && this.vendor.PrimaryMedia
            && this.vendor.PrimaryMedia.MediaType == 'LiveWebSource'
            && this.vendor.PrimaryMedia.Uri.indexOf('zoom.html') != -1;

        let webinarView = this.$refs.webinarView;

        if(webinarView) {

            let isConferenceLive = webinarView.conference;
            let isVideoLive = webinarView.videoUrl && webinarView.videoStartDateTime && !webinarView.isSessionLive;

            isInWebinar = isConferenceLive || isVideoLive;
        }

        if(isInWebinar || isInZoom) {

            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you want to leave this page?",
                    () => {
                        r("Ok");
                    },
                    () => {
                        r("Cancel");
                    },
                    {
                        labels: {
                            confirm: "Confirm Exit",
                            confirmOk: "Yes",
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;
            if(result == 'Cancel') {
                next(false);
                return;
            }

            this.postExitAnalytics();
            next(); 
        } else {
            this.postExitAnalytics();
            next();
        }
    },

    methods: {
        async launchDMAsync() {
            let routeToFetch = `/api/chats/${AppState.show.Code}/VENDOR/${this.code}/${AppState.attendee.Code}`;

            try {
                let result = await this.tryGet(routeToFetch);
                console.log(result);

                if(!result?.Result?.Discussion) {
                    throw "Not found";
                }

                console.log(result);

                let route = {
                    name: 'DiscussionForVendor', 
                    params: { discussionCode: result.Result.Discussion.Code }};
                
                this.$router.push(route);

            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not start the chat. Please try again later.");
            }

        },

        showPageEditorPane() {
            window.$bus.$emit('set-sidebar-mode', 'page-editor', {
                EntityTypeCode: "VENDOR",
                EntityCode: this.code,
                Page: this.vendor
            });
        },

        addItem(code) {
            this.$refs.contactInfoEditor.addItem(code, null);

            this.$nextTick(() => {
                this.$refs.mainScrollContainer.scrollTo(0, this.$refs.mainScrollContainer.scrollHeight);
            });
        },

        launchScheduler() {
            let vendorCategoryCode = this.vendor.Category.Code;
            
            let vendorCategory = AppState.show.VendorCategories.find(x => x.Code == vendorCategoryCode);

            window.$bus.$emit('set-sidebar-mode', 'schedule', {
                EntityTypeCode: "VENDOR",
                EntityCode: this.code,
                CategoryCodes: vendorCategory.AddSessionsToCategoryCodes,
                PrimaryCategoryCode: this.code,
                Mode: 'ReadWrite',
                Name: this.vendor.Name
            });
        },

        onToggleFavorite() {
            let pageModel = this.vendorModel;

            if(!pageModel.CanEditFavorite) {
                return;
            }

            window.$bus.$emit('request-toggle-favorite', {
                Type: "VENDOR",
                Code: pageModel.Vendor.Code,
                ReadOnly: false,
                AllowEditEntity: pageModel.IsPageAdmin,
                Active: !pageModel.IsFavorite
            });

            return false;
        },

        onWebinarExitRequested() {
            this.forceWebinarMode = false;
        },

        postExitAnalytics() {
            this.postAnalytics(AppState.attendee.Code,'VendorExit',this.code,'Vendor',AppState.show.Code);
        },

        onUploadProfilePhoto() {
            if(!this.editMode) {
                return;
            }
            console.log('Test')
            this.$refs.uploaderProfilePhoto.requestChooseFile();
        },

        onUploadCoverPhoto() {
            if(!this.editMode) {
                return;
            }
            
            this.$refs.uploaderCover.requestChooseFile();
        },

        onUploadFile(selectedUploadCandidate) {
            if(selectedUploadCandidate.IsLocked && AppState.attendee.RoleCode != 'Administrator'){
                return;
            }
            this.selectedUploadCandidate = selectedUploadCandidate;
            this.$refs.uploader.requestChooseFile();
        },

        onContactInfoUploadEvent(eventName) {
            if(eventName == 'success') {
                this.uploadInProgress = false;
            } else if (eventName == 'failed') {
                this.uploadInProgress = false;
                this.$awn.alert('Could not upload your file. Try again later.');
            } else {
                this.uploadInProgress = true;
            }
        },

        onUploadStarted() {
            this.uploadInProgress = true;
        },

        onUploadFailed() {
            this.uploadInProgress = false;
            this.$awn.alert('Could not upload your file. Try again later.');
        },

        onProfilePhotoUploaded(file) {
            this.uploadInProgress = false;
            this.vendor.ContactInfo.PhotoUrl = file;
        },        

        onFileUploadedCover(file) {
            this.uploadInProgress = false;
            this.vendor.PrimaryMedia.ThumbnailUri = file;
        },        


        abortPageChanges() {
            this.editMode = false;
            this.build();
        },

        async savePageChanges() {
            this.submitting = true;

            window.$bus.$emit('closeSidebar', 'contact-info');
            window.$bus.$emit('closeSidebar', 'page-editor');

            this.vendor.ContactInfo.ContactInfo = [...this.$refs.contactInfoEditor.contactInfoList];

            try {


                let result = await this.tryPost(
                    '/api/vendor', 
                    JSON.stringify(this.vendor),
                    'application/json');

            } catch(ex) {
                console.error(ex);
            }

            this.editMode = false;
            this.build();
        },

        async build() {
            this.submitting = true && !this.vendor;

            let result = await this.tryGet(`/api/vendor/${AppState.show.Code}/${this.code}`);

            if(!result.Result || !result.Result.Vendor) {
                this.$awn.alert("We're sorry. This page does not exist.");
                this.$router.go(-1);
                return;
            }

            this.vendorModel = result.Result;
            this.vendor = result.Result.Vendor;

            this.postAnalytics(AppState.attendee.Code,'VendorDetails',this.code,'Vendor',AppState.show.Code);

            this.allowRehearsal = false;


            let isAdmin = AppState.attendee.RoleCode && AppState.attendee.RoleCode == 'Administrator';

            try {
                this.canViewBookings = 
                    (this.vendorModel.IsPageAdmin
                    || isAdmin);
            } catch {

            }

            try {

                this.canViewVisits = (isAdmin 
                    || this.vendorModel.IsPageAdmin);
            } catch {

            }

            await this.refreshOnlineAttendees();

            if (this.vendor.DiscussionWebinarStatus
                && this.vendor.DiscussionWebinarStatus == 'Private') {
                
                await this.checkCanRehearse();
            }

            if(this.forceWebinarMode 
                && this.vendor.DiscussionWebinarStatus != 'Public'
                && !this.allowRehearsal) {
                
                this.forceWebinarMode = false;
            }

            this.submitting = false;
        },

        switchWebinarModeForce() {
            this.forceWebinarMode = !this.forceWebinarMode;
        },

        async loadEditPageVideosAsync() {
            if(!this.vendor.DiscussionCode) {
                return;
            }

            this.loadingVideos = true;

            try {
                let result = await this.tryGet(`/api/recording/permissions/${AppState.show.Code}/${this.vendor.DiscussionCode}`);

                this.videoPermissions = result.Result;
            } catch {
                this.videoPermissions = {
                    CanRecord: false,
                    CanUpload: false,
                }
            }

            try {
                let result = await this.tryGet(`/api/videos/${AppState.show.Code}/${this.vendor.DiscussionCode}`);

                this.videos = result.Result;

            } catch {
                this.videoPermissions = {
                    CanRecord: false,
                    CanUpload: false,
                };
            }

            this.loadingVideos = false;
        },

        async onVideoConvertUpdate() {
            if(!this.editMode) {
                return;
            }

            await this.loadEditPageVideosAsync();
        },

        async checkCanRehearse() {
            this.allowRehearsal = false;
        
            if(!this.vendor.DiscussionCode) {
                return;
            }

            try {
                let result = await this.tryGet(`/api/webinar/checkcanrehearse/${AppState.show.Code}/${this.vendor.DiscussionCode}`);

                if(result.Result == 'canrehearse') {
                    this.allowRehearsal = true;
                }  

            } catch {
                this.allowRehearsal = false;
            }
        },
        onEntityUpdated(details) {
            if(details.EntityTypeCode != "VENDOR" || details.EntityCode != this.code) {
                return;
            }

            if(this.editMode) {
                return;
            }

            this.build();
        },

        onFavoritesUpdated(favorites) {
            if(!favorites) {
                this.build();
            } else {

                let favorite = favorites.find(x => x.EntityTypeCode == 'VENDOR' && x.EntityCode == this.vendor.Code);

                if(!favorite) {
                    return;
                }

                this.vendorModel.IsFavorite = favorite.IsActive;
            }

        },

        setVendorMedia(video) {
            if(!video) {
                this.vendor.PrimaryMedia.MediaType = null;
                this.vendor.PrimaryMedia.Uri = null;
            } else {
                this.vendor.PrimaryMedia.MediaType = 'HlsSource';
                this.vendor.PrimaryMedia.Uri = video.TractusM3U8Url;
            }
        },

        requestVideoUpload() {
            this.videoUploadRequest = {
                Name: 'New Video',
                Description: '',
                File: null,
            };

            this.$nextTick(() => {

                let uploader = this.$refs.videopicker;

                uploader.onchange = (e) => {
                    this.videoUploadRequest.File = uploader.files[0];
                }
            });
        },

        cancelVideoUpload() {
            this.videoUploadRequest = null;
        },

        selectVideo() {
            let uploader = this.$refs.videopicker;
            uploader.click();
        },

        async performVideoUpload() {
            let uploader = this.$refs.videopicker;

            this.uploadInProgress = true;
            let url = '/api/assets/video/sas';
            let result = await this.tryGet(url);

            let sasDetails = result.Result;

            const containerClient = new ContainerClient(sasDetails.SASUri);

            const promises = [];

            const file = uploader.files[0];
            const videoCode = this.getAzureCompatibleTimeStamp();

            const blobClient = containerClient.getBlockBlobClient(`${AppState.show.Code}/${this.vendor.DiscussionCode}_${videoCode}/${file.name}`)
            promises.push(blobClient.uploadBrowserData(file));

            let finalPath = `${sasDetails.ContainerUri}/${blobClient.name}`;

            await Promise.all(promises);

            


            let video = {
                ShowCode: AppState.show.Code,
                RoomCode: this.vendor.DiscussionCode,
                Code: videoCode,
                ExternalVideoUrl: finalPath,
                ConversionStatusCode: 'NotDownloaded',
                Name: this.videoUploadRequest.Name,
                Description: this.videoUploadRequest.Description
            };

            url = `/api/videos/${AppState.show.Code}`;
            result = await this.tryPost(
                    url,
                    JSON.stringify([ video ]), 
                    'application/json');

            this.videoUploadRequest = null;

            this.uploadInProgress = false;
            await this.loadEditPageVideosAsync();
        },    

        editVideoDetails(video) {
            this.videoEditRequest = JSON.parse(JSON.stringify(video));
        },

        async saveChangesToVideo(video) {
            this.uploadInProgress = true;
            let url = `/api/videos/${AppState.show.Code}`;
            let result = await this.tryPost(
                    url,
                    JSON.stringify([ video ]), 
                    'application/json');

            this.$awn.info("Video saved successfully.");

            this.videoEditRequest = null;

            this.uploadInProgress = false;
            this.loadEditPageVideosAsync();
        },

        onWebinarLiveChanged(args) {
            if(args.EntityTypeCode != "VENDOR") {
                return;
            }

            if(args.EntityCode != this.code) {
                return;
            }

            this.vendor.DiscussionWebinarStatus = args.WebinarStatus;

            if(this.vendor.DiscussionWebinarStatus == 'Private') {
                this.forceWebinarMode = false;
            }
        },

        async refreshOnlineAttendees() {

            let boothAttendees = [];

            for(var i in this.vendor.ContactInfo.ContactInfo) {
                let info = this.vendor.ContactInfo.ContactInfo[i];

                if(info.Type == "TractusLink" && info.Value && info.Value.indexOf("Attendee") != -1) {
                    try {
                        let attendeeCode = info.Value.split('c=')[1].substr(0, 6);
                        boothAttendees.push(attendeeCode);
                    } catch(ex) {

                    }
                }
            }

            if(boothAttendees.length > 0) {
                try {
                    this.tryGet(`/api/interactions/online?c=${boothAttendees.join(';')}`).then(result => {
                        this.onlineCodes = result.Result || [];
                    });

                } catch(ex) {

                }
                
            }

        },

        async onOnlineUsersCountUpdated() {
            await this.refreshOnlineAttendees();
        },

        onDragStartNewElement(newElementType) {
            this.$refs.contactInfoEditor.onDragStartNewElement(newElementType);
        },

        onDragNewElement(e) {
            this.$refs.contactInfoEditor.onDrag(null, e);
        },

        onDropCleanup() {
            this.$refs.contactInfoEditor.onDropCleanup();
        },

        onDragUpdate(e) {
            let boundingRect = this.$refs.summaryToolbar.getBoundingClientRect();
            let yBoundaryToolbar = boundingRect.y + boundingRect.height;
            let browserHeight = window.innerHeight;
            let mouseY = e.mouseY;

            let direction = 0; 

            if(mouseY <= yBoundaryToolbar - 24) {
                direction = -16;
            } else if (mouseY > browserHeight - 96) {
                direction = 16;
            } else {
                direction = 0;
            }

            // console.log("Scroll Details (mouseY, yBoundary, browserHeight, direction)",
            //     mouseY,
            //     yBoundaryToolbar,
            //     browserHeight,
            //     direction);

            this.$refs.contactInfoEditor.updateScrollAmount(direction);
        },

        onAutoScrollTimer(direction) {
            if(!direction || direction == 0) {
                return;
            }

            this.$refs.mainScrollContainer.scrollTo(0, this.$refs.mainScrollContainer.scrollTop + direction);
        },


        onDragOverProfileOrCoverPhoto(e) {
            if(!this.editMode) {
                return;
            }
            e.preventDefault();
        },

        async onDropProfilePhoto(e) {
            if(!this.editMode) {
                return;
            }

            e.preventDefault();

            let file = e.dataTransfer.files[0];
            if(!file.type.startsWith('image/')) {
                this.$awn.alert("This file is not an image. Please upload an image (e.g. JPG, PNG).");
                return;
            }

            await this.$refs.uploaderProfilePhoto.uploadFile(e.dataTransfer.files[0]);
        },

        async onDropCoverPhoto(e) {
            if(!this.editMode) {
                return;
            }

            e.preventDefault();

            let file = e.dataTransfer.files[0];
            if(!file.type.startsWith('image/')) {
                this.$awn.alert("This file is not an image. Please upload an image (e.g. JPG, PNG).");
                return;
            }

            await this.$refs.uploaderCover.uploadFile(e.dataTransfer.files[0]);
        },

        onPageDetailsEdited(entityTypeCode, entityCode, page) {
            if(entityTypeCode != "VENDOR" || entityCode != this.vendor.Code) {
                return;
            }

            // TODO: See if we can use a ref() wrapped model for the vendor so we can pass around
            // a single source of truth.
            this.vendor.Name = page.Name;
            this.vendor.Headlines = page.Headlines;
            this.vendor.ContactInfo.Bio = page.ContactInfo.Bio;
            this.vendor.PrimaryMedia.Uri = page.PrimaryMedia.Uri;
            this.vendor.PrimaryMedia.MediaType = page.PrimaryMedia.MediaType;
            
        },

        async onPaste(e) {
            if(!this.editMode) {
                return;
            }

            if(e.target?.tagName != "BODY") {
                return;
            }

            try {
                let clipboardContents = await navigator.clipboard.read();

                console.log(clipboardContents);

                if(clipboardContents.length == 0) {
                    return;
                }

                const imageRaw = clipboardContents[0];
                const type = imageRaw.types[0];

                if(!type.startsWith('image/')) {
                    console.warn("Not an image.");
                    return;
                }

                const blob = await imageRaw.getType(type);


                console.log(blob);
                this.$refs.contactInfoEditor.onPaste(blob);
            } catch(ex) {
                console.error("Error on read clipboard.", ex);
            }

        }
    },

    data() {
        return {
            vendorModel: null,
            vendor: null,
            canViewBookings: false,
            canViewVisits: false,
            sections: [],
            selectedSection: null,
            streamDetails: null,
            onlineCodes: [],
            submitting: false,
            editMode: false,
            previewChangesMode: false,
            uploadInProgress: false,
            selectedUploadCandidate: null,
            allowRehearsal: false,
            forceWebinarMode: false,
            videoPermissions: {
                CanRecord: false,
                CanUpload: false,
            },

            loadingVideos: false,
            videos: [],
            showVideoEditor: false,
            videoUploadRequest: null,
            videoEditRequest: null,

            newElementTypes: [
                {
                    Code: 'Email',
                    Name: 'E-Mail'
                },
                {
                    Code: 'Website',
                    Name: 'Link'
                },
                {
                    Code: 'Downloadable',
                    Name: 'Download'
                },
                {
                    Code: 'Image',
                    Name: 'Image'
                },
                {
                    Code: 'Section',
                    Name: 'Text'
                }
            ]
        }
    },

    beforeUnmount() {
        window.$bus.$off('entity-updated', this.onEntityUpdated);
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('WebinarLiveChange', this.onWebinarLiveChanged);
        window.$bus.$off('refresh-online-users', this.onOnlineUsersCountUpdated);
        window.$bus.$off('VideoConvertUpdate', this.onVideoConvertUpdate);
        window.$bus.$off('PageDetailsEdited', this.onPageDetailsEdited);
        window.$bus.$emit('closeSidebar', 'contact-info');
        window.$bus.$emit('closeSidebar', 'schedule');
        window.$bus.$emit('closeSidebar', 'page-editor');
        window.removeEventListener('paste', this.onPaste);
    },

    created: function() {
        this.build();
        window.$bus.$on('entity-updated', this.onEntityUpdated);
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('WebinarLiveChange', this.onWebinarLiveChanged);
        window.$bus.$on('VideoConvertUpdate', this.onVideoConvertUpdate);
        window.$bus.$on('refresh-online-users', this.onOnlineUsersCountUpdated);
        window.$bus.$on('PageDetailsEdited', this.onPageDetailsEdited);
        window.addEventListener('paste', this.onPaste);
    },
}
</script>