<template>
    <div v-if="!network || submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div class="page-container network-list" v-else>
        <div class="attendee-list list-page-full">
            <vue-title :title="'My Network'" :brand="true"></vue-title>
            <div class="list-page-header">
                <div class="search-container">

                    <div class="search-box">
                        <span class="icomoon">
                            
                        </span>
                        <input placeholder="Search" 
                            v-debounce:150="updateSearchTerm"   
                            type="text"/>
                    </div>

                    <div class="text-holder">
                        <span class="category-title"><div class="online-icon"></div>{{onlineCodes}} online</span>
                    </div>

                    <div class="button-group filter">
                        <button @click="favoritesOnly = !favoritesOnly">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                My Favorites
                            </span>
                        </button>
                        <router-link :to="{name: 'ProfileViewsAttendeeStandalone'}"
                                     class="btn">
                            My Visitors
                        </router-link>
                    </div>

                </div>
            </div>
            <div class='list-page-contents'>
                <div class='category-item'>
                    <div class="category-items attendee-list-items">
                        
                        <attendee-list-item v-for="item in filteredResults"
                                    :key="item.Code"
                                    :attendee="item.Attendee"
                                    :isFavorite="item.IsFavorite"
                                    :class="[$route.params && ($route.params.discussionCode && $route.params.discussionCode == item.Code) || ($route.params.code == item.Code) ? 'list-item-selected' : '']">
                        </attendee-list-item>
                    </div>
                </div>
            </div>
        </div>
        <router-view class="right-pane" 
                     v-if="!onlineOnly"></router-view>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import { AppState } from './appstate'

export default {
    props: ['code', 'onlineOnly'],
    mixins: [
        Token,
        Common
    ],   


    computed: {
        onlineCodes() {
            return AppState.onlineAttendeeCount;    
        },

        attendeeSelected() {
            return this.$route.name == 'attendeeDetailsNetwork';
        },

        chatSelected() {
            return this.$route.name == 'DiscussionForAttendeeNetworkList';
        },

        filteredResults() {
            if(!this.network) {
                return [];
            }            

            let targetArray = this.network.FullNetwork;

            if(this.searchTerm
                && this.searchTerm.length > 0) {

                let searchTest = new RegExp(this.searchTerm, 'i');
                targetArray = targetArray.filter(s => searchTest.test(s.Attendee.DisplayName) 
                    || searchTest.test(s.Attendee.ContactInfo.Title)
                    || searchTest.test(s.Attendee.ContactInfo.SubTitle));

            }

            targetArray = targetArray.sort((a, b) => {
                let isAOnline = AppState.onlineAttendeeCodes.indexOf(a.Attendee.Code) != -1;
                let isBOnline = AppState.onlineAttendeeCodes.indexOf(b.Attendee.Code) != -1;

                if(!isAOnline && isBOnline) {
                    return 1;
                }

                if(isAOnline && !isBOnline) {
                    return -1;
                }

                return 0;
            });

            if(this.favoritesOnly) {
                targetArray = targetArray.filter(x => x.IsFavorite);                
            }

            return targetArray;            
        }
    },
    methods: {

        onFavoritesUpdated(favorites){
            if(!favorites || !favorites.forEach) {
                this.reloadNetwork();
            } else {
                favorites.forEach(f => {
                    if(f.EntityTypeCode != 'ATTENDEE') {
                        return;
                    }

                    let result = this.network.FullNetwork.find(x => x.Code == f.EntityCode);

                    if(!result) {
                        return;
                    }

                    result.IsFavorite = f.IsActive;
                });
            }
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        async reloadNetwork() {
            this.submitting = !this.network;

            let r = await this.tryGet(`/api/interactions/mynetwork?onlineOnly=${this.onlineOnly ? 'true' : 'false'}`);

            if(r.Errors.length > 0) {
                this.errorMessage = r.Errors[0];
            } else {
                this.network = r.Result;
                AppState.onlineAttendeeCodes = r.Result.OnlineCodes;
            }

            window.$bus.$emit(
                'update-online-attendee-count-internal', 
                AppState.onlineAttendeeCodes.length);

            this.submitting = false;
        },

        async reloadOnlineAttendees() {
            try {
                if(this.onlineOnly) {
                    await this.reloadNetwork();
                } else {
                    let r = await this.tryGet(`/api/interactions/allonline`);

                    if(r.Errors.length == 0) {
                        AppState.onlineAttendeeCodes = r.Result;

                        window.$bus.$emit(
                            'update-online-attendee-count-internal', 
                            AppState.onlineAttendeeCodes.length);
                    }
                } 
            } catch(ex) {

            }
        },
    },

    data() {
        return {
            network: null,
            displayMode: 'Everyone',
            searchTerm: '',
            favoritesOnly: false,
        }
    },

    created: function() {
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('entity-updated',this.reloadNetwork);
        window.$bus.$on('refresh-online-users', this.reloadOnlineAttendees);
        this.reloadNetwork();

        if(!this.onlineOnly) {
            this.postAnalytics(AppState.attendeeCode,'MyNetwork',null,null,AppState.showCode);
        }
    },

    beforeUnmount() {
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('entity-updated',this.reloadNetwork);
        window.$bus.$off('refresh-online-users', this.reloadOnlineAttendees);
    }
}
</script>