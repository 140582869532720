import { getApiUrl } from "./environment.hci";
import { get, set, clear } from "idb-keyval";
import token from "./authentication/token";
import { BigNumber } from "bignumber.js";
import marked from 'marked'

export const Lookups = {
    Facebook: "\uea91",
    LinkedIn: "\ueac9",
    Soundcloud: "\ueac3",
    Instagram: "\uea92",
    Amazon: "\uea87",
    GooglePlus: "\uea8b",
    Whatsapp: "\uea93",
    Spotify: "\uea94",
    YouTube: "\uea9d",
    Telegram: "\uea95",
    Vimeo: "\ueaa0",
    Github: "\ueab0",
    Steam: "\ueaad",
    Wordpress: "\ueab4",
    Reddit: "\ueac6",
    StackOverflow: "\uead0",
    Skype: "\ueac5",
    Twitter: "\uea96",
    Twitch: "\uea9f",
    Discord: "\ue90a",
    Rss: "\uea9b",

    Phone: "\ue942",
    Email: "\uea83",
    Downloadable: "\ueadf",
    Website: "\ue9c9",
    PDF: "\ueadf",
    Play: "\ue912",
    Attachment: "\ue9cd",
    BarGraph: "\ue99c",

    Agenda: "\ue953",
    Back: "\ue902",
    Badge: "\ue938",
    Chat: "\ue96c",
    Check: "\uea10",
    CheckInCheckOut: "\uea10",
    CheckInOnly: "\uea11",
    CircleAdd: "\uea0a",
    CircleClose: "\uea0f",
    Clear: "\ue965",
    Connect: "\ue9b6",
    Dashboard: "\ue956",
    Decline: "\uea0f",
    Disconnect: "\ue9b6",
    Education: "\ue99e",
    HappeningNow: "\ue94e",
    Home: "\ue900",
    InviteCode: "\ue937",
    Left: "\ue902",
    Logout: "\uea14",
    MailAdmin: "\ue994",
    Map: "\ue94c",
    Marker: "\ue948",
    Message: "\ue96b",
    Moderate: "\ue96f",
    MyEvents: "\ue953",
    NFC: "\ue91d",
    Note: "\ue907",
    Notification: "\ue91a",
    People: "\ue972",
    PersonSetting: "\ue994",
    PhoneNfc: "\ue91d",
    Poll: "\ue99b",
    QR: "\ue938",
    Right: "\ue901",
    Send: "\ue906",
    Settings: "\ue994",
    Share: "\uea82",
    Sponsors: "\ue903",
    Statistics: "\ue99b",
    Tag: "\ue936",
    Undo: "\ue965",
    Visit: "\ue972",
};

export const IconLookups = {
    "facebook.com": Lookups.Facebook,
    "linkedin.com": Lookups.LinkedIn,
    "soundcloud.com": Lookups.Soundcloud,
    "instagram.com": Lookups.Instagram,
    "://amazon.": Lookups.Amazon,
    "https://wa.me": Lookups.Whatsapp,
    "spotify.com": Lookups.Spotify,
    "youtube.com": Lookups.YouTube,
    "youtu.be": Lookups.YouTube,
    "t.me": Lookups.Telegram,
    "vimeo.com": Lookups.Vimeo,
    "github.com": Lookups.Github,
    "steamcommunity.com": Lookups.Steam,
    "wordpress.com": Lookups.Wordpress,
    "reddit.com": Lookups.Reddit,
    "stackoverflow.com": Lookups.StackOverflow,
    "twitter.com": Lookups.Twitter,
    "twitch.tv": Lookups.Twitch,
};

export const AppLinkRouteScheme = {
    AppLink: {
        HasMultipleRoutes: true,
        Announcements: { path: "/myactivity" },
        DiscussionArea: { path: "/discussionList" },
        ChatRooms: { path: '/chatrooms' },
        Networking: { path: "/gettheapp" },
        MyProfile: { path: "/myprofile" },
        Agenda: { path: "/agenda/{{code}}" },
        Networking: { path: "/mynetwork" },
        Polls: { path: "/polls" },
        Vendors: {path: "/pages/{{code}}"},
        VideoTest: { path: '/videotest' },
        MyFavorites: {path: '/favorites'},
        MyAppointments: {path: '/appointments'}
    },
    BroadcastList: { path: '/mybroadcasts/{{code}}'},
    Agenda: { path: "/agenda/{{code}}" },
    AgendaFavorites: { path: "/agenda/{{code}}" },
    HtmlContent: { path: "/page/{{code}}" },
    Vendors: { path: "/pages/{{code}}" },
    VendorsFavorites: { path: "/pages/{{code}}"},
    Map: { path: "/map/{{code}}" },
    Profile: { path: "/details/{{type}}/{{code}}" },
    Attendees: { path: "/attendees/{{code}}"},
    SessionScanner: { path: "/sessionscanner/{{code}}"},
    VendorScanner: { path: "/vendorscanner/{{code}}"},
    CustomScanner: { path: "/scanner/{{code}}"},
};

export const TractusLinkRouteScheme = {
    vendor: "/details/page/{{code}}",
    session: "/details/session/{{code}}",
    attendee: "/details/attendee/{{code}}",
    discussion: "/discussionList/chat/{{code}}",
    chat: "/chatrooms/chat/{{code}}",
    poll: "/poll/{{code}}",
};

const adjustedEpoch = new BigNumber("621355968000000000");

const dateTimeMax = new BigNumber("3155378975999999999");

import { AppState } from './appstate'

export default {
    data() {
        return {
            submitting: false,
            error: null,
            validateErrors: [],
        };
    },

    computed: {
        // AppState() {
        //     console.warn("Direct access of AppState.");
        //     return AppState;
        // },

        // attendee() {
        //     console.warn("Direct access of attendee.");
        //     return AppState.attendee;
        // }
    },

    methods: {

        isOnExtraSmallPlatform() {
            return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 920;
        },
        
        markdown(text) {
            if(!text) {
                return null;
            }
            
            text = text.replace("<iframe", "<div class='iframe-ratio'><iframe loading='lazy' ");
            text = text.replace("</iframe>", "</iframe></div>");
            
            const regEx = /\[embed=(.*)\]/g

            let input = text;
            
            let matchResult = input.matchAll(regEx)
           
            let returnContent = input;
            
            for(const m of matchResult) {
              returnContent = returnContent.replace(m[0], `<div class='iframe-ratio'><iframe loading='lazy' src="${m[1]}" allow="fullscreen;camera;microphone"></iframe></div>`);
            }
            
            return marked(returnContent, {
                breaks: true
            });
        },

        goBackOneRoute() {
            let routes = this.$route.matched;

            let routeToGoTo = routes[routes.length - 2];

            this.$router.replace(routeToGoTo);
        },

        attendeeAllowedToViewSection(section) {

            let sectionCategories = section.VisibleToAttendeeCategories;
            if(!sectionCategories || sectionCategories.length == 0) {
                return true;
            }

            let attendeeCategories = AppState.attendee.Categories.map(x => x.Code);
            sectionCategories = sectionCategories.map(x => x.Code);


            if(sectionCategories.filter(x => attendeeCategories.includes(x)).length > 0) {
                return true;
            }

            return false;
        },

        uuidv4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
            );
        },
        createUniqueCode() {
            let guid = this.uuidv4().replace(/-/g, '').toUpperCase();
            return guid;
        },
        getAzureCompatibleTimeStamp() {
            // IMPORTANT: We use BigInt here because the Azure ticks are > 15 digits, and JS numbers
            // can't handle that.

            // new Date() is always guaranteed to be UTC.
            // let compareDate = new Date();
            // const adjustedEpoch = 621355968000000000n;
            // const dateTimeMax = 3155378975999999999n;
            // let compareTicks = BigInt(10000 * compareDate.getTime()) + adjustedEpoch;
            // let stamp = (dateTimeMax - compareTicks).toString().padStart(20, '0');

            let compareDate = new Date();

            let compareTicks = dateTimeMax.minus(new BigNumber(compareDate.getTime()).times(10000).plus(adjustedEpoch));

            let stamp = compareTicks.toString().padStart(20, '0');

            return stamp;
        },

        getIconForContactInfo(link) {
            if (link.Type == "Downloadable") {
                var ext = link.Value.slice(link.Value.length - 3).toLowerCase();
                if (ext == "pdf") {
                    return Lookups.PDF;    
                }
                else if (ext == "mp4" || ext == "mov") {
                    return Lookups.Play
                }
                return Lookups.Attachment;
            }

            if (link.Type == "Email") {
                return Lookups.Email;
            }
            
            if (link.Value.includes("surveytest.tractus") || link.Value.includes("survey.tractus")) {
                return Lookups.BarGraph;
            }

            let result = Object.keys(IconLookups).find((element) => {
                return link.Value.indexOf(element) != -1;
            });

            if (result) {
                return IconLookups[result];
            }

            return Lookups.Website;
        },

        doNavigate(route) {
            if (!route) {
                return;
            }

            if (route.launch && route.path.startsWith("http")) {
                window.open(route.path, "_blank");
                this.postAnalytics(
                    AppState.attendee.Code,
                    "OpenLink",
                    null,
                    "Home",
                    AppState.attendee.Show.Code,
                    route.path
                );
                return;
            }


            this.$router.push(route);
        },

        getRouterLink(section, idx) {
            if (section.SectionTypeCode == "TractusLink") {
                this.openLink(section.Content);
                return;
            }
            
            let suggestedRoute = AppLinkRouteScheme[section.SectionTypeCode];

            if (suggestedRoute && suggestedRoute.HasMultipleRoutes) {
                suggestedRoute = suggestedRoute[section.AppLinkTypeCode];
            } else if (suggestedRoute && suggestedRoute.launch) {
                suggestedRoute.path = section.Content;
            }

            if (suggestedRoute) {
                let toReturn = {
                    ...suggestedRoute,
                    ...{ params: { attendee: AppState.attendee } },
                };

                if (section.SectionTypeCode == "Profile") {
                    toReturn.path = toReturn.path.replace(
                        "{{code}}",
                        section.RelatedItemCode
                    );
                    toReturn.path = toReturn.path.replace(
                        "{{type}}",
                        section.RelatedItemTypeCode.replace("Vendor", 'page')
                    );
                } else {
                    toReturn.path = toReturn.path.replace("{{code}}", idx);
                }



                if (section.SectionTypeCode == 'HtmlContent') {
                    if (section.Content.startsWith('http')) {
                        toReturn.launch = true;
                        toReturn.path = section.Content;
                    }
                }

                return toReturn;
            }

            return {
                name: "AttendeeBadgePortal",
                params: { showCode: AppState.attendee.Show.Code, attendeeCode: AppState.attendee.Code },
            };
        },

        parseTractusLink(tractusUri) {
            try {
                let url = new URL(tractusUri);

                // Why aren't we using url.pathname?
                // Because FUCKING APPLE throws a FUCKING FIT
                // if we're not using HTTP.

                let entityType = tractusUri
                    .replace("tractus://", "")
                    .split("?")[0]
                    .toLowerCase();
                let searchDetails = url.searchParams;

                let entityCode = searchDetails.get("c");

                return {
                    EntityType: entityType.toLowerCase(),
                    Code: entityCode,
                };
            } catch (e) { }
        },

        openLink(uri, attendee, entityType, entityCode) {
            if (uri) {
                uri = uri.trim();
            }

            attendee = attendee || AppState.attendee;

            if (uri.startsWith("http")) {
                this.postAnalytics(
                    attendee.Code,
                    "OpenLink",
                    entityCode,
                    entityType,
                    attendee.Show.Code,
                    uri
                );
                window.open(uri, "_blank");
            } else if (uri.startsWith("www.")) {
                uri = "http://" + uri;
                this.postAnalytics(
                    attendee.Code,
                    "OpenLink",
                    entityCode,
                    entityType,
                    attendee.Show.Code,
                    uri
                );
                
                window.open(uri);
            } else if (uri.startsWith("tractus://")) {
                let link = this.parseTractusLink(uri);
                if (link.EntityType == 'section') {
                    let section = attendee.Show.Sections.find(x => x.Code == link.Code);
                    if (section) {
                        let i = attendee.Show.Sections.indexOf(section);
                        this.doNavigate(this.getRouterLink(section, i))
                    }
                    return;
                } 

                if(link.EntityType == 'vendor_booking') {

                    let relatedCode = link.Code.split('_')[0];
                    let bookingCode = link.Code.split('_')[1];

                    let realRelatedType = link.EntityType.replace('_booking', '');
                    realRelatedType = realRelatedType[0].toUpperCase() + realRelatedType.substr(1);

                    let route = {
                        name: `ViewAppointmentRequests${realRelatedType}`,
                        params: {
                            code: relatedCode,
                            requestcode: bookingCode
                        }
                    };
    
                    this.$router.push(route);
                    return;
                }

                let suggestedRoute = TractusLinkRouteScheme[link.EntityType];
                if (suggestedRoute) {
                    let toPush = {
                        path: suggestedRoute.replace("{{code}}", link.Code),
                        params: { attendee: attendee },
                    };
                    this.$router.push(toPush);
                }
            } else if (uri.indexOf("@") != -1) {
                this.postAnalytics(
                    attendee.Code,
                    "OpenEmailLink",
                    entityCode,
                    entityType,
                    attendee.Show.Code,
                    uri
                );
                // Treat as an email.
                window.location.href = `mailto:${uri}`;
            } else {
                uri = `https://${uri}`;
                // Failsafe - assume it's a URL of some sort.
                this.postAnalytics(
                    attendee.Code,
                    "OpenLink",
                    entityCode,
                    entityType,
                    attendee.Show.Code,
                    uri
                );
                
                window.open(uri);
            }
        },

        isOnline(code) {
            let onlineAttendeeCodes = AppState.onlineAttendeeCodes;
            return onlineAttendeeCodes?.find(x => x == code);
        },

        getAttendeeProfilePhotoUrl(showCode, value) {
            if (value.startsWith('tractus://')) {
                value = this.parseTractusLink(value);
            }

            return `${this.getApiUrl()}/api/asset/${showCode}/attendee/${value.Code}/profile`
        },

        getEntityPhotoFromTypeAndCode(entityType, entityCode) {
            if (entityType.toLowerCase() == "attendee"
                || entityType.toLowerCase() == 'vendor') {
                let photoUrl = `${this.getApiUrl()}/api/asset/${AppState.attendee.Show.Code}/${entityType.toLowerCase()}/${entityCode}/profile`;
                return photoUrl;
            } else if (entityType.toLowerCase() == "discussion") {
                return Lookups.Chat;
            } else if (entityType.toLowerCase() == "poll") {
                return Lookups.Poll;
            } else if (entityType.toLowerCase() == "session") {
                return Lookups.Agenda;
            } else if (entityType.toLowerCase() == "announcement") {
                return Lookups.Notification;
            } else {
                return Lookups.Website;
            }
        },

        getEntityPhoto(link) {
            let entityDetails = this.parseTractusLink(link);
            return this.getEntityPhotoFromTypeAndCode(entityDetails.EntityType, entityDetails.Code);
        },

        get(code) {
            return get(code);
        },

        set(code, val) {
            return set(code, val);
        },

        clear() {
            return clear();
        },

        getApiUrl() {
            return getApiUrl();
        },

        cloneObject(source) {
            if (source) {
                return JSON.parse(JSON.stringify(source));
            }

            return null;
        },

        /**
         * Returns a new GUID without dashes. All uppercase.
         */
        newUniqueId() {
            let toReturn = this.newGuid();
            toReturn = toReturn.replace(/-/g, '');
            return toReturn;
        },

        /**
         * Returns the current device ID for this session.
         */
        getDeviceId() {
            return sessionStorage.getItem("dk");
        },

        postLoginEvent(
            attendeeCode,
            showCode
        ) {
            let loadedDeviceKeyFromStorage = false;

            if(window.sessionStorage) {
                try {
                    let deviceKey = sessionStorage.getItem('dk');

                    if(!deviceKey) {
                        window.deviceKey = this.newUniqueId();
                    } else {
                        window.deviceKey = deviceKey;
                        loadedDeviceKeyFromStorage = true;
                    }

                    sessionStorage.setItem('dk', window.deviceKey);
                } catch {
                    window.deviceKey = this.newUniqueId();
                }
            }

            let userAgent = navigator.userAgent;

            let toPost = {
                ShowCode: showCode,
                AttendeeCode: attendeeCode,
                EntityCode: userAgent,
                EntityTypeCode: 'LoginDevice',
                EventName: 'Login',
                AccessDateTime: new Date().toUTCString(),
                Device: window.deviceKey,
            };

            if(loadedDeviceKeyFromStorage) {
                toPost = {
                    ShowCode: showCode,
                    AttendeeCode: attendeeCode,
                    EntityCode: userAgent,
                    EntityTypeCode: 'LoginDeviceResume',
                    EventName: 'LoginResume',
                    AccessDateTime: new Date().toUTCString(),
                    Device: window.deviceKey,
                };
            }


            window.$signalRConnection.invoke('PostAnalytics', toPost);
        },

        /**
         *
         * @param {*} attendeeCode
         * @param {*} eventName Identifies the type of event (e.g. Session, LandingPage, etc.)
         * @param {*} resourceCode The code of the resource being accessed.
         * @param {*} resourceTypeCode The type of the resource being accessed (e.g. Session, Attendee)
         * @param {*} showCode
         */
        postAnalytics(
            attendeeCode,
            eventName,
            resourceCode,
            resourceTypeCode,
            showCode,
            uri
        ) {
            if (!window.$signalRConnection
                || !window.$signalRConnection.invoke
                || window.$signalRConnection.notInitialized) {
                console.error("SIGNALR NOT INITIALIZED", window.$signalRConnection);
                return;
            } 


            var toPost = {
                ShowCode: showCode,
                AttendeeCode: attendeeCode,
                EntityCode: resourceCode,
                EntityTypeCode: resourceTypeCode,
                EventName: eventName,
                AccessDateTime: new Date().toUTCString(),
                Device: window.deviceKey,
                Uri: uri
            };

            window.$signalRConnection.invoke('PostAnalytics', toPost);
        },
    },
};
