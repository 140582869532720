<template>
    <div v-if="submitting"
        class="webinar-container loading-container">
        <loading-container />
    </div>
    <div v-else
         class="webinar-container"
         :class="[rightSidebarComponent != 'none' ? 'show-right-sidebar' : 'collapse-right-sidebar', leftSidebarComponent != 'none' ? 'show-left-sidebar' : 'collapse-left-sidebar']">
        <!-- <div class="debug-stat-overlay" v-if="true">
            {{invitedAttendees}}
        </div> -->
        <component :is="'style'" v-html="customLayoutCss">
        </component>
        <div class="connecting-indicator"
             v-if="connectionMessage">
            <div>
                <h1>{{connectionMessage}}</h1>
                <loading></loading>
            </div>
        </div>
        <div class="webinar-left-pane">
            <div v-show="leftSidebarComponent == 'productionchat'">
                <chat-area :discussionCode="productionChatCode"
                           :doNotModifyTitle="true"
                           :isInline="true"
                           :hideCallButton="true"
                           :suppressOnLeaveChatNotification="false"
                           @closerequest="toggleLeftSidebar()"
                           :showQaTools="false"
                           ref="qaChat">
                </chat-area>      
            </div>            
            <div v-show="leftSidebarComponent == 'virtualBg'">
                <div class="webinar-settings-panel">
                    <div class="header-actions">
                        <div class="title">
                            Virtual Background
                        </div>
                        <button @click="toggleLeftSidebar()"
                                class="header-buttons icomoon">
                            
                        </button>
                    </div>
                    <div>
                        <div class="device-picker">
                                <select v-model="settings.backgroundFilter">
                                    <option value="">
                                        No Filter
                                    </option>
                                    <option value="blur">
                                        Blur Background
                                    </option>
                                    <option value="image"
                                            :disabled="!settings.backgroundImage">
                                        Virtual Background
                                    </option>
                                </select>
                            </div>
                            
                            <div class="virtual-background-settings">
                                <img crossorigin="anonymous" 
                                        :src="settings.backgroundImage || '/placeholder.png'" 
                                        ref="virtualBackground"
                                        @load="onBgImageLoaded"
                                        @click="onRequestBgUpload">
                                <loading v-if="bgUploadInProgresss">
                                </loading>
                            </div>

                            <div class="button-group">
                                <button @click="onRequestBgUpload">
                                    Upload Virtual Background
                                </button>
                                <button @click="onDeleteVirtualBg">
                                    Delete
                                </button>
                            </div>

                            <file-upload ref="uploaderBg"
                                            style="display: none;"
                                            :assetType="`virtualbg_${attendeeCode}`"
                                            :acceptFileType='"image/png, image/jpeg, image/webp"'
                                            @uploadSuccess="onBgUploaded"
                                            @uploadFailed="onBgUploadFailed"
                                            @uploadStarted="onBgUploadStarted" />           
                        </div>
                </div>
            </div>
            <div v-if="leftSidebarComponent == 'layout'">
                <textarea v-model="customLayoutCss"
                          style="min-height: 400px">
                </textarea>
                <button @click="updateHidePresenterIfVideoOff">
                    Save Changes
                </button>
            </div>
            <div v-if="leftSidebarComponent == 'micSettings'">
                <div class="webinar-settings-panel">
                    <div class="header-actions">
                        <div class="title">
                            Device Settings
                        </div>
                        <button @click="toggleLeftSidebar()"
                                class="header-buttons icomoon">
                            
                        </button>
                    </div>
                    <div>
                        <div class="device-picker">
                            <span class="icomoon">
                                
                            </span>
                            <select v-model="selectedCameraId">   
                                <option :value="null">
                                    (Turn off camera)
                                </option>
                                <option v-for="camera in camList"
                                        :value="camera.deviceId">
                                    {{camera.label}}
                                </option>
                            </select>   
                        </div>
                        <div class="device-picker">
                            <span class="icomoon">
                                
                            </span>
                            <select v-model="selectedMicId"> 
                                <option v-for="mic in micList"
                                        :value="mic.deviceId">
                                    {{mic.label}}
                                </option>
                            </select>   
                        </div>          
                        <div class="device-picker">
                            <span class="icomoon">
                                
                            </span>
                            <select v-model="selectedOutputId"> 
                                <option v-for="output in outputList"
                                        :value="output.deviceId">
                                    {{output.label}}
                                </option>
                            </select>   
                        </div>        
                    </div>

                    <h3>Mirror My Video</h3>
                    <div class="device-picker">
                        <select v-model="settings.mirrorVideo">
                            <option :value="true">
                                Mirror my video
                            </option>
                            <option :value="false">
                                Do not mirror my video
                            </option>
                        </select>
                    </div>

                    <h3>Advanced Audio Settings</h3>
                    <div>
                        <h4>Noise Reduction</h4>
                        <div class="device-picker">
                            <select v-model="settings.noiseReduction">
                                <option value="high">
                                    Standard Noise Reduction
                                </option>
                                <option value="low">
                                    Low Noise Reduction
                                </option>
                            </select>
                        </div>
                        <h4>Audio Processing</h4>
                        <div class="device-picker">
                            <select v-model="settings.processMode">
                                <option value="standard">
                                    Standard Audio Capture
                                </option>
                                <option value="music">
                                    Music (Original Audio)
                                </option>
                                <option value="unprocessed">
                                    Unprocessed Audio Capture
                                </option>
                            </select>
                        </div>
                        <h4>Headphone Mode (Echo Cancellation)</h4>
                        <div class="device-picker">
                            <select v-model="settings.echoCancellation">
                                <option value="on">
                                    Standard (Echo Cancellation On)
                                </option>
                                <option value="off">
                                    Headphones (Echo Cancellation Off)
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>            
        </div>
        <div class="webinar-main-pane"
             :class="[mode]">
            <div class="stage-part"
                 ref="stagePart">
                <div class="main-webinar-container video-container" 
                    v-show="conference && galleryPresenters && galleryPresenters.length > 0 || debugPresenters"
                    ref="cameraContainer">
                    <div class="camera" 
                        v-for="i in debugPresenters"
                        :key="i">
                        <webinar-view-video isDebug="true">

                        </webinar-view-video>
                    </div>
                    <div class="camera" 
                        v-for="participant in galleryPresenters"
                        :key="participant.id">
                        <webinar-view-video :participant="participant.Participant"
                                            :stream="participant.Stream"
                                            :showPresenterName="true"
                                            :mirrorVideo="settings.mirrorVideo">

                        </webinar-view-video>
                    </div>
                    <template v-if="invitedAttendees && invitedAttendees.length > 0">
                        <div class="camera" 
                            v-for="code in invitedAttendees"
                            :key="code">
                            <webinar-view-video :code="code"
                                                :showCode="showCode"
                                                isConnecting="true">

                            </webinar-view-video>
                        </div>
                    </template>
                </div>
                <div class="stage-container video-container"
                    v-show="conference || debugPresenters || mainStageDebugParticipant"
                    ref="stageContainer">
                    <div v-if="mainStageParticipant" 
                        class="camera">
                        <webinar-view-video :participant="mainStageParticipant.Participant"
                                            :stream="mainStageParticipant.Stream"
                                            :blurIfMyStream="true">

                        </webinar-view-video>
                    </div>
                    <div v-else-if="mainStageDebugParticipant"
                        class="camera">
                        <webinar-view-video isDebug="true">

                        </webinar-view-video>
                    
                    </div>
                </div>

            </div>

            <div class="hls-video-container"
                 v-show="videoUrl && videoStartDateTime && showVideo"
                 :class="{'tiny': !showFullSizeHlsContainer && isUserModerator, 'full': showFullSizeHlsContainer || !isUserModerator}">
                <hls-video-player 
                            :showControls="isUserModerator"
                            ref="videoPlayer"
                            @videoevent="onVideoEventFromPlayer"
                            :clearStartAtTime="isUserAdmin"
                            :suppressEventEmit="true">

                </hls-video-player>
            </div>
            <div v-if="!isUserModerator && !isSessionLive && !(videoUrl && videoStartDateTime && showVideo)"
                 class="holding-content">
                <slot name="holding">

                </slot>
                <div v-if="!isSessionLive && !isSessionFinished">
                    <h1 class="waiting-message">
                        This session has not started yet.
                    </h1>
                </div>
                <div v-else-if="!isSessionLive && isSessionFinished">
                    <h1 class="waiting-message">
                        This session has ended.
                    </h1>
                </div>
            </div>
            <div class="record-webinar-indicator" v-if="recording && conference">
                <div class="online-icon recording"></div> Recording
            </div>

            <div class="record-webinar-indicator streaming-webinar-indicator" v-if="streamingRtmp && conference">
                <div class="online-icon recording"></div> Streaming
            </div>
            
            <transition name="fade">
                <div class="current-qa-question"
                     v-if="currentQaQuestionToProject">
                    <h2>From: {{currentQaQuestionToProject.DisplayName}}</h2>
                    <p>
                        {{currentQaQuestionToProject.Body}}
                    </p>
                </div>
            </transition>
        </div>
        <div class="webinar-right-pane">
            <div v-if="rightSidebarComponent == 'tts' && isUserAdmin">
                <webinar-announcement-panel @closerequest="toggleRightSidebar('tts')"
                                            :roomCode="roomCode"
                                            :indicatedStartTime="indicatedStartTime"
                                            :indicatedEndTime="indicatedEndTime"
                                            :isUserAdmin="isUserAdmin"
                                            :isUserModerator="isUserModerator"
                                            :autoAnnouncements="autoAnnouncements">

                </webinar-announcement-panel>
            </div>
            <div v-if="rightSidebarComponent == 'polls' && isUserModerator">
                <lite-poll-editor :roomCode="roomCode"
                                  @closerequest="closePoll"
                                  >
                </lite-poll-editor>
            </div>
            <div v-if="rightSidebarComponent == 'settings'">
                <webinar-settings :showSessionControls="showSessionControls"
                                    :isOpen="isSessionLive"
                                    :entityTypeCode="entityTypeCode"
                                    :entityCode="entityCode"
                                    :hidePresenterIfVideoOff="hidePresenterIfVideoOff"
                                    :showTimer="showTimer"
                                    :nonModeratorIsParticipant="nonModeratorIsParticipant"
                                    :webinarStatus="webinarStatus"
                                    :isUserAdmin="isUserAdmin"
                                    :isUserModerator="isUserModerator"
                                    :videoUrl="videoUrl"
                                    :roomCode="roomCode"
                                    :isRecording="recording"
                                    :isStreamingRtmp="streamingRtmp"
                                    :isEnded="isSessionFinished"
                                    :showVideo="showVideo"
                                    :allowReactions="allowReactions"
                                    :videoStartDateTime="videoStartDateTime"
                                    :conference="conference"
                                    :customLayoutCss="customLayoutCss"
                                    @closerequest="toggleRightSidebar('settings')"
                                    @launch-production-chat="onLaunchPrivateProductionChat"
                                    @save-state="postUpdateToWebinarAsync"
                                    @scrub-video="scrubHlsVideoPlaybackAsync"
                                    @toggle-panel="toggleRightSidebar">
                </webinar-settings>
            </div>
            <div v-show="rightSidebarComponent == 'chat'">
                <chat-area :discussionCode="discussionCodeForRoom"
                        :doNotModifyTitle="true"
                        :isInline="true"
                        :hideCallButton="true"
                        @messagereceived="onMessageReceived"
                        @participantclick="onParticipantClick"
                        :emotions="emotions"
                        :suppressOnLeaveChatNotification="suppressOnLeaveChatNotification"
                        @closerequest="closeChat"
                        showQaTools="true"
                        ref="chat">
                    <template v-slot:speaker-list v-if="isUserModerator && conference">
                        <h2 class="header-subtitle">Connected Speakers</h2>
                        <div class="speaker-list pb-2">
                            <div v-for="participant in speakerList"
                                 :key="participant.id"
                                 @click.prevent="manageParticipant(participant)"                 
                                 class="participant-card">
                                <img-g :src="participant.info.avatarUrl"
                                        fallbackSrc="/img/person.png" />  
                                <span>
                                    {{participant.info.name}}
                                </span>
                                <div v-if="canBroadcastInCurrentRoom">
                                    <button @click.stop="requestChangeQualityBand(participant)"
                                            class="icomoon">
                                        
                                    </button>
                                    <button @click.stop="sendConferenceCommandMessageToParticipant(participant, 'MuteMic')"
                                            class="icomoon">
                                        
                                    </button>  
                                    <button @click.stop="sendConferenceCommandMessageToParticipant(participant, 'UnmuteMic')"
                                            class="icomoon">
                                        
                                    </button>  
                                </div>
                            </div>
                        </div>
                        <div class="button-group speaker-list-controls">
                            <button @click.stop="sendConferenceCommandMessage(null, 'MuteMic')"
                                    v-if="canBroadcastInCurrentRoom">
                                Mute All
                            </button>
                            <button @click.stop="sendConferenceCommandMessage(null, 'MuteCam')"
                                    v-if="canBroadcastInCurrentRoom">
                                Turn off Cameras
                            </button>
                            <button @click.stop="sendConferenceCommandMessage(null, 'StopScreenShare')">
                                Stop All Screen Share
                            </button>
                            <button @click.stop="forceClearEmotionForAllAttendees">
                                Lower Hands
                            </button>
                        </div>
                    </template>
                </chat-area>      
                <div class="participant-tool-overlay" v-if="attendeeToManage || participantToManage">
                    <div v-if="attendeeToManage">
                        <div>
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${attendeeToManage.Code}/profile`"
                                fallbackSrc="/img/person.png" />
                            <h1>
                                {{attendeeToManage.DisplayName}}
                            </h1>
                        </div>

                        <div class="button-group">
                            <button @click.stop="sendConferenceCommandMessage(attendeeToManage.Code, 'Promote')"
                                    v-if="canBroadcastInCurrentRoom">
                                Promote
                            </button>
                            <button @click.stop="sendConferenceCommandMessage(attendeeToManage.Code, 'Kick')"
                                    v-if="canBroadcastInCurrentRoom">
                                Kick
                            </button>
                            <button @click.stop="forceClearEmotionForAttendee(attendeeToManage.Code)"
                                    v-if="emotions && emotions[attendeeToManage.Code]">
                                Lower Hand
                            </button>
                            <button @click.stop="attendeeToManage = null">
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div v-else-if="participantToManage">
                        <div>
                            <img-g :src="participantToManage.info.avatarUrl"
                                fallbackSrc="/img/person.png" />
                            <h1>
                                {{participantToManage.info.name}}
                            </h1>
                        </div>

                        <div class="button-group">
                            <button @click.stop="sendConferenceCommandMessageToParticipant(participantToManage, 'MuteCam')">
                                Turn off Camera
                            </button>
                            <button @click.stop="sendConferenceCommandMessageToParticipant(participantToManage, 'Demote')">
                                Demote
                            </button>
                            <button @click.stop="sendConferenceCommandMessageToParticipant(participantToManage, 'Kick')">
                                Kick
                            </button>
                            <button @click.stop="participantToManage = null">
                                Cancel
                            </button>
                        </div>

                    </div>
                </div>  
            </div>
        </div>
        <div class="webinar-join-setup-dialog"
                v-if="!conference && (isUserModerator || canBroadcastInCurrentRoom)">
            <div>
                <h2>Join {{entityName ? entityName : 'Call'}}</h2>
                <div class="video-preview">
                    <video ref="localpreview"
                            autoplay
                            playsinline
                            muted
                            :class="{'mirror-video': settings.mirrorVideo}">

                    </video>

                    <div>
                        <div class="device-picker">
                            <span class="icomoon">
                                
                            </span>
                            <select v-model="selectedCameraId">   
                                <option :value="null">
                                    (Turn off camera)
                                </option>
                                <option v-for="camera in camList"
                                        :value="camera.deviceId">
                                    {{camera.label}}
                                </option>
                            </select>   
                        </div>
                        <div class="device-picker">
                            <span class="icomoon">
                                
                            </span>
                            <select v-model="selectedMicId"> 
                                <option v-for="mic in micList"
                                        :value="mic.deviceId">
                                    {{mic.label}}
                                </option>
                            </select>   
                        </div>

                        <div class="button-group">
                            <button @click="connectToCall()"
                                    class="start-call">
                                <span class="icomoon"></span>
                                <span>Join</span>
                            </button>

                            <button @click="connectToCall('audience')"
                                    v-if="entityTypeCode"
                                    class="start-call">
                                <span>Watch</span>
                            </button>
                            <button @click="emitExitRequest"
                                    class="end-call">
                                <span>Leave</span>
                            </button>
                        </div>
                    </div>
                </div>                
            </div>
        </div>

        <div class="video-settings-menu"
             v-if="showVideoSettingsMenuPopup"
             @click="showVideoSettingsMenuPopup = false">
            <ul>
                <li @click="toggleLeftSidebar('micSettings')">
                    Change Webcam/Mic
                </li>
                <li @click="toggleLeftSidebar('virtualBg')"
                    v-if="virtualBackgroundsSupported">
                    Change Virtual Background
                </li>
                <li @click="onLaunchPrivateProductionChat"
                    v-if="(isUserAdmin || isUserModerator) && entityTypeCode">
                    Show Private Production Chat
                </li>
                <li @click="toggleRightSidebar('settings')"
                    v-if="isUserAdmin">
                    Advanced Settings
                </li>
                <li @click="toggleLeftSidebar('layout')"
                    v-if="isUserAdmin">
                    Layout
                </li>
            </ul>
        </div>


        <div class="leave-call-menu"
             v-if="showExitPanel"
             @click="showExitPanel = false">
            <ul>
                <li v-if="isUserAdmin && entityTypeCode"
                    class="end-session-danger"
                    @click="askExitAndEndSession">
                    End Session for All
                </li>
                <li @click="emitExitRequest">
                    Leave Meeting
                </li>
            </ul>
        </div>
        <div class="timer-indicator"
             v-if="showTimer && isUserModerator"
             :class="[isBeforeStart ? 'before-start' : '', isOverTime ? 'over-time' : '']">
            <span class="clock">
                {{countdownDisplay}}
            </span>
            <span class="overtime-message">
                Overtime
            </span>
            <span class="before-start-message">
                Before Session Start
            </span>
        </div>
        <div class="banner-message-container"
             v-if="bannerMessage">
            <div class="banner-message">
                {{bannerMessage}}
            </div>     
        </div>
        <div class="webinar-bottom-control-bar control-bar"
             v-show="true || conference || (videoUrl && videoStartDateTime && showVideo)">
            <div v-if="canBroadcastInCurrentRoom && conference">
                <button type="button"
                        class="control-bar-btn fixed-size-btn"
                        @click="toggleMute">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        {{muted ? 'Unmute' : 'Mute'}}
                    </span>

                    <div class="mute-strikethrough"
                        v-if="muted"></div>
                </button>



                <button type="button"
                        class="control-bar-btn fixed-size-btn"
                        @click="toggleCamera">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        {{!selectedCameraId ? 'Start' : 'Stop'}} Video
                    </span>

                    <div class="mute-strikethrough"
                        v-if="!selectedCameraId">
                    </div>
                </button>

                <button type="button"
                        class="control-bar-btn"
                        @click="showVideoSettingsMenu">
                    <span class="icomoon">
                        
                    </span> 
                    <span>
                        Settings
                    </span>
                </button>

            </div>
            <div v-else>
            </div>

            <div>
                <button type="button"
                        class="control-bar-btn hide-on-xs"
                        @click="toggleRightSidebar('members')"
                        v-if="isUserModerator">
                    <span class="icomoon">
                        
                    </span>
                    <span class="notification-badge"
                            v-if="isUserModerator && displayEmotionIndicator"></span>
                    <span>
                        Participants
                    </span>
                </button>

                <button type="button"
                        class="control-bar-btn share-screen-btn hide-on-xs"
                        @click="shareScreen"
                        v-if="canBroadcastInCurrentRoom && conference">
                    <span class="icomoon">
                        
                    </span>
                    <span v-if="!sharingScreen">
                        Share Screen
                    </span>
                    <span v-else-if="sharingScreen">
                        Stop Share
                    </span>
                </button>

                <button type="button"
                        class="control-bar-btn"
                        @click="toggleRightSidebar('chat')">
                    <span class="icomoon">
                        
                    </span>
                    <span class="notification-badge" 
                        v-if="unreadMessages > 0"></span>
                    <span>
                        Chat <span v-if="unreadMessages > 0">({{unreadMessages}})</span>
                    </span>
                </button>
                <button type="button"
                        class="control-bar-btn"
                        :class="{'active': emotion}"
                        v-if="allowReactions && conference"
                        @click="setEmotion('hand')">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        {{emotion ? 'Lower' : 'Raise'}} Hand
                    </span>
                </button>

                <button type="button"
                        class="control-bar-btn"
                        v-if="isUserModerator"
                        @click="toggleRightSidebar('polls')">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        Polls
                    </span>
                </button>

                <button type="button"
                        class="control-bar-btn"
                        @click="startOrStopRecording"
                        v-if="recordingButtonVisible">
                    <span class="icomoon">
                        {{recording ? '' : ''}}
                    </span>
                    <span>
                        {{recording ? 'Stop Record' : 'Record'}}
                    </span>
                </button>

                <button class="control-bar-btn"
                        v-if="filteredApps.length"
                        @click="showAppBar">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        Apps
                    </span>
                </button>

            </div>

            <div>
                <button type="button"
                        class="control-bar-btn"
                        @click="showExitPanel = true;">
                    <span class="icomoon">
                        
                    </span>
                    <span>
                        Leave
                    </span>
                </button>
            </div>

        </div>

    </div>
</template>
<script>

import { AppState } from './appstate';
import Common from './common.js'
import Token from './authentication/token.js'

import VoxeetSDK from '@voxeet/voxeet-web-sdk'

import WebinarViewVideo from './webinar.view.video.vue';
import moment from 'moment'
import Attendee from './list-items/attendee.vue'

import { isProxy, toRaw } from 'vue'

const POPUPS = [
    'Admin',
    'Cam',
    'Mic',
    'Xs'
];

export default {
    components: { WebinarViewVideo, Attendee },
    props: [
        'roomCode', 
        
        'entityName',
        'indicatedStartTime', 
        'indicatedEndTime',
        'entityTypeCode',
        'entityCode',
        'webinarStatus',
        'exitRequestIsImmediate',
        'suppressOnLeaveChatNotification',

        'invitedAttendees'
    ],
        
    mixins: [
        Common,
        Token,
    ],

    computed: {
        attendeeCode() {
            return AppState.attendeeCode;
        },

        showCode() {
            return AppState.showCode;
        },

        virtualBackgroundsSupported() {
            if(!navigator.userAgentData) {
                return false;
            }

            if(navigator.userAgentData && navigator.userAgentData.mobile) {
                return false;
            }

            try {
                let brands = navigator.userAgentData.brands.find(x => x.brand.indexOf('Edge') != -1 || x.brand.indexOf('Chrome') != -1);

                if(!brands) {
                    return false;
                }

            } catch {
                return false;
            }

            return true;
        },

        filteredApps() {
            if(this.isUserAdmin) {
                return this.apps;
            }

            if(this.isUserModerator) {
                return this.apps.filter(x => x.AllowModerator || x.AllowUser);
            }

            return this.apps.filter(x => x.AllowUser);
        },

        displayEmotionIndicator() {
            if(!this.isUserModerator) {
                return false;
            }

            let values = Object.values(this.emotions);

            if(values.length == 0
                || !values.find(x => x != null)) {
                return false;
            }

            return true;
        },

        recordingButtonVisible() {
            if(!this.conference) {
                return false;
            }

            if(this.isUserAdmin) {
                return true;
            }

            if(!this.canRecord) {
                return false;
            }

            return this.isUserModerator;
        },
        
        isUserModerator() {
            return this.isUserAdmin
                || this.discussion?.ParticipantRoles?.[AppState.attendeeCode] == 'Moderator';
        },

        isUserAdmin() {
            return AppState.attendee.RoleCode == 'Administrator'
                || this.discussion?.ParticipantRoles?.[AppState.attendeeCode] == 'Administrator';;
        },

        canBroadcastInCurrentRoom() {
            return this.nonModeratorIsParticipant
                    || this.participantRole == 'host';
        },

        mode() {
            return !this.mainStageParticipant && !this.mainStageDebugParticipant
                ? 'gallery'
                : 'stage';
        },

        showSessionControls() {
            return this.entityTypeCode 
                && this.entityTypeCode.toLowerCase() == 'session';
        },

        galleryPresenters() {
            let toReturn = this.participants;

            if(this.hidePresenterIfVideoOff) {
                toReturn = toReturn.filter(x => x.Stream);
            }

            return toReturn;
        },

        speakerList() {
            let toReturn = this.dolbyParticipants;

            toReturn = toReturn.filter(x => x.audioTransmitting);

            return toReturn;
        },

        discussionCodeForRoom() {
            return this.roomCode;
        },
    },

    watch: {
        selectedCameraId() {
            this.updateCameraSelection();
        },

        selectedMicId() {
            this.updateMicSelection();
        },

        selectedOutputId() {
            this.updateOutputSelection();
        },

        'settings.echoCancellation'() {
            this.updateAudioProcessing();
        },

        'settings.processMode'() {
            this.updateAudioProcessing();
        },

        'settings.noiseReduction'() {
            this.updateAudioProcessing();
        },

        'settings.backgroundFilter'() {
            this.updateBackgroundFilter();
        },

        'settings.mirrorVideo'() {
            localStorage.setItem('mirrorVideo', this.settings.mirrorVideo);
        },

        rightSidebarComponent() {
            this.scheduleResize();
        },

        leftSidebarComponent() {
            this.scheduleResize();
        },

        showTimer() {
            this.ensureTimerIsVisible();
        },
    },

    methods: {
        showVideoSettingsMenu() {
            this.showVideoSettingsMenuPopup = !this.showVideoSettingsMenuPopup;
        },

        onBgImageLoaded() {
            if(this.settings.backgroundFilter == 'image') {
                this.updateBackgroundFilter();
            }
        },

        onDeleteVirtualBg() {
            if(this.settings.backgroundFilter == 'image') {
                this.settings.backgroundFilter = '';
            }

            this.settings.backgroundImage = null;
            localStorage.removeItem('virtualbg');
        },

        onBgUploaded(file) {
            this.bgUploadInProgresss = false;
            this.settings.backgroundImage = file;
            localStorage.setItem('virtualbg', this.settings.backgroundImage);
        },      

        onBgUploadStarted() {
            this.bgUploadInProgresss = true;
        },

        onBgUploadFailed() {
            this.bgUploadInProgresss = false;
            this.$awn.alert('Could not upload your file. Try again later.');
        },

        onRequestBgUpload() {
            this.$refs.uploaderBg.requestChooseFile();
        },


        async updateBackgroundFilter() {
            try {
                let selectedProcessor = this.settings.backgroundFilter;
                let processor = null;

                if(selectedProcessor == 'blur') {
                    processor = {
                        type: 'bokeh'
                    };
                } else if(selectedProcessor == 'image') {
                    processor = {
                        type: 'backgroundreplacement',
                        image: this.$refs.virtualBackground,
                    };
                } 

                if(processor) {
                    await VoxeetSDK.video.local.setProcessor(processor);
                } else {
                    await VoxeetSDK.video.local.disableProcessing();
                }
            } catch(ex) {
                console.error("Could not set processing.", ex);
                this.$awn.alert("Sorry. We could not activate virtual backgrounds. This feature is only supported on Chrome and Edge for desktops and laptops.");
            }
        },


        manageParticipant(participant) {
            if(!this.isUserModerator) {
                return;
            }

            if(!this.canBroadcastInCurrentRoom) {
                return;
            }

            this.participantToManage = participant;
        },

        async setEmotion(emotion) {

            if(this.emotion && this.emotion == emotion) {
                this.emotion = null;
            } else {
                this.emotion = emotion;
            }

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.showCode,
                FromCode: AppState.attendeeCode,
                TypeCode: 'WebinarControl',
                DID: this.getDeviceId(),
                DiscussionAreaCode: this.discussionCodeForRoom,
                Message: {
                    Type: 'Emotion',
                    Emotion: this.emotion,
                    FromCode: AppState.attendeeCode
                }
            });

            // TODO: Use Dolby once we confirm listeners can send messages.
            // await this.sendConferenceCommandMessage(null, 'Emotion', {
            //     From: AppState.attendeeCode,
            //     Emotion: this.emotion
            // });
        },

        changeQualityBand(e) {
            this.settings.noiseReduction = this.settings.noiseReduction == 'high' ? 'low' : 'high'
            console.log(e, this.settings);
        },

        requestChangeQualityBand(participant) {
            let attendeeCode = participant.info.externalId.split('_')[0];

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.showCode,
                FromCode: AppState.attendeeCode,
                TypeCode: 'WebinarControl',
                DID: this.getDeviceId(),
                DiscussionAreaCode: this.discussionCodeForRoom,
                RecipientAttendeeCodes: [attendeeCode],
                Message: {
                    Type: 'ToggleQualityBand'
                },
            });
        },

        closePoll(){
            this.toggleRightSidebar('polls');
        },
        closeChat(){
            this.toggleRightSidebar('chat');
        },

        getCurrentStateForPost() {
            let currentState = {
                HidePresenterIfVideoOff: this.hidePresenterIfVideoOff,
                IsRecording: this.recording,
                IsEnded: this.isSessionFinished,
                IsOpen: this.isSessionLive,
                RoomCode: this.roomCode,
                ShowTimer: this.showTimer,
                VideoUrl: this.videoUrl,
                VideoStartDateTime: this.videoStartDateTime,
                NonModeratorIsParticipant: this.nonModeratorIsParticipant,
                ShowVideo: this.showVideo,
                AllowReactions: this.allowReactions,
                IsStreaming: this.streamingRtmp,
                CustomLayoutCss: this.customLayoutCss,
            }

            return currentState;
        },



        closePopupLists(ignoreType) {
            POPUPS.forEach(key => {

                if(ignoreType && key == ignoreType) {
                    return;
                }

                let methodName = `show${key}List`;

                if(this.popupLists[methodName]) {
                    this.popupLists[methodName] = false;
                }
            });
        },

        openOrClosePopupList(popup) {
            this.closePopupLists(popup);
            let showKey = `show${popup}List`;

            if(this.popupLists[showKey]) {
                this.popupLists[showKey] = false;
            } else {
                this.popupLists[showKey] = true;
            }
        },

        selectMic(deviceId) {
            this.selectedMicId = deviceId;
            this.closePopupLists();
        },

        selectCamera(deviceId) {
            this.selectedCameraId = deviceId;
            this.closePopupLists();
        },

        emitExitRequest() {
            this.$emit('exit-request');
        },
 
        async forceStopScreenShare() {
            try {

                await VoxeetSDK.conference.stopScreenShare();
                this.sharingScreen = false;
                return;

            } catch(ex) {
                console.error("Error on StopScreenShare --- ", ex);
                this.$awn.alert("Error on ForceStopScreenShare. Check console.");
            }
        },

        changeDebugPresenters(direction) {
            this.debugPresenters = Math.max(0, this.debugPresenters + direction);
            this.scheduleResize();
        },

        toggleStagePresenter() {
            this.mainStageDebugParticipant = !this.mainStageDebugParticipant;
            this.scheduleResize();
        },

        async startOrStopRecording() {
            if(VoxeetSDK.recording.current) {
                VoxeetSDK.recording.stop();
                this.recording = false;
            } else {
                VoxeetSDK.recording.start();
                this.recording = true;
            }

            await this.postUpdateToWebinarAsync(this.getCurrentStateForPost());
        },

        async scrubHlsVideoPlaybackAsync() {
            if(!this.videoUrl || !this.videoStartDateTime || !this.showVideo) {
                return;
            }

            let position = this.$refs.videoPlayer.reportedPosition();

            if(!position || position == NaN) {
                return;
            }

            console.log(position);

            let newStartDate = new Date(new Date() - (position * 1000.0));

            let state = this.getCurrentStateForPost();

            state.VideoStartDateTime = newStartDate;

            await this.postUpdateToWebinarAsync(state);
        },


        async updateHidePresenterIfVideoOff() {
            let state = this.getCurrentStateForPost();
            await this.postUpdateToWebinarAsync(state);
            this.scheduleResize();
        },

        async updateAudioProcessing() {

            let noiseReductionLevel = this.settings.noiseReduction;
            let processMode = this.settings.processMode;
            let echoCancellation = this.settings.echoCancellation;

            console.info(`Noise Reduction is: ${noiseReductionLevel}; Processing is: ${processMode}, Echo Cancel is ${echoCancellation}`);

            await VoxeetSDK.audio.local.setCaptureMode(
                {
                    mode: processMode,
                    modeOptions: {
                        noiseReductionLevel: noiseReductionLevel,
                        echoCancellation: echoCancellation
                    }
                }
            );
        },

        toggleRightSidebar(mode) {
            if(!mode) {
                mode = 'none';
            }

            if(this.rightSidebarComponent == mode) {

                if(this.rightSidebarComponent == 'chat'
                    && this.$refs.chat.showParticipants) {
                    this.$refs.chat.showParticipants = false;
                    return;
                }

                this.rightSidebarComponent = 'none';
                return;
            }

            if(mode == 'chat') {
                this.unreadMessages = 0;
                this.scheduleResize();
            }

            if(mode == 'members') {
                mode = 'chat';
                this.unreadMessages = 0;
                this.scheduleResize();
                this.$refs.chat.showMemberPane();
            }

            this.rightSidebarComponent = mode;
        },


        toggleLeftSidebar(mode) {
            if(!mode) {
                mode = 'none';
            }

            if(this.leftSidebarComponent == mode) {

                this.leftSidebarComponent = 'none';
                return;
            }

            this.leftSidebarComponent = mode;
        },


        async toggleMute() {
            this.muted = !this.muted;
            VoxeetSDK.conference.mute(VoxeetSDK.session.participant, this.muted);
        },

        toggleCamera() {
            if(this.selectedCameraId) {
                this.lastSelectedCameraId = this.selectedCameraId;
                this.selectedCameraId = null;
            } else if (!this.selectedCameraId && this.lastSelectedCameraId) {
                this.selectedCameraId = this.lastSelectedCameraId;
            }
        },

        async shareScreen() {
            if(!this.conference) {
                return;
            }

            if(this.sharingScreen) {
                try {
                    await VoxeetSDK.conference.stopScreenShare();
                    this.sharingScreen = false;
                    return;
                } catch(ex) {
                    console.error("Error on StopScreenShare --- ", ex);
                }
            }

            try {
                await VoxeetSDK.conference.startScreenShare();

                this.sharingScreen = true;

                this.scheduleResize();
            } catch(ex) {
                this.$awn.alert('Could not start screen share.');
                this.sharingScreen = false;
            }
        },

        async updateCameraSelection() {
            if(this.selectedCameraId) {
                try {
                    localStorage.setItem('last-cam-id', this.selectedCameraId);
                } catch {

                }
            }

            if(!this.conference) {
                if(this.previewCameraStream) {
                    this.previewCameraStream.getTracks().forEach(t => t.stop());
                    this.previewCameraStream = null;
                }

                if(this.selectedCameraId) {
                    let stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: this.selectedCameraId,
                            width: {
                                min: 160,
                                ideal: 640
                            },
                            height: {
                                min: 120,
                                ideal: 360
                            }
                        },
                        audio: false
                    });

                    this.previewCameraStream = stream;

                    let videoTag = this.$refs.localpreview;

                    if(videoTag) {
                        videoTag.srcObject = stream;
                        videoTag.play();
                    }
                }

                return;
            }

            if(this.selectedCameraId) {
              

                try {
                    await VoxeetSDK.conference.stopVideo(VoxeetSDK.session.participant);
                } catch(ex) {
                    console.error("Error on StopVideo (inside selected cam ID): ", ex);
                }

                this.lastSelectedCameraId = this.selectedCameraId;

                console.log(this.selectedCameraId, this.camList);
                let constraints = {
                    deviceId: this.selectedCameraId,
                    width: {
                        min: 160,
                        ideal: 640
                    },
                    height: {
                        min: 120,
                        ideal: 360
                    }
                    
                };

                try {
                    let r = await VoxeetSDK.mediaDevice.selectVideoInput(this.selectedCameraId);
                    console.log(`r: ${r}`);
                } catch (ex) {
                    console.error("selectVideoInput error:", {
                        ex: ex
                    });
                }

                await VoxeetSDK.conference.startVideo(VoxeetSDK.session.participant, constraints);

                await this.updateBackgroundFilter();                
            } else {
                try {
                    await VoxeetSDK.conference.stopVideo(VoxeetSDK.session.participant);
                } catch(ex) {
                    console.error("Error on StopVideo: ", ex);
                }
            }
        },

        async updateMicSelection() {

            if(this.selectedMicId) {
                try {
                    localStorage.setItem('last-mic-id', this.selectedMicId);
                } catch {

                }
            }

            if(!this.conference) {
                return;
            }

            try {
                if(this.selectedMicId) {
                    await VoxeetSDK.mediaDevice.selectAudioInput(this.selectedMicId);


                }              
            } catch(ex) {
                console.error(ex)
                this.$awn.alert("Could not use your microphone. Please try a different one.");
            }
        },

        async updateOutputSelection() {
            if(this.selectedOutputId) {
                try {
                    localStorage.setItem('last-output-id', this.selectedOutputId);
                } catch {

                }

                if(!this.conference) {
                    return;
                }

                try {
                    var result = await VoxeetSDK.mediaDevice.selectAudioOutput(this.selectedOutputId);
                } catch(ex) {
                    console.error("Could not switch audio device.", {
                        selectedOutputId: this.selectedOutputId,
                        ex: ex
                    });

                }
            }  
        },

        async onAutoplayBlocked() {
            console.warn("AUTO PLAY WAS BLOCKED -- Handled:", this.autoplayWarningHandled);
            if(this.autoplayWarningHandled) {
                return;
            }

            this.autoplayWarningHandled = true;

            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Your browser has blocked auto-play of the conference audio. Click 'Join Audio' to listen.",
                    () => {
                        r('End');
                    },
                    false,
                    {
                        labels: {
                            confirm: "Join Audio",
                        }
                    }
                )
            });

            await promise;

            VoxeetSDK.conference.playBlockedAudio();

            window.$bus.$emit('play-blocked-audio');
            //this.autoplayWarningHandled = false;
        },

        async onWebinarUpdated(webinarDetails) {
            console.log('::::::::::::::Webinar Updated::', webinarDetails);
            if(webinarDetails.RoomCode != this.roomCode) {
                return;
            }

            this.isSessionLive = webinarDetails.IsOpen;
            this.isSessionFinished = webinarDetails.IsEnded;
            this.recording = webinarDetails.IsRecording;
            this.streamingRtmp = webinarDetails.IsStreaming; 
            this.hidePresenterIfVideoOff = webinarDetails.HidePresenterIfVideoOff;
            this.showTimer = webinarDetails.ShowTimer;
            this.videoUrl = webinarDetails.VideoUrl;
            this.videoStartDateTime = webinarDetails.VideoStartDateTime;
            this.showVideo = webinarDetails.ShowVideo;
            this.nonModeratorIsParticipant = webinarDetails.NonModeratorIsParticipant;
            this.allowReactions = webinarDetails.AllowReactions;
            this.customLayoutCss = webinarDetails.CustomLayoutCss;

            this.scheduleResize();

            await this.checkAndDisplayHlsVideo();

            if(this.isUserModerator) {
                return;
            }

            if(this.isSessionLive && !this.conference) {
                await this.connectToCall();
            } else if (!this.isSessionLive && this.conference) {
                await this.leaveCall();
            }  
        },

        async checkAndDisplayHlsVideo() {
            if(this.videoUrl && this.videoStartDateTime && !this.isSessionLive && this.showVideo) {

                if(this.$refs.videoPlayer.isVideoPlaying) {
                    this.$refs.videoPlayer.seek(this.videoStartDateTime, true);
                } else {
                    this.$refs.videoPlayer.playVideoAtTime(this.videoUrl, this.videoStartDateTime);
                }

                if(this.isUserModerator) {
                    this.$refs.videoPlayer.volumeLevel = 0;
                }

            } else {
                if(this.$refs.videoPlayer && this.$refs.videoPlayer.videoSource) {
                    this.$refs.videoPlayer.stop();
                }
            }            
        },

        onVideoEventFromPlayer(e) {
        },

        async postUpdateToWebinarAsync(details) {

            let uri = `/api/webinar/update/${AppState.showCode}/${this.roomCode}`;
            if(this.entityTypeCode && this.entityCode) {
                uri = `${uri}?t=${this.entityTypeCode.toUpperCase()}&c=${this.entityCode}`;
            }

            await this.tryPost(
                uri, 
                JSON.stringify(details), 
                "application/json");

            VoxeetSDK.command.send(JSON.stringify({
                ToAll: true,
                Code: 'WebinarSettingsUpdated',
                Message: ''
            }));
        
        },



        async onReconnectedToSignalR() {
            // We reconnected to signalr  - we should grab the latest session details.
            let tokenResult = await this.tryGet(`/api/webinar/token/${this.roomCode}`);

            await this.onWebinarUpdated(tokenResult.Result);
        },

        async onVoxeetCommandReceived(participant, message) {
            const command = JSON.parse(message);
            console.warn("COMMAND RECEIVED ---", command);

            if(command.ToCode != AppState.attendeeCode
                && !command.ToAll) {
               
                return;
            }

            console.log('Got Command ---- ', command);

            if (command.Code == "Kick") {
                await this.disconnectFromCall();
                this.$awn.alert("You have been kicked from the call.");
                this.emitExitRequest();
                return;
            } else if(command.Code == 'LowerHand') {
                this.emotion = null;
                this.emotions[AppState.attendeeCode] = null;
            }

            if(this.participantRole == 'listener') {
                // Listener-specific commands here.
                if (command.Code == "Promote") {
                    this.askToPromoteToSpeaker();
                }
            } else if(this.participantRole == 'host') {
                if(command.Code == 'StopScreenShare'
                    && this.sharingScreen) {
                    this.forceStopScreenShare();
                    this.$awn.info("The host has stopped your screen share.");
                } else if (command.Code == 'MuteMic') {
                    this.muted = true;
                    this.$awn.info("You have been muted.");
                    VoxeetSDK.conference.mute(VoxeetSDK.session.participant, true);
                } else if (command.Code == 'UnmuteMic') {
                    this.muted = false;
                    this.$awn.info("You have been unmuted.");
                    VoxeetSDK.conference.mute(VoxeetSDK.session.participant, false);
                } else if (command.Code == 'MuteCam') {
                    this.lastSelectedCameraId = this.selectedCameraId;
                    this.selectedCameraId = null;
                } else if (command.Code == "Demote") {
                    this.demoteToAudience();
                }
            } else if(command.Code == 'TransitionPage') {
                // Let's see if we can transition with just SignalR...
                // The command will be more for the Mixer app
                //this.transitionToNewPage(command.Message.PageCode);
            }

            // if (command.Code == 'ClearAllEmotion') {
            //     this.emotion = null;
            //     this.emotions = {};
            // } else if (command.Code == 'Emotion') {
            //     this.updateEmotionForAttendee(command.Message);
            // } 
        },

        async onRecordingPermUpdate(details) {
            if(!this.entityTypeCode) {
                return;
            }
            
            if(this.roomCode == details.RoomCode) {
                
                await this.getRecordingPermissions();
            }
        },

        async getRecordingPermissions() {
            if(!this.isUserModerator && !this.isUserAdmin) {
                return;
            }

            try {

                let routeParams = `${AppState.showCode}/${this.roomCode}`.toUpperCase();
                let result = await this.tryGet(`/api/recording/permissions/${routeParams}`);
                this.canRecord = result.Result.CanRecord;
            } catch(ex) {
                console.error("Could not fetch recording perms.", ex);
                this.canRecord = false;
            }
        },

        onVoxeetConferenceError(e) {
            if(e.name === 'WebSocketError' || e.name == 'PeerConnectionDisconnectedError') {
                console.error("Got a WEBSOCKET ERROR from VoxeetSDK. Are we disconnected?", {
                    details: e
                });

                this.voxeetWebSocketErrorReported = new Date();

            } else if(e.name === 'ServerError'){


                console.error("Got a SERVER_ERROR from VoxeetSDK", {
                    details: e
                });

                if(e.data && e.data.error_reason == 'screen_share_in_progress') {
                    console.warn("Error was due to screen share - not fatal");
                }
                else if(e.status == 404) {
                    console.warn("Error was due to conf not found - fatal (BYPASS END)");
                    // VoxeetSDK.conference.off('error', this.onVoxeetConferenceError);
                    // this.onVoxeetConferenceLeft(null, true);
                }
                // else if(e.status == 403) {
                //     this.onVoxeetConferenceLeft(null, true);
                // }
            } else {
                console.error('::::ERROR VoxeetSDK: ', {
                    details: e
                });
            }
        },

        onVoxeetConferenceJoined(e) {
            console.info(":::::::::: JOINED CONFERENCE :::::::::::");
            this.voxeetWebSocketErrorReported = null;
        },

        async onVoxeetConferenceLeft(e, force) {
            if(!this.conference) {
                return;
            }

            console.info(":::::::::: LEFT CONFERENCE :::::::::::");
            if(this.voxeetWebSocketErrorReported || force) {

                this.conference = null;

                try {
                    await VoxeetSDK.conference.leave();
                } catch {

                }

                try {
                    await VoxeetSDK.session.close();
                } catch {

                }

                this.rightSidebarComponent = null;
                this.participants = [];
                this.dolbyParticipants = [];
                this.mainStageParticipant = null;
                this.connectionMessage = null;

                this.emitExitRequest();
                this.$awn.confirm("You were disconnected from the call. Please check your internet connection and try again.", false, false, {
                    labels: {
                        confirm: "Disconnected"
                    }
                });
                console.warn(":::::::::: Voxeet Error Reported :::::::::::");
            }
        },

        onVoxeetRecordingStatusUpdated(recording, isRecording) {
            console.log('Got a recording update:', recording, isRecording);
            //this.recording = isRecording;
        },

        async initializeVoxeetDetails() {
            let tokenResult = await this.tryGet(`/api/webinar/token/${this.roomCode}`);
            let token = tokenResult.Result.Token;

            VoxeetSDK.initializeToken(token, async() => {
                let tokenResult = await this.tryGet(`/api/webinar/token/${this.roomCode}`);
                let token = tokenResult.Result.Token;
                return token;                
            });

            this.discussion = tokenResult.Result.Discussion;

            if(this.entityTypeCode) {
                await this.getRecordingPermissions();
            }

            this.isSessionLive = tokenResult.Result.IsOpen;
            this.isSessionFinished = tokenResult.Result.IsEnded;
            this.recording = tokenResult.Result.IsRecording;
            this.hidePresenterIfVideoOff = tokenResult.Result.HidePresenterIfVideoOff;
            this.showTimer = tokenResult.Result.ShowTimer;
            this.videoUrl = tokenResult.Result.VideoUrl;
            this.videoStartDateTime = tokenResult.Result.VideoStartDateTime;
            this.showVideo = tokenResult.Result.ShowVideo;
            this.nonModeratorIsParticipant = tokenResult.Result.NonModeratorIsParticipant;
            this.allowReactions = tokenResult.Result.AllowReactions;
            this.streamingRtmp = tokenResult.Result.IsStreaming;
            this.customLayoutCss = tokenResult.Result.CustomLayoutCss;

            if(this.isUserModerator || this.canBroadcastInCurrentRoom) {
                await this.prepareDeviceList();

                try {
                    this.handleAutoAnnounceUpdated();
                } catch {
                }
            }

            this.submitting = false;

            this.scheduleResize();

            if(!this.isUserModerator && !this.canBroadcastInCurrentRoom && this.isSessionLive) {
                await this.connectToCall();
            }

            this.$nextTick(async () => {
                await this.checkAndDisplayHlsVideo();
            })

            this.ensureTimerIsVisible();
        },

        async build() {
            VoxeetSDK.conference.on('streamAdded', this.onStreamUpdated);
            VoxeetSDK.conference.on('streamUpdated', this.onStreamUpdated);
            VoxeetSDK.conference.on('streamRemoved', this.onStreamRemoved);
            VoxeetSDK.conference.on('participantUpdated', this.onParticipantUpdated);
            VoxeetSDK.conference.on('participantAdded', this.onParticipantAdded);
            VoxeetSDK.conference.on('autoplayBlocked', this.onAutoplayBlocked);

            VoxeetSDK.conference.on('left', this.onVoxeetConferenceLeft);
            VoxeetSDK.conference.on('joined', this.onVoxeetConferenceJoined);
            VoxeetSDK.conference.on('error', this.onVoxeetConferenceError);

            VoxeetSDK.command.on('received', this.onVoxeetCommandReceived);

            VoxeetSDK.mediaDevice.on('deviceChanged', this.onVoxeetDeviceChanged);
            VoxeetSDK.recording.on('statusUpdated', this.onVoxeetRecordingStatusUpdated);

            try {
                let virtualBgUrl = localStorage.getItem('virtualbg');

                if(virtualBgUrl) {
                    this.settings.backgroundImage = virtualBgUrl;
                }

                let mirrorVideo = localStorage.getItem('mirrorVideo');

                if(mirrorVideo) {
                    this.settings.mirrorVideo = true;
                }
            } catch {
                this.settings.backgroundImage = null;
                this.settings.mirrorVideo = true;
            }

            await this.initializeVoxeetDetails();

            this.postAnalytics(
                AppState.attendeeCode,
                'EnterWebinar',
                this.roomCode,
                "Webinar",
                AppState.showCode);

        },

        ensureTimerIsVisible() {
            if(!this.isUserModerator
                || !this.indicatedStartTime 
                || !this.indicatedEndTime
                || !this.showTimer) {

                if(this.countdownTimer) {
                    clearInterval(this.countdownTimer);
                    this.countdownTimer = null;
                }
                return;
            }

            this.startTime = new Date(this.indicatedStartTime);
            this.endTime = new Date(this.indicatedEndTime);

            if(!this.countdownTimer) {
                this.updateCountdownTimer();
                this.countdownTimer = setInterval(this.updateCountdownTimer, 1000);
            }
        },

        updateCountdownTimer() {
            // new Date() - ((60000 * -2) + (3600000 * 10))
            let now = new Date();

            let comparisonDestination = now;
            let comparisonSource = this.startTime;

            let isPastStart = now > this.startTime;

            if(isPastStart) {
                comparisonDestination = this.endTime;
                comparisonSource = now;
            } 

            let diff = (comparisonDestination - comparisonSource) / 1000;

            this.isOverTime = isPastStart && diff < 0;            
            this.isBeforeStart = !isPastStart && diff < 0;

            if(diff < 0) {
                diff = -diff;
            }


            let hours = Math.floor(diff / 3600);
            let minutes = Math.floor(diff / 60) % 60;
            let seconds = Math.floor(diff % 60);

            if(hours > 24) {
                this.countdownDisplay = "24:00:00+";
            } else {
                this.countdownDisplay = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
            }
        },

        async onParticipantAdded(participant) {
            if(participant.status == 'Connected' 
                && this.canBroadcastInCurrentRoom
                && VoxeetSDK.session.participant
                && VoxeetSDK.session.participant.id != participant.id) {
            }
        },
        
        async onParticipantUpdated(participant) {

            if(participant.status == 'Left') {
                let existingParticipantDetails = this.participants.find(x => x.Participant.id == participant.id);

                let existingParticipantDetailsStage = 
                    this.mainStageParticipant
                        && this.mainStageParticipant.Participant.id == participant.id
                    ? this.mainStageParticipant
                    : null;

                if(existingParticipantDetails) {
                    let index = this.participants.indexOf(existingParticipantDetails);

                    this.participants.splice(index, 1);
                }

                if(existingParticipantDetailsStage) {
                    this.mainStageParticipant = null;
                }

                this.scheduleResize();

                if(participant.status == 'Connected' 
                    && this.canBroadcastInCurrentRoom
                    && VoxeetSDK.session.participant
                    && VoxeetSDK.session.participant.id != participant.id) {
                }
            }

            this.$emit('participant-update', {
                Code: participant.info.externalId,
                Status: participant.status
            });

            this.syncDolbyParticipantList();
        },

        syncDolbyParticipantList() {
            let currentParticipants = [...VoxeetSDK.conference.participants.values()].filter(x => x.status != 'Left');
            this.dolbyParticipants = currentParticipants;
        },

        async onStreamUpdated(participant, stream) {

            if(stream.type == 'ScreenShare') {
                
                let existingParticipantDetails = 
                    this.mainStageParticipant 
                    && this.mainStageParticipant.Participant.id == participant.id
                    ? this.mainStageParticipant
                    : null;

                if(!existingParticipantDetails) {
                    existingParticipantDetails = {
                        Participant: participant,
                        Stream: stream
                    };

                    this.mainStageParticipant = existingParticipantDetails;
                }

                if(stream.getVideoTracks().length) {

                    existingParticipantDetails.Stream = stream;

                } else {

                    existingParticipantDetails.Stream = null;

                }


            } else {
                let arrayToUpdate = this.participants;

                let existingParticipantDetails = arrayToUpdate.find(x => x.Participant.id == participant.id);

                if(!existingParticipantDetails) {
                    existingParticipantDetails = {
                        Participant: participant,
                        Stream: stream
                    };

                    arrayToUpdate.push(existingParticipantDetails);
                }

                if(stream.getVideoTracks().length) {

                    existingParticipantDetails.Stream = stream;

                } else {

                    existingParticipantDetails.Stream = null;

                }


            }


            this.scheduleResize();
        },

        async onStreamRemoved(participant, stream) {

            if(stream.type == 'ScreenShare') {
                this.mainStageParticipant = null;
            } else {

                let arrayToUpdate = this.participants;

                let existingParticipantDetails = arrayToUpdate.find(x => x.Participant.id == participant.id);

                if(!existingParticipantDetails) {
                    return;
                }

                existingParticipantDetails.stream = null;
            }

            this.scheduleResize();
        },        

        onWebinarControlMessage(message) {
            console.log(message);

            if (message.Type == 'ClearEmotions') {
                this.emotion = null;
                this.emotions = {};
            } else if (message.Type == 'Emotion') {
                this.updateEmotionForAttendee(message);
            } else if (message.Type == 'ToggleQualityBand') {
                this.changeQualityBand();
            } else if (message.Type == 'ProductionTtsMessage') {
                this.handleTtsMessage(message);
            } else if (message.Type == 'AutoAnnounceUpdated') {
                this.handleAutoAnnounceUpdated();
            } else if (message.Type == 'ActivePostChange' && message.Project) {
                let posts = this.$refs.chat?.posts;
                if(posts) {
                    let post = posts.find(x => x.Id == message.Code);
                    if(post) {
                        this.currentQaQuestionToProject = post;
                        this.scheduleResize();
                    } 
                }
            } else if (message.Type == 'ClearProjectedPost') {
                this.currentQaQuestionToProject = null;
                this.scheduleResize();
            } else if (message.Type == 'LayoutUpdated') {
                // TODO: On layout updated for the simplified area.
            }
        },

        async handleAutoAnnounceUpdated() {
            if(!this.isUserAdmin && !this.isUserModerator) {
                return;
            }

            let announcements = await this.tryGet(`/api/webinar/announcements/${AppState.showCode}/${this.roomCode}`);

            this.autoAnnouncements = announcements?.Result || [];

            if(!this.indicatedStartTime) {
                return;
            }

            let compareDate = new Date(this.indicatedStartTime).getTime();
            let now = new Date().getTime();

            this.autoAnnouncements.forEach(a => {
                a.Triggered = false;

                if(!a.AutoTrigger) {
                    return;
                }

                a.TriggerTime = new Date(this.indicatedStartTime).setSeconds(a.PlaybackTimeFromStartSeconds);
                if(now > a.TriggerTime) {
                    a.Triggered = true;
                }
            });

            this.autoAnnouncements = this.autoAnnouncements.sort((a, b) => a.TriggerTime - b.TriggerTime);

            if(!this.autoAnnouncementTimer) {
                this.autoAnnouncementTimer = setInterval(this.onAutoAnnouncementTimerFired, 1000);
            }
        },

        onAutoAnnouncementTimerFired() {
            if(this.autoAnnouncements.length == 0) {
                return;
            }

            let unTriggeredAnnouncements = this.autoAnnouncements.filter(x => x.AutoTrigger && !x.Triggered);

            if(unTriggeredAnnouncements.length == 0) {
                return;
            }

            let announcement = unTriggeredAnnouncements[0];
            if(!announcement) {
                return;
            }

            let compareDate = new Date();
            if(compareDate > announcement.TriggerTime) {
                announcement.Triggered = true;
                this.handleTtsMessage({
                    Speak: true,
                    Message: announcement.Message
                });
            }
        },

        handleTtsMessage(message) {
            if(!this.isUserAdmin && !this.isUserModerator) {
                return;
            }

            if(message.Speak) {

                if('speechSynthesis' in window) {
                    let msg = new SpeechSynthesisUtterance(message.Message);
                    msg.volume = message.Volume || 0.65;
                    window.speechSynthesis.speak(msg);
                } else {
                    this.$awn.info(message.Message);
                }

            } 
            
            if(message.Banner) {

                this.setBannerMessage(message.Message);

            }
        },

        setBannerMessage(message, timeout) {
            timeout = timeout ?? 7500;

            this.bannerMessage = message;
            if(this.bannerTimeout) {
                clearTimeout(this.bannerTimeout);
                this.bannerTimeout = null;
            }

            this.bannerTimeout = setTimeout(this.onBannerTimeout, timeout);
        },

        onBannerTimeout() {
            this.bannerMessage = null;
            this.bannerTimeout = null;
        },

        updateEmotionForAttendee(emotionDetails) {
            let attendeeCode = emotionDetails.FromCode;
            let emotion = emotionDetails.Emotion;

            this.emotions[attendeeCode] = emotion;
        },

        async askToPromoteToSpeaker() {
            let promise = new Promise(r => {
                this.$awn.confirm(
                    "You have been asked to join the speakers on stage. Is this OK?",
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('Reject');
                    },
                    {
                        labels: {
                            confirm: "Join Speakers on Stage?",
                            cancel: 'Cancel'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'Yes';
            
            if(!shouldGoLive) {
                return;
            }

            this.connectionMessage = "Joining, please wait...";

            await VoxeetSDK.conference.leave();

            this.conference = null;
            this.scheduleResize();

            await this.prepareDeviceList();

            this.participants = [];
            this.dolbyParticipants = [];
            this.mainStageParticipant = null;

            let resultOfJoin = await this.joinAsHost();

            if(resultOfJoin == 'ERR_JOIN_FAIL') {
                this.$awn.alert("Could not connect as a participant. Returning to the audience.");
                this.demoteToAudience();
            }

            this.connectionMessage = null;
        },

        async demoteToAudience() {
            this.connectionMessage = "Returning to audience, please wait...";

            await VoxeetSDK.conference.leave();

            this.scheduleResize();

            this.participants = [];
            this.dolbyParticipants = [];
            this.mainStageParticipant = null;

            await this.joinAsAudience();

            this.connectionMessage = null;

            this.$awn.info("You have been returned to the audience.");

            if(this.rightSidebarComponent == "micSettings") {
                this.rightSidebarComponent = 'none';
            }
        },

        onParticipantClick(attendee) {
            if(!this.isUserAdmin && !this.isUserModerator) {
                return;
            }

            this.attendeeToManage = attendee;
        },

        async sendConferenceCommandMessageToParticipant(participant, commandCode) {
            let attendeeCode = participant.info.externalId.split('_')[0];

            await this.sendConferenceCommandMessage(attendeeCode, commandCode);

            this.participantToManage = null;
        },

        forceClearEmotionForAttendee(attendeeCode) {
            this.emotions[attendeeCode] = null;
            this.sendConferenceCommandMessage(attendeeCode, 'LowerHand')
        },

        forceClearEmotionForAllAttendees() {
            let keys = Object.keys(this.emotions);

            keys.forEach(k => this.emotions[k] = null);
            this.emotion = null;

            this.sendConferenceCommandMessage(null, 'LowerHand')
        },

        async sendConferenceCommandMessage(
            attendeeCode, 
            commandCode,
            message) {

            if(!message) {
                message = {};
            }

            if(attendeeCode) {
                VoxeetSDK.command.send(JSON.stringify({
                    ToCode: attendeeCode,
                    Code: commandCode,
                    Message: message
                }));

                this.attendeeToManage = null;
            } else {
                VoxeetSDK.command.send(JSON.stringify({
                    ToAll: true,
                    Code: commandCode,
                    Message: message
                }));
            }
        },

        async onVoxeetDeviceChanged() {
            this.micList = await VoxeetSDK.mediaDevice.enumerateAudioInputDevices();       
            this.outputList = await VoxeetSDK.mediaDevice.enumerateAudioOutputDevices();
            this.camList = await VoxeetSDK.mediaDevice.enumerateVideoInputDevices();

            if(this.selectedMicId && !this.micList.find(x => x.deviceId == this.selectedMicId)) {
                this.selectedMicId = 'default';
            }

            if(this.selectedCameraId && !this.camList.find(x => x.deviceId == this.selectedCameraId)) {
                this.selectedCameraId = 'default';
            }

            if(this.selectedOutputId && !this.outputList.find(x => x.deviceId == this.selectedOutputId)) {
                this.selectedOutputId = 'default';
            }
        },

        async prepareDeviceList() {
            let deviceCount = [];

            try {
                deviceCount = await navigator.mediaDevices.enumerateDevices();
            } catch {
                deviceCount = [];
            }

            if(deviceCount.length == 0) {
                this.$awn.confirm("It looks like you do not have a webcam or microphone. Please attach one and try again.", false, false, {
                    labels: {
                        confirm: "No Devices Detected"
                    }
                });
            }

            let permissionPromptCheck = localStorage.getItem('webrtc-permission-prompt');
            let deviceNamesAccessible = deviceCount[0].label != '';

            if(!deviceNamesAccessible) {
                // Looks like we haven't shown the permission prompt. 
                localStorage.setItem('webrtc-permission-prompt', 'yes');

                // We only need to show the permission prompt if we don't have labels.
                // We only have labels if the user has granted permission in the past to use media.

                if(!deviceNamesAccessible) {
                    let promise = new Promise(r => {
                        this.$awn.confirm(
                            "For others to see and hear you, your browser will ask to use your camera and microphone.",
                            () => {
                                r();
                            },
                            false,
                            {
                                labels: {
                                    confirm: "Permissions needed"
                                }
                            }
                        )
                    });

                    await promise;
                }
            }

            try {
                let test = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: true
                });

                test.getTracks().forEach(t => t.stop());

            } catch {
                try {
                    let test = await navigator.mediaDevices.getUserMedia({
                        video: false,
                        audio: true
                    });

                    test.getTracks().forEach(t => t.stop());
                } catch(ex) {
                    console.error("Could not access devices", ex);

                    this.$awn.confirm(
                        "Tractus could not access your webcam and microphone. Please ensure your browser allows access.",
                        () => {},
                        false, {
                            labels: {
                                confirm: 'Permission Denied'
                            }
                        });
                    
                    this.emitExitRequest();
                    window.$bus.$emit('camera-permission-denied');
                    return;
                }
            }

            this.micList = await VoxeetSDK.mediaDevice.enumerateAudioInputDevices();       
            this.outputList = await VoxeetSDK.mediaDevice.enumerateAudioOutputDevices();
            this.camList = await VoxeetSDK.mediaDevice.enumerateVideoInputDevices();

            let camDeviceIdToSelect = null;
            let micDeviceIdToSelect = null;
            let outputDeviceIdToSelect = null;

            try {

                camDeviceIdToSelect = localStorage.getItem('last-cam-id');
                micDeviceIdToSelect = localStorage.getItem('last-mic-id');
                outputDeviceIdToSelect = localStorage.getItem('last-output-id');

                if(!camDeviceIdToSelect
                    || !this.camList.find(x => x.deviceId == camDeviceIdToSelect)) {
                    camDeviceIdToSelect = null;
                }

                if(!micDeviceIdToSelect
                    || !this.micList.find(x => x.deviceId == micDeviceIdToSelect)) {
                    micDeviceIdToSelect = null;
                }

                if(!outputDeviceIdToSelect
                    || !this.outputList.find(x => x.deviceId == outputDeviceIdToSelect)) {
                    outputDeviceIdToSelect = 'default';
                }

            } catch {

            }

            if(!camDeviceIdToSelect && this.camList.length > 0) {
                camDeviceIdToSelect = this.camList[0].deviceId;
            }

            if(!micDeviceIdToSelect && this.micList.length > 0) {
                micDeviceIdToSelect = this.micList[0].deviceId;
            }

            if(!outputDeviceIdToSelect) {
                outputDeviceIdToSelect = 'default';
            }

            this.selectedMicId = micDeviceIdToSelect;
            this.selectedCameraId = camDeviceIdToSelect;
            this.selectedOutputId = outputDeviceIdToSelect;

            console.log("Mic id, Cam ID, Output ID:", {
                micId: micDeviceIdToSelect,
                camId: camDeviceIdToSelect,
                outputId: outputDeviceIdToSelect
            });
        },

        async connectToCall(forceRole) {
            this.autoplayWarningHandled = false;

            await VoxeetSDK.session.open({
                name: AppState.attendee.DisplayName,
                externalId: `${AppState.attendeeCode}_${Math.random()}`,
                avatarUrl: AppState.attendee.ContactInfo.PhotoUrl || "https://event.tractus.ca/img/person.png"
            });


            if(this.previewCameraStream) {
                this.previewCameraStream.getTracks().forEach(t => t.stop());
                this.previewCameraStream = null;   
            }
            // BUG: If the joinAsHost fails, we have no way to prevent a softlock

            try {

                if(this.isUserModerator && !forceRole) {
                    await this.joinAsHost();
                } else if (this.canBroadcastInCurrentRoom && !forceRole) {
                    await this.prepareDeviceList();
                    await this.joinAsHost();
                } else {
                    await this.joinAsAudience();
                }

            } catch(ex) {
                console.error("Connect TO Call Fail -- ", ex);
                this.connectionMessage = null;
                this.participantRole = 'disconnected';
                try {
                    await VoxeetSDK.session.close();
                } catch {

                }
            }
        },

        async joinAsAudience() {
            this.connectionMessage = "Joining session...";

            this.conference = await VoxeetSDK.conference.create({
                alias: `${AppState.showCode}_${this.discussionCodeForRoom}`,
                params: {
                    liveRecording: true
                }
            });

            let result = await VoxeetSDK.conference.listen(toRaw(this.conference), {
            });

            console.log("RESULT OF LISTEN:", result);

            this.participantRole = 'listener';
            this.connectionMessage = null;
        },

        async joinAsHost() {
            this.connectionMessage = "Joining session...";

            let useRawFeed = false;

            try {
                useRawFeed = this.$route.query.useRawFeed == 'true';
            } catch {
                useRawFeed = false;
            }

            try {
                this.conference = await VoxeetSDK.conference.create({
                    alias: `${AppState.showCode}_${this.discussionCodeForRoom}`,
                    params: {
                        liveRecording: true,
                        dolbyVoice: true, //!useRawFeed,
                        videoCodec: 'H264'
                    }
                });


            } catch(ex) {
                console.error("Conference Create Fail -- ", ex);
            }

            try {

                let constraints = {
                    audio: this.selectedMicId 
                        ? {
                            deviceId: this.selectedMicId,
                            // echoCancellation: !useRawFeed,
                            // noiseSuppression: !useRawFeed,
                            // autoGainControl: !useRawFeed
                        } : false,
                    video: this.selectedCameraId
                        ? {
                            deviceId: this.selectedCameraId,
                            width: {
                                min: "320",
                                max: "1280"
                            },
                            height: {
                                min: "240",
                                max: "720"
                            }
                        } : false
                };

                await VoxeetSDK.conference.join(toRaw(this.conference), {
                    constraints: constraints,
                    dvwc: true// !useRawFeed
                    //simulcast: true
                });

                console.log(`Joined conference ${VoxeetSDK.conference.current.id}`);

                this.participantRole = 'host';

                this.$emit('connected');

                await this.updateOutputSelection();
            }
            catch (ex) {
                console.error("Conference Join Fail -- ", {exception: ex});
                try {
                    await VoxeetSDK.conference.leave();
                    this.participantRole = 'disconnected';
                } catch {

                }
                
                this.conference = null;

                this.$awn.alert("Could not connect to call. Please try again later.");
                throw ex;
            }

            this.syncDolbyParticipantList();

            this.connectionMessage = null;
        },

        async kickParticipant(participant) {
            await VoxeetSDK.conference.kick(participant);
        },

        async disconnectFromCall() {
            await this.leaveCall();
            this.participantRole = 'disconnected';
            this.autoplayWarningHandled = false;

            if(this.exitRequestIsImmediate) {
                this.emitExitRequest();
                return;
            }

            if(!this.isUserModerator) {
                return;
            }

            this.updateCameraSelection();
        },

        async leaveCall() {
            if(!this.conference) {
                try {
                    await VoxeetSDK.session.close();
                } catch {

                }

                return;
            }

            this.voxeetWebSocketErrorReported = null;
            this.connectionMessage = "Leaving session...";

            await VoxeetSDK.conference.leave();
            this.conference = null;

            await VoxeetSDK.session.close();

            this.scheduleResize();

            this.rightSidebarComponent = this.rightSidebarComponent;

            this.participants = [];
            this.dolbyParticipants = [];
            this.mainStageParticipant = null;

            this.connectionMessage = null;
        },

        scheduleResize() {
            this.$nextTick(() => {
                if(this.$refs.cameraContainer) {
                    this.refreshVideoSizes(this.$refs.cameraContainer, 'cameras');
                }

                if(this.$refs.stageContainer) {
                    this.refreshVideoSizes(this.$refs.stageContainer, 'stage');
                }
            });
        },

        _area(increment, 
              containerWidth, 
              containerHeight, 
              children, 
              ratio,
              marginBetweenVideos) {

            let i = 0;
            let w = 0;
            
            let h = increment * ratio + marginBetweenVideos;


            while (i < (children.length)) {
                if ((w + increment) > containerWidth) {
                    w = 0;
                    h = h + (increment * ratio) + marginBetweenVideos;
                }
                w = w + increment + marginBetweenVideos;
                i++;
            }
            if (h > containerHeight || increment > containerWidth) return false;
            else return increment;
        },

        refreshVideoSizes(container, mode) {
            let children = [...container.childNodes].filter(x => x.nodeName == 'DIV');

            if(children.length == 0) {
                return;
            }
            
            let marginBetweenVideos = this.isOnExtraSmallPlatform()
                ? 1
                : 4;

            marginBetweenVideos *= 2;

            let max = 0;
            let i = 1;
            let width = container.offsetWidth - marginBetweenVideos;
            let height = container.offsetHeight - marginBetweenVideos;
            let ratio = 9.0 / 16.0;

            if(children.length > 1 || mode == 'cameras' && this.mode == 'stage') {
                while(i < 5000) {
                    let area = this._area(
                        i, 
                        width, 
                        height, 
                        children, 
                        ratio,
                        marginBetweenVideos);

                    if(area === false) {
                        max = i - 1;
                        break;
                    }

                    i++;
                }

                max = max - marginBetweenVideos;

                for(let s = 0; s < children.length; s++) {
                    let child = children[s];

                    child.style.margin = `${marginBetweenVideos / 2}px`;

                    child.style.width = `${max}px`;
                    child.style.height = `${max * ratio}px`;
                    
                }
                

            } else {
                // Width == total width the container can consume
                // Height == total height the container can consume

                let child = children[0];

                child.style.margin = `${marginBetweenVideos}px`;

                child.style.width = `${width}px`;

                let maxHeight = Math.min(width * (9.0 / 16.0), height);
                child.style.height = `${maxHeight}px`;

                child.style.width = `${maxHeight * (16.0 / 9.0)}px`;
            }
            
        },        

        onMessageReceived() {
            if(this.rightSidebarComponent == 'chat') {
                return;
            }

            this.unreadMessages++;
        },

        async onLaunchPrivateProductionChat(){
            if(this.productionChatCode) {
                if(this.leftSidebarComponent != 'productionchat') {
                    this.toggleLeftSidebar('productionchat');
                }
                return;
            }

            var discussionAreaCode = await this.tryPost(
                `/api/webinar/launchchat/${AppState.showCode}/${this.roomCode}`, 
                JSON.stringify({}), 
                "application/json");

            if(discussionAreaCode && discussionAreaCode.Result){
                // 
                this.productionChatCode = discussionAreaCode.Result;
                if(this.leftSidebarComponent != 'productionchat') {
                    this.toggleLeftSidebar('productionchat');
                }
            }
            
        },

        toggleAdminPanel(panelCode) {
            this.adminPanelComponent = panelCode;
        },

        async askExitAndEndSession() {

            let isRecording = this.recording;


            let promise = new Promise(r => {
                this.$awn.confirm(
                    isRecording 
                        ? 'This will end the current webinar for all participants and end the recording. Are you sure you want to end this meeting?'
                        : 'This will end the current webinar for all participants. Are you sure you want to end the meeting?',
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('Reject');
                    },
                    {
                        labels: {
                            confirm: "Confirm Exit Meeting",
                            cancel: 'Cancel',
                            ok: 'Yes, Exit Meeting'
                        }
                    }
                )
            });

            let result = await promise;

            if(result != 'Yes') {
                return;
            }

            let state = this.getCurrentStateForPost();
            
            state.IsRecording = false;
            state.ShowVideo = false;
            state.VideoStartDateTime = null;
            state.IsStreaming = false;
            
            if(this.entityTypeCode == 'session') {
                state.IsOpen = false;
                state.IsEnded = true;
            }

            await this.postUpdateToWebinarAsync(state);

            if(isRecording) {
                VoxeetSDK.recording.stop();
            }

            await this.tryPost(
                `/api/webinar/opendoor/${AppState.showCode}/${this.entityTypeCode}/${this.entityCode}/Private`, 
                JSON.stringify({}), 
                "application/json");

            this.emitExitRequest();
        },

        onHelloGoodbye(message, typeCode, discussionCode) {
            if(discussionCode != this.discussionCodeForRoom) {
                return;
            }

            if(typeCode != 'Goodbye') {
                return;
            }

            this.emotions[message.FromCode] = null;
        },
    },

    data() {
        return {
            videoContainerWidth: 0,
            videoContainerHeight: 0,

            discussion: null,

            micList: [],
            outputList: [],
            camList: [],

            selectedCameraId: null,
            selectedMicId: null,
            selectedOutputId: 'default',

            participants: [],

            mainStageParticipant: null,
            
            rightSidebarComponent: 'none',
            leftSidebarComponent: 'none',

            settings: {
                processMode: 'standard',
                noiseReduction: 'high',
                backgroundFilter: '',
                echoCancellation: 'on',
                backgroundImage: null,
                mirrorVideo: true,
            }, 

            connectionMessage: null,

            tempPromotedToBroadcast: false,

            unreadMessages: 0,

            emotion: null,
            emotions: {},
            showEmotionPanel: false,

            showDebug: false,

            needInitialPermission: false,

            isSessionLive: false,
            isSessionFinished: false,
            hidePresenterIfVideoOff: false,
            nonModeratorIsParticipant: false,
            recording: false,
            streamingRtmp: false,

            canRecord: false,

            conference: null,
            sharingScreen: false,
            muted: false,

            debugPresenters: 0,
            mainStageDebugParticipant: false,

            autoplayWarningHandled: false,
            lastSelectedCameraId: null,

            dolbyParticipants: [],
            countdownTimer: null,
            countdownDisplay: null,

            startTime: null,
            endTime: null,
            isOverTime: false,
            isBeforeStart: false,
            showTimer: false,
            allowReactions: false,

            videoUrl: null,
            videoStartDateTime: null,
            showVideo: false,
            showFullSizeHlsContainer: false,

            apps: [],

            adminPanelComponent: null,

            popupLists: {
                showMicList: false,
                showCamList: false,
                showAdminList: false,
                showXsList: false
            },

            previewCameraStream: null,

            announcementDraft: {
                body: '',
                title: '',
                submitting: false,
            },

            sfx: {
                joined: null,
                left: null,
            },

            participantToManage: null,
            attendeeToManage: null,
            participantRole: 'disconnected',
            showExitPanel: false,

            voxeetWebSocketErrorReported: false,
            
            bannerMessage: null,
            bannerTimeout: null,

            autoAnnouncements: [],
            autoAnnouncementTimer: null,
            productionChatCode: null,

            currentQaQuestionToProject: null,
            bgUploadInProgresss: false,
            showVideoSettingsMenuPopup: false,

            customLayoutCss: '',
        };
    },

    created() {
        this.submitting = true;
    },

    mounted() {
        window.addEventListener('resize', this.scheduleResize);
        window.$bus.$on('webinar-update', this.onWebinarUpdated);
        window.$bus.$on('webinar-control-message', this.onWebinarControlMessage);
        window.$bus.$on('connected-to-signalr', this.onReconnectedToSignalR);
        window.$bus.$on('recording-perm-update', this.onRecordingPermUpdate);
        window.$bus.$on('Received-Hello-Goodbye', this.onHelloGoodbye);
        this.build();
        window.$bus.$emit('close-all-chats');
        window.$bus.$emit('set-sidebar-mode');
    },

    beforeUnmount() {

        if(this.previewCameraStream) {
            this.previewCameraStream.getTracks().forEach(t => t.stop());
            this.previewCameraStream = null;
        }


        try {
            if(this.countdownTimer) {
                clearInterval(this.countdownTimer);
            }

            VoxeetSDK.conference.removeListener('left', this.onVoxeetConferenceLeft);
        } catch {

        }

        try {
            if(this.autoAnnouncementTimer) {
                clearInterval(this.autoAnnouncementTimer);
            }
        } catch {

        }

        window.removeEventListener('resize', this.scheduleResize);

        this.leaveCall();

        this.postAnalytics(AppState.attendeeCode,'LeaveWebinar',this.roomCode,"Webinar",AppState.showCode);
        window.$bus.$off('webinar-update', this.onWebinarUpdated);
        window.$bus.$off('webinar-control-message', this.onWebinarControlMessage);
        window.$bus.$off('connected-to-signalr', this.onReconnectedToSignalR);
        window.$bus.$off('recording-perm-update', this.onRecordingPermUpdate);
        window.$bus.$off('Received-Hello-Goodbye', this.onHelloGoodbye);
        console.log(VoxeetSDK.conference);

        VoxeetSDK.conference.removeListener('streamAdded', this.onStreamUpdated);
        VoxeetSDK.conference.removeListener('streamUpdated', this.onStreamUpdated);
        VoxeetSDK.conference.removeListener('streamRemoved', this.onStreamRemoved);
        VoxeetSDK.conference.removeListener('participantUpdated', this.onParticipantUpdated);
        VoxeetSDK.conference.removeListener('participantAdded', this.onParticipantAdded);
        VoxeetSDK.conference.removeListener('autoplayBlocked', this.onAutoplayBlocked);
        VoxeetSDK.conference.removeListener('joined', this.onVoxeetConferenceJoined);
        VoxeetSDK.conference.removeListener('error', this.onVoxeetConferenceError);
        VoxeetSDK.mediaDevice.removeListener('deviceChanged', this.onVoxeetDeviceChanged);
        VoxeetSDK.recording.removeListener('statusUpdated', this.onVoxeetRecordingStatusUpdated);
        VoxeetSDK.command.removeListener('received', this.onVoxeetCommandReceived);
    }
}
</script>