<template>
    <div class="call-window-overlay"
         :class="[`call-${displayMode}`]">
        <audio preload="auto" class="ringtone" loop ref="ringtone" src="/incomingcall.mp3"></audio>
        <!-- <div class="debug-call-window-overlay" v-if="true">
            IL: {{inviteList}}<br>
            IC: {{incomingCall}} <br>
            RC: {{roomCode}} <br>
            NA: {{noAnswerTimeoutRef ? 'Yes' : 'No'}}
        </div> -->

        <div v-if="displayMode == 'incoming'">
            <h1>Incoming call from {{incomingCall.From}}</h1>

            <div class="answer-buttons">
                <button @click="acceptIncomingCall" 
                        class="icomoon start-call">
                    
                </button>
                <button class="icomoon end-call" @click="declineIncomingCall">
                    
                </button> 

            </div>
        </div>
        <webinar-view :roomCode="roomCode" 
                      :invitedAttendees="inviteList"
                      @exit-request="onWebinarExitRequested"
                      @connected="onConnectedToCall"
                      @participant-update="onParticipantUpdate"
                      ref="webinarView"
                      v-if="roomCode"
                      :exitRequestIsImmediate="true"
                      :suppressOnLeaveChatNotification="true"
                      v-show="displayMode == 'full'">
        </webinar-view>                
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'

export default {

    mixins: [
        Token,
        Common
    ],  

    data() {
        return {
            incomingCall: null,
            inviteList: [],
            acceptTimer: null,
            roomCode: null,
            noAnswerTimeoutRef: null,

            callWasAnswered: false,
        };
    },

    computed: {
        displayMode() {
            return this.incomingCall
                ? 'incoming'
                : this.roomCode
                    ? 'full'
                    : 'none';
        }
    },

    created() {
    },

    beforeUnmount() {
        window.$bus.$off('incoming-call', this.onIncomingCall);
        window.$bus.$off('cancel-incoming-call', this.cancelIncomingCall);
        window.$bus.$off('join-call', this.onRequestJoinCallFromDiscussion);
        window.$bus.$off('camera-permission-denied', this.onCameraPermissionDenied);
        
        if(this.acceptTimer) {
            clearTimeout(this.acceptTimer);
        }

        if(this.noAnswerTimeoutRef) {
            clearTimeout(this.noAnswerTimeoutRef);
        }

        this.callWasAnswered = false;

        this.$refs.ringtone.pause();
    },

    mounted() {
        window.$bus.$on('join-call', this.onRequestJoinCallFromDiscussion);
        window.$bus.$on('cancel-incoming-call', this.cancelIncomingCall);
        window.$bus.$on('incoming-call', this.onIncomingCall);
        window.$bus.$on('camera-permission-denied', this.onCameraPermissionDenied);
        this.build();

    },

    methods: {
        onWebinarExitRequested() {
            this.roomCode = null;

            if(this.acceptTimer) {
                this.clearTimeout(this.acceptTimer);
                this.acceptTimer = null;
            }

            if(this.noAnswerTimeoutRef) {
                this.clearTimeout(this.noAnswerTimeoutRef);
                this.noAnswerTimeoutRef = null;
            }

            this.callWasAnswered = false;
        },

        async onConnectedToCall() {
            if(!this.inviteList || this.inviteList.length == 0) {
                return;
            }

            try {

                await this.tryPost(`/api/call/invite/${this.roomCode}/p2p`, 
                    JSON.stringify(this.inviteList), 'application/json');

                this.noAnswerTimeoutRef = setTimeout(this.onNoAnswerTimeout, 20000);

                this.callWasAnswered = false;

            } catch(ex) {

            }

        },

        onParticipantUpdate(participant) {
            if(!this.inviteList || this.inviteList.length == 0) {
                return;
            }

            if(participant.Code.startsWith(AppState.attendeeCode)) {
                return;
            }

            this.callWasAnswered = true;

            let participantCode = participant.Code.split('_')[0];

            let index = this.inviteList.indexOf(participantCode);

            if(index != -1) {
                this.inviteList.splice(index, 1);
            }
        },

        onNoAnswerTimeout() {
            this.noAnswerTimeoutRef = null;

            if(!this.roomCode) {
                return;
            }

            if(this.callWasAnswered) {
                this.inviteList = [];
                return;
            }

            this.$awn.alert("There was no answer. Please try your call again later.");

            this.$refs.webinarView.disconnectFromCall();
        },

        onCameraPermissionDenied() {
            this.roomCode = null;
            this.incomingCall = null;
            this.inviteList = [];
        },

        async declineIncomingCall() {

            try {
                if(this.acceptTimer) {
                    this.clearTimeout(this.acceptTimer);
                    this.acceptTimer = null;
                }

            } catch {

            }

            try {
                this.$refs.ringtone.pause();
            } catch {

            }

            this.incomingCall = null;
            this.inviteList = [];
        },


        async build() {

        },

        cancelIncomingCall() {

        },

        async acceptIncomingCall() {
            clearTimeout(this.acceptTimer);
            this.acceptTimer = null;
            this.$refs.ringtone.pause();

            this.roomCode = this.incomingCall.RoomCode;
            this.incomingCall = null;
        },

        async onIncomingCall(incomingCall) {

            if(this.acceptTimer) {
                clearTimeout(this.acceptTimer);
            }

            this.$nextTick(() => {
                this.$refs.ringtone.pause();
                this.$refs.ringtone.currentTime = 0;
                this.$refs.ringtone.play();
            });
            this.incomingCall = incomingCall;

            this.acceptTimer = setTimeout(this.onCallNotAnswered, 20000);
        },

        onCallNotAnswered() {
            clearTimeout(this.acceptTimer);
            this.acceptTimer = null;
            this.$refs.ringtone.pause();
            if(this.displayMode != 'incoming') {
                return;
            }

            this.incomingCall = null;
        },

        async beforeEnterRoomDeviceCheck() {
            if(!navigator.mediaDevices) {
                this.$awn.confirm("Your browser does not support making calls. Please use a different device.", false, false, {
                    labels: {
                        confirm: "Browser Not Supported"
                    }
                });
                return false;   
            }

            return true;
        },

        async onRequestJoinCallFromDiscussion(e) {

            let beforeEnterRoomDeviceCheck = await this.beforeEnterRoomDeviceCheck();
            if(!beforeEnterRoomDeviceCheck) {
                console.log("FAILED CHECK.");
                return;
            }

            this.roomCode = e.roomCode;
            this.inviteList = e.inviteAttendeeCodes;
        },
    }
}
</script>