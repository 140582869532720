<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else class="showcase-floor page-container">
        <vue-title :title="title" 
                   :brand="true" />

        <div class="search-box-overlay">
            <div class="search-box">
                <span class="icomoon">
                    
                </span>
                <input placeholder="Search" 
                    v-debounce:150="updateSearchTerm"   
                    ref="searchInput"
                    type="text"/>
                <span class="icomoon clear-search"
                      @click="cancelSearch"
                      v-if="searchTerm">
                    
                </span>
            </div>
            <div v-if="filteredResults && filteredResults.length > 0"
                 class="results-list">
                 <page-item v-for="item in filteredResults"
                            :key="item"
                            :pageModel="item"
                            :class="`page-map-item-small`">
                </page-item>                 
            </div>            
        </div>

        <div class="showcase-floor-container"
             :class="`${editMode ? 'edit-mode': ''}`"
             ref="showcaseContainer" 
             v-debounce:300ms="onScrollShowcase"
             debounce-events="scroll">

            <div class="showcase-floor-sizer"
                :style="`min-width: ${showFloor.Width * zoomLevel * gridSize}px; min-height: ${showFloor.Height * zoomLevel * gridSize}px;`">

            </div>      
            <div class="showcase-floor-viewbox"
                 @mousedown="onMouseDownViewbox"
                 @mousemove="onMouseMoveViewbox"
                 @mouseup="onMouseUpViewbox"
                 ref="viewbox"
                :style="`transform: scale(${zoomLevel}); width: ${showFloor.Width * gridSize}px; height: ${showFloor.Height * gridSize}px;`">
        
                <template v-if="showFloor.Items">
                    <!-- TODO:
                         Emotion sending - emojis?
                    -->

                    <div v-for="item in showFloor.Items"
                         :key="item.Code"
                         :style="`left: ${item.X * gridSize}px; top: ${item.Y * gridSize}px; width: ${item.Width * gridSize}px; height: ${item.Height * gridSize}px;`"
                         @mousemove="onMouseMoveItem($event, item)"
                         @mousedown="onMouseDownItem($event, item)"
                         @mouseup="onMouseUpItem($event, item)"
                         @dblclick.stop="onMouseDoubleClickItem($event, item)"
                         class="bounding-box-item"
                         :class="`${editor.selectedItem == item ? 'selected' : ''}`">
                        
                        <div v-if="item.ChatCode && item.VoiceArea && insideArea == item"
                             class="voice-enter-controls fade-in-on-create">

                            <span>
                                {{ item.Name }}
                            </span>
                            <select v-if="voice.conferenceId"
                                    v-model="voice.selectedMicId">
                                <option v-for="mic in voice.micList"
                                        :key="mic.deviceId"
                                        :value="mic.deviceId">
                                    {{ mic.label }}
                                </option>
                            </select>
                            <button @click="updateMuteStatus()"
                                    v-if="voice.conferenceId">
                                {{ voice.muted ? 'Unmute' : 'Mute' }}
                            </button>
                            <button class="icomoon join-call"
                                    @click="joinVoiceArea()">
                                
                            </button>
                            <button class="icomoon join-call"
                                    v-if="voice.conferenceId"
                                    @click="leaveVoiceArea()">
                                X
                            </button>
                        </div>
                        <div v-if="item.Type == 'DIV'"
                             class="div-item"
                             v-html="item.Content">

                        </div>
                        <div v-else-if="item.Type == 'IMAGE'"
                             class="img-item">
                            <img-g :src="item.Content"></img-g>

                            <button class="icomoon"
                                    v-if="item.TargetUrl"
                                    @mouseup.stop.prevent="onOpenTargetForItem(item)">
                                
                            </button>
                        </div>

                        <page-item v-else-if="item.Type == 'VENDOR' && item.Content"
                                   @mousedown.stop
                                   :pageModel="items.vendors[item.Content]"
                                   :class="`page-map-item-${item.Size ? item.Size : 'regular'}`"
                                   @need-load="findElement(item)">

                        </page-item>
                        <div v-else>
                            Empty Item
                        </div>

                        <div class="resize-thumb"
                             v-if="editor.selectedItem == item && !item.Locked"
                             @mousedown.stop="onMouseDownThumb($event, item)"
                             @mousemove.stop="onMouseMoveThumb($event, item)"
                             @mouseup.stop="onMouseUpThumb($event, item)">

                        </div>
                    </div>
                </template>

                <div class="item-placeholder"
                     v-if="editMode && editor.mode == 'create'"
                     :style="`left: ${editor.startX * gridSize}px; top: ${editor.startY * gridSize}px; width: ${Math.max(Math.abs(editor.endX - editor.startX), 1) * gridSize}px; height: ${Math.max(Math.abs(editor.endY - editor.startY), 1) * gridSize}px;`">

                </div>

                <template v-if="showFloor.Mode == 'Multiplayer'">
                    <div class="user-circle me"
                        v-show="!editMode"
                        @mousedown.stop
                        :style="`left: ${myPosition.x * gridSize}px; top: ${myPosition.y * gridSize}px;`">
                        <img-g :src="myLogo"
                            :fallbackSrc="'/img/person.png'">
                        </img-g>
                        <div class="user-circle-name">
                            Me
                        </div>
                    </div>

                    <div class="user-circle"
                        v-for="player in players"
                        v-show="!editMode && player.Connections > 0"
                        :key="player.Code"
                        @mousedown.stop="onPlayerClick(player)"
                        :style="`left: ${player.X * gridSize}px; top: ${player.Y * gridSize}px;`">
                        <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${player.Code}/profile`"
                            :fallbackSrc="'/img/person.png'">
                        </img-g>
                        <div class="user-circle-name">
                            {{ player.DisplayName }}
                        </div>
                    </div>
                </template>

            </div>

        </div>

        <div class="editor-popup"
             v-if="editMode">
            <select v-model="editor.selectedItem">
                <option :value="null">
                    (Select Object)
                </option>
                <option v-for="item in showFloor.Items"
                        :value="item">
                    {{ item.Type }} -- {{ item.Code }}
                </option>
            </select>
            <div v-if="!editor.selectedItem">
                <label>
                    Map Width
                    <input type="number" min="20" step="1" v-model="showFloor.Width">
                </label>
                <label>
                    Map Height
                    <input type="number" min="20" step="1" v-model="showFloor.Height">
                </label>
                <label>
                    Map mode

                    <select v-model="showFloor.Mode">
                        <option value="StaticMap">
                            Static Map
                        </option>
                        <option value="Multiplayer">
                            Interactive
                        </option>
                    </select>
                </label>
                <label>
                    Chat Room for Map
                    <select v-model="showFloor.ChatCode">
                        <option :value="null">
                                (No Chat/Room)
                        </option>
                        <option v-for="item in resources['CHATS']"
                                :value="`${item.Code}`"
                                :key="item.Code">
                            {{ item.EditorName }}
                        </option>
                    </select>    
                </label>
            </div>
            <div v-else>
                <div class="button-group">
                    <button @click="moveToIndex(1, true)">
                        ⏫
                    </button>
                    <button @click="moveToIndex(1, false)">
                        ⬆️
                    </button>
                    <button @click="moveToIndex(-1, false)">
                        ⬇️
                    </button>
                    <button @click="moveToIndex(0, true)">
                        ⏬
                    </button>
                </div>
                <label>
                    Item type
                    <select v-model="editor.selectedItem.Type">
                        <option value="IMAGE">
                            Image
                        </option>
                        <option value="DIV">
                            Shape
                        </option>
                        <option value="VENDOR">
                            Page
                        </option>
                    </select>
                </label>
                <label>
                    <input type="checkbox" v-model="editor.selectedItem.Locked">
                    Locked
                </label>

                <label>
                    Name
                    <input v-model="editor.selectedItem.Name">
                </label>
                <label>
                    Description
                    <textarea v-model="editor.selectedItem.Description"
                              style="height: 64px">
                    </textarea>
                </label>
                <label>
                    <input type="checkbox" v-model="editor.selectedItem.ShowOnHover">
                    Show Card on Hover
                </label>

                <label>
                    Width
                    <input type="number" min="1" step="1" v-model="editor.selectedItem.Width">
                </label>
                <label>
                    Height
                    <input type="number" min="1" step="1" v-model="editor.selectedItem.Height">
                </label>

                <div v-if="editor.selectedItem.Type == 'IMAGE' || editor.selectedItem.Type == 'DIV'">
                    <label>
                        Chat Room for Tile
                        <select v-model="editor.selectedItem.ChatCode">
                            <option :value="null">
                                (No Chat/Room)
                            </option>
                            <option v-for="item in resources['CHATS']"
                                    :value="`${item.Code}`"
                                    :key="item.Code">
                                {{ item.EditorName }}
                            </option>
                        </select>    
                    </label>

                    <label v-if="editor.selectedItem.ChatCode">
                        <input type="checkbox" v-model="editor.selectedItem.VoiceArea">
                        Voice Tile
                    </label>
                </div>

                <div v-if="editor.selectedItem.Type == 'IMAGE'">
                    <label>
                        Image URL
                        <input v-model="editor.selectedItem.Content">
                    </label>
                    <label>
                        Target URL
                        <input v-model="editor.selectedItem.TargetUrl">
                    </label>
                    <label>
                        <input type="checkbox" v-model="editor.selectedItem.SpawnZone">
                        Spawn Zone
                    </label>

                    <div class="img-changer">
                        <img :src="editor.selectedItem.Content"
                            v-if="editor.selectedItem.Content"
                            @click="requestAddPhoto"
                            class="responsive profile-photo">
                        <div v-else class="blank-image icomoon" @click="requestAddPhoto">
                            
                        </div>
                    </div>

                </div>
                <div v-else-if="editor.selectedItem.Type == 'DIV'">
                    <textarea v-model="editor.selectedItem.Content">
                    </textarea>                    
                    <label>
                        <input type="checkbox" v-model="editor.selectedItem.SpawnZone">
                        Spawn Zone
                    </label>
                </div>
                <div v-else-if="editor.selectedItem.Type == 'VENDOR'">
                    <select v-if="editMode"
                        v-model="editor.selectedItem.Content">
                        <option v-for="item in resources['VENDORS']"
                                :value="`${item.Code}`">
                            {{ item.DisplayName }}
                        </option>
                    </select>                    
                    <select v-if="editMode"
                        v-model="editor.selectedItem.Size">
                        <option value="normal">
                            Normal Size
                        </option>
                        <option value="small">
                            Small Size
                        </option>
                    </select>                    
                </div>
            </div>
        </div>

        <div class="showcase-controls">
            <select v-model="zoomLevel" :disabled="editMode">
                <option :value="0.75">
                    75%
                </option>
                <option :value="1.0">
                    100%
                </option>
                <option :value="1.25">
                    125%
                </option>
                <option :value="1.5">
                    150%
                </option>
            </select>

            <button @click="centerView"
                    class="icomoon">
                
            </button>
            <button @click="respawn"
                    class="icomoon"
                    v-if="showFloor.Mode == 'Multiplayer'">
                
            </button>

            <button v-if="canEdit"
                    @click="toggleEditMode">
                {{ editMode ? 'Exit Edit' : "Edit" }}
            </button>

        </div>      
        
        <file-upload ref="uploader" 
                    style="display: none;"
                    :acceptFileType="'.jpg'"
                    :assetType="`map_${mapCode}`"
                    v-if="editMode"
                    :showCode="showCode"
                    @uploadSuccess="onFileUploaded"
                    @uploadFailed="onUploadFailed"
                    @uploadStarted="onUploadStarted"  />       
        <router-view class="right-pane"></router-view> 
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import { nextTick, toRaw } from 'vue';

import { AppState } from './appstate';
import VoxeetSDK from '@voxeet/voxeet-web-sdk'

import VoiceArea from './voice.area.js'

export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    computed:{
        showCode() {
            return AppState.showCode;
        },

        mapCode() {
            return AppState.attendee.Show.Sections[this.sectionCode].Code;
        },  

        myLogo() {
            return `${this.getApiUrl()}/api/asset/${AppState.showCode}/attendee/${AppState.attendeeCode}/profile`;
        },

        canEdit() {
            return AppState.attendee.RoleCode == "Administrator";
        },

        gridSize() {
            return 32;
        },

        title() {
            return AppState.attendee.Show.Sections[this.sectionCode]?.Title;
        },

        filteredResults(){
            if(!this.items) {
                return [];
            }

            let toReturn = [];

            if(this.searchTerm && this.searchTerm.length > 0) {
                let searchTest = new RegExp(this.searchTerm, 'i');
                toReturn = Object.values(this.items.vendors)

                toReturn = toReturn.filter(x => searchTest.test(x.Vendor.Name));
            }

            return toReturn;
        }
    },

    watch: {
        sectionCode(to, from) {
            this.build();
        },

        'voice.selectedMicId'() {
            this.updateMicSelection();
        }, 
    },

    methods: {
        cancelSearch() {
            this.$refs.searchInput.value = '';
            this.searchInput = '';
            this.updateSearchTerm();
        },

        onPlayerClick(player) {
            this.$router.push({
                name: 'attendeeDetailsMap',
                params: {
                    code: player.Code
                }
            })
        },

        getClickPosition(e) {
            let bounds = this.$refs.viewbox.getBoundingClientRect();

            let mouseX = e.clientX;
            let mouseY = e.clientY;

            let edgeX = bounds.x;
            let edgeY = bounds.y;

            let toReturn = {
                x: mouseX - edgeX,
                y: mouseY - edgeY,
                gridX: 0,
                gridY: 0
            };

            toReturn.gridX = Math.floor(Math.floor(toReturn.x / this.gridSize) / this.zoomLevel);
            toReturn.gridY = Math.floor(Math.floor(toReturn.y / this.gridSize) / this.zoomLevel);

            //console.log("Bound rect: ", this.$refs.viewbox.getBoundingClientRect());
            //console.log("Mouse: ", e);
            //console.log(toReturn);
            return toReturn;
        }, 

        requestAddPhoto() {
            this.$refs.uploader.requestChooseFile();
        },

        onUploadFailed() {
            this.uploadInProgress = false;
            this.$awn.alert('Could not upload your file. Try again later.');
        },

        onUploadStarted() {
            this.uploadInProgress = true;
        },

        onFileUploaded(file) {
            this.uploadInProgress = false;
            this.editor.selectedItem.Content = file;
        },

        async toggleEditMode() {
            if(!this.editMode) {
                this.editMode = true;
                this.zoomLevel = 1.0;

                document.addEventListener("keydown", this.onItemKeyUp);
            } else {
                this.editMode = false;

                document.removeEventListener("keydown", this.onItemKeyUp);
                await this.tryPost(`/api/map`, JSON.stringify(this.showFloor), "application/json");
                this._onMouseUpEditMode();
            }
        },

        centerView(immediate) {
            let container = this.$refs.showcaseContainer;
            console.log("Ref container", container)

            if(!container) {
                return;
            }

            let scrollMode = immediate ? 'instant' : 'smooth'

            if(this.showFloor.Mode == "Multiplayer") {
                // Center on the player
                
                let xPosition = this.myPosition.x + 1;
                let yPosition = this.myPosition.y + 1;

                let xPixel = xPosition * this.gridSize;
                let yPixel = yPosition * this.gridSize;

                /*

                    we want left to be xPosition - half the width of the viewport

                */

                let xNew = Math.max(0, xPixel - (container.offsetWidth / 2.0)); 
                let yNew = Math.max(0, yPixel - (container.offsetHeight / 2.0)); 

                console.log(xPosition, yPosition, xPixel, yPixel, xNew, yNew, container.offsetWidth, container.offsetHeight)

                container.scrollTo({
                    top: yNew,
                    left: xNew,
                    behavior: scrollMode
                })

            } else {

                if(window.localStorage) {
                    let lastPos = localStorage.getItem(`mappos:${this.mapCode}`);

                    let centered = false;

                    if(lastPos) {
                        try {
                            lastPos = JSON.parse(lastPos);
                            container.scrollTo({
                                top: lastPos.Y,
                                left: lastPos.X,
                                behavior: scrollMode
                            });

                            centered = true;
                        } catch {
                            centered = false;
                        }
                    }

                    if(!centered) {
                        let width = (container.scrollWidth / 2.0) - (container.offsetWidth / 2.0);
                        let height = (container.scrollHeight / 2.0) - (container.offsetHeight / 2.0);
                        console.log(container.offsetWidth, container.offsetHeight, container.scrollWidth, container.scrollHeight)
                        container.scrollTo({
                            top: height,
                            left: width,
                            behavior: scrollMode
                        })
                    }
                }


            }

            
            // this.myPosition.x = Math.max((this.showFloor.Width / 2) - 1, 0);
            // this.myPosition.y = Math.max((this.showFloor.Height / 2) - 1, 0);
            // this.myPosition.destinationX = this.myPosition.x;
            // this.myPosition.destinationY = this.myPosition.y;
        },

        getGridPositionFromPixelPosition(e) {
            let x = Math.floor(e.offsetX / this.gridSize);
            let y = Math.floor(e.offsetY / this.gridSize);

            //console.log(`Grid position for ${e.offsetX}, ${e.offsetY}: ${x}, ${y}`);

            return {
                x: x,
                y: y,
                offsetX: e.offsetX,
                offsetY: e.offsetY
            };
        },

        onMouseDownThumb(e, item) {
            if(!this.editMode) {
                return;
            }


            let pixelXPosition = (item.X * this.gridSize) + (item.Width * this.gridSize) + e.offsetX;
            let pixelYPosition = (item.Y * this.gridSize) + (item.Height * this.gridSize) + e.offsetY;

            let gridPosition = this.getClickPosition(e);

            this.editor.startX = gridPosition.gridX;
            this.editor.startY = gridPosition.gridY;

            this.editor.mode = "preresize";
        },

        onMouseMoveThumb(e, item) {
            //console.log(e.offsetX, e.offsetY);

            let pixelXPosition = (item.X * this.gridSize) + (item.Width * this.gridSize) + e.offsetX;
            let pixelYPosition = (item.Y * this.gridSize) + (item.Height * this.gridSize) + e.offsetY;

            // let gridPosition = this.getGridPositionFromPixelPosition({
            //     offsetX: pixelXPosition,
            //     offsetY: pixelYPosition
            // });
            let gridPosition = this.getClickPosition(e);

            this._onMouseMoveEditMode(gridPosition);
        },

        onMouseUpThumb() {
            this._onMouseUpEditMode();
        },

        onMouseDoubleClickItem(e, item) {
            if(!this.editMode) {
                return;
            }

            if(!item.Locked) {
                return;
            }

            this.editor.selectedItem = item;
        },

        onMouseDownItem(e, item) {
            let pixelXPosition = (item.X * this.gridSize) + e.offsetX;
            let pixelYPosition = (item.Y * this.gridSize) + e.offsetY;

            let gridPosition = this.getClickPosition(e);

            if(this.editMode) {

                if(!item.Locked) { 
                    e.stopPropagation();

                    this.editor.selectedItem = item;
                    this.editor.startX = gridPosition.gridX;
                    this.editor.startY = gridPosition.gridY;

                    this.editor.mode = "premove";
                }

            } else {
                // Execute move.
                if(e.target.nodeName == "BUTTON") {
                    return;
                }
                if(e.target.nodeName == "SELECT") {
                    return;
                }
                if(e.target.nodeName == "OPTION") {
                    return;
                }

                gridPosition = this.getClickPosition(e);
                this._executeMove(gridPosition);
            }

        },

        onMouseMoveItem(e, item) {
            if(!this.editMode || item.Locked) {
                return;
            }

            e.stopPropagation();

            let pixelXPosition = (item.X * this.gridSize) + e.offsetX;
            let pixelYPosition = (item.Y * this.gridSize) + e.offsetY;

            let gridPosition = this.getClickPosition(e);

            this._onMouseMoveEditMode(gridPosition);


        },

        onOpenTargetForItem(item) {
            if(item.TargetUrl) {
                // Handle navigation
                let url = item.TargetUrl.replace("{{entityTypeCode}}", 'MAP')
                    .replace("{{entityCode}}", this.mapCode)
                    .replace('INTERAL:::');

                let iframeParams = {
                    EntityTypeCode: this.entityType,
                    EntityCode: this.entityCode,
                    Title: item.Name,
                    Url: url
                };

                if(item.TargetUrl.startsWith('INTERNAL:::')) {
                    window.$bus.$emit('iframe-open-request', iframeParams);
                } else {
                    window.$bus.$emit('link-open-request', iframeParams);
                }
            }
        },

        onMouseUpItem(e, item) {
            if(!this.editMode) {
                return;
            }

            if(item.Locked) {
                return;
            }

            e.stopPropagation();
            this._onMouseUpEditMode();
        },

        onItemKeyUp(e) {
            if(!this.editMode) {
                return;
            } 

            if(e.target?.nodeName == "TEXTAREA" || e.target?.nodeName == "INPUT") {
                return;
            }

            if(this.editor.selectedItem && !this.editor.selectedItem.Locked) {
                if(e.key == "Delete") {

                    let index = this.showFloor.Items.indexOf(this.editor.selectedItem);
                    if(index != -1) {
                        this.showFloor.Items.splice(index, 1);
                    }
                } else if(e.key == 'ArrowRight') {

                    this.editor.selectedItem.X += 1;   
                    if(this.editor.sselectedItem.X < 0) {
                        this.editor.sselectedItem.X = 0;
                    }

                } else if(e.key == 'ArrowLeft') {

                    this.editor.selectedItem.X -= 1;   

                } else if(e.key == 'ArrowUp') {
                    this.editor.selectedItem.Y -= 1;   
                    if(this.editor.sselectedItem.Y < 0) {
                        this.editor.sselectedItem.Y = 0;
                    }

                } else if(e.key == 'ArrowDown') {
                    this.editor.selectedItem.Y += 1;   
                }
            }

            //console.log(e);
        },

        _executeMove(gridPosition) {
            // Execute a move
            if(this.showFloor.Mode != 'Multiplayer') {
                return;
            }

            let destX = gridPosition.gridX
            if(destX < 0) {
                destX = 0;
            }

            let destY = gridPosition.gridY
            if(destY < 0) {
                destY = 0;
            }

            if(destX == this.myPosition.x && destY == this.myPosition.y) {
                return;
            }

            this.myPosition.x = destX;
            this.myPosition.y = destY;

            if(window.sessionStorage) {
                window.sessionStorage.setItem(`mc_${this.showFloor.Code}`, `${this.myPosition.x}:${this.myPosition.y}`);
            }

            this.tryPut(`/api/map/${this.showFloor.Code}/pos/${destX}/${destY}`);

            this.centerView()

            this.checkIntersections();

            this.updateVoicePosition();
        },

        async updateVoicePosition() {
            if(!this.insideArea) {
                return;
            }

            let x = this.myPosition.x;
            let y = this.myPosition.y;

            let vX = x - this.insideArea.X;
            let vY = y - this.insideArea.Y;

            this.myPosition.vX = vX;
            this.myPosition.vY = vY;

            if(!VoxeetSDK.conference.current) {
                return;
            }

            // TODO: Figure out why configuring the spatial environment doesn't work
            // TODO: Add a "scale" factor to the map?
            // TODO: Add a better UI for connect/disconnect/manage
            // TODO: Indicator for who's talking
            // TODO: Indicator of who's connected

            VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, {
                x: this.myPosition.vX / 8.0,
                y: 0,
                z: this.myPosition.vY / 8.0
            });
        },

        checkIntersections() {
            if(VoxeetSDK.conference.current) {
                return;
            }

            let intersectingItem = this.showFloor.Items.find(item => {

                let x = this.myPosition.x;
                let y = this.myPosition.y;

                let xMin = item.X - 1;
                let yMin = item.Y - 1;
                let xMax = item.X + item.Width + 1;
                let yMax = item.Y + item.Height + 1;

                if(x < xMin
                    || y < yMin
                    || x > xMax
                    || y > yMax ) {
                    return false;
                }

                return true;
            });

            this.insideArea = intersectingItem;
        },

        onMouseDownViewbox(e) {
            if(e.target.nodeName == "BUTTON") {
                return;
            }

            if(e.button != 0) {
                return;
            }

            let gridPosition = this.getClickPosition(e);

            if(this.editMode) {

                if(this.editor.selectedItem) {
                    this.editor.selectedItem = null;
                    this.editor.mode = null;
                }

                if(!this.editor.mode) {

                    this.editor.startX = gridPosition.gridX;
                    this.editor.startY = gridPosition.gridY;

                    this.editor.mode = 'precreate';

                } 

            } else {

                this._executeMove(gridPosition);

            }
        },

        onMouseMoveViewbox(e) {
            if(!this.editMode) {
                return;
            }

            let gridPosition = this.getClickPosition(e);

            this._onMouseMoveEditMode(gridPosition, null);
        },


        onMouseUpViewbox(e) {
            if(!this.editMode) {
                return;
            }

            this._onMouseUpEditMode();
        },

        _onMouseMoveEditMode(gridPosition) {

            if(this.editor.selectedItem) {
                let item = this.editor.selectedItem;

                if(this.editor.mode == 'premove'
                    || this.editor.mode == 'preresize') {

                    let deltaX = this.editor.startX - gridPosition.gridX;
                    let deltaY = this.editor.startY - gridPosition.gridY;
                    //console.log(deltaX, deltaY)

                    if(deltaX != 0 || deltaY != 0) {
                        this.editor.mode = this.editor.mode.replace("pre", '');
                    }

                    this.editor.startW = item.Width;
                    this.editor.startH = item.Height;
                }             

                if(this.editor.mode == 'move') {

                    item.X = gridPosition.gridX;
                    item.Y = gridPosition.gridY; 
                    this.editor.endX = gridPosition.gridX;
                    this.editor.endY = gridPosition.gridY;

                }            

                if(this.editor.mode == 'resize') {

                    this.editor.endX = gridPosition.gridX;
                    this.editor.endY = gridPosition.gridY;

                    let deltaX = this.editor.endX - this.editor.startX;
                    let deltaY = this.editor.endY - this.editor.startY;

                    //console.log("DX, DY", deltaX, deltaY)

                    item.Width = Math.max(this.editor.startW + deltaX, 1);
                    item.Height = Math.max(this.editor.startH + deltaY, 1);

                }
            }

            if(this.editor.selectedItem) {
                return;
            }


            let x = gridPosition.gridX;
            let y = gridPosition.gridY;

            if(this.editor.mode == 'precreate') {

                let deltaX = this.editor.startX - x;
                let deltaY = this.editor.startY - y;

                if(deltaX != 0 || deltaY != 0) {
                    this.editor.mode = 'create';
                }


            } else if(this.editor.mode == 'create') {

                let startX = this.editor.startX;
                let startY = this.editor.startY;

                if(x < startX) {
                    this.editor.startX = x;
                    this.editor.endX = startX;
                } else if(x > startX) {
                    this.editor.startX = startX;
                    this.editor.endX = x;
                }

                if(y < startY) {
                    this.editor.startY = y;
                    this.editor.endY = startY;
                } else if(y > startY) {
                    this.editor.startY = startY;
                    this.editor.endY = y;
                }

            }
        },

        _onMouseUpEditMode() {
            if(!this.editor.selectedItem && this.editor.mode == 'create') {

                let newItem = {
                    Code: this.uuidv4(),
                    Type: '',
                    X: this.editor.startX,
                    Y: this.editor.startY,
                    Width: Math.max(Math.abs(this.editor.endX - this.editor.startX), 1),
                    Height: Math.max(Math.abs(this.editor.endY - this.editor.startY), 1),
                    Description: '',
                    TargetUrl: '',
                    Content: '',
                    Size: 'small',
                    SpawnZone: false,
                };

                this.showFloor.Items.push(newItem);

                this.editor.selectedItem = newItem;
            }

            this.editor.mode = null;
            this.editor.startX = null;
            this.editor.startY = null;
            this.editor.endX = null;
            this.editor.endY = null;
            this.editor.startW = null;
            this.editor.startH = null;
        },

        moveToIndex(index, absolute) {
            if(!this.editor.selectedItem) {
                return;
            }

            let currentIndex = this.showFloor.Items.indexOf(this.editor.selectedItem);
            if(currentIndex == -1) {
                return;
            }

            console.log(currentIndex, this.editor.selectedItem.Code);

            
            if(absolute) {
                this.showFloor.Items.splice(currentIndex, 1);

                if(index == 0) {
                    this.showFloor.Items.splice(0, 0, this.editor.selectedItem);
                } else {
                    this.showFloor.Items.push(this.editor.selectedItem);
                }
            } else {

                /*
                    0 1 2 3 4

                    0 1   3 4
                    0 1 3 4
                    0 1 3 2 4
                */

                let spliceIndex = currentIndex + index;

                console.log(spliceIndex);

                if(spliceIndex < 0 && index < 0) {
                    return;
                }

                if(spliceIndex >= this.showFloor.Items.length && index > 0) {
                    return;
                }

                this.showFloor.Items.splice(currentIndex, 1);
                this.showFloor.Items.splice(spliceIndex, 0, this.editor.selectedItem);
            }
        },

        async findElement(item) {
            if(!item.Type || !item.Content) {
                return;
            }

            if(!this.items['vendors'][item.Content]) {
                item._Loading = true;

                let foundItem = await this.tryGet(`/api/vendor/${AppState.showCode}/${item.Content}`);

                if(foundItem.Result) {
                    this.items['vendors'][item.Content] = foundItem.Result;
                }

                item._Loading = false;

                return foundItem?.Result;
            } else {
                return this.items['vendors'][item.Content];
            }
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        requestJoinSpace(item) {
            window.$bus.$emit('join-call', {
                roomCode: item.ChatCode,
                inviteList: [],
            })
        },

        async reloadMap(positions) {
            var section = AppState.attendee.Show.Sections[this.sectionCode];

            let map = await this.tryGet(`/api/map/${section.Code}?p=${positions}`);

            if(map?.Result == null) {
                map = {}
                map.Result = {
                    Code: section.Code,
                    Width: 20,
                    Height: 20,
                    Items: []
                };
            }

            this.showFloor = map.Result;

            if(positions && map.Result.Players && this.showFloor.Mode == 'Multiplayer') {
                this.players = [...map.Result.Players.filter(x => x.Code != AppState.attendeeCode)];

                let myLastPosition = map.Result.Players.find(x => x.Code == AppState.attendeeCode);

                if(myLastPosition) {

                    this.myPosition.x = myLastPosition.X;
                    this.myPosition.y = myLastPosition.Y;

                    this.tryPut(`/api/map/${this.showFloor.Code}/pos/${this.myPosition.x}/${this.myPosition.y}`);

                } else {

                    this.respawn();

                }

                this.checkIntersections();
            }
        },

        respawn() {
            let spawnZones = this.showFloor.Items.filter(x => x.SpawnZone);
            if(spawnZones.length > 0) {
                
                let spawnIndex = Math.floor(Math.random() * spawnZones.length);
                let spawnZone = spawnZones[spawnIndex];

                console.log(spawnZone)

                let minX = Math.max(spawnZone.X - 3, 0);
                let maxX = Math.min(this.showFloor.Width - 2, spawnZone.X + spawnZone.Width + 3);

                let minY = Math.max(spawnZone.Y - 3, 0);
                let maxY = Math.min(this.showFloor.Height - 2, spawnZone.Y + spawnZone.Height + 3);

                let spawnPositionX = this.getRndInteger(minX, maxX);
                let spawnPositionY = this.getRndInteger(minY, maxY);
                
                this.myPosition.x = spawnPositionX;
                this.myPosition.y = spawnPositionY;

            } else {
                console.log("No spawn zones found.", this.showFloor.Items);

                this.myPosition.x = Math.floor(this.showFloor.Width / 2);
                this.myPosition.y = Math.floor(this.showFloor.Height / 2);

            }

            this.tryPut(`/api/map/${this.showFloor.Code}/pos/${this.myPosition.x}/${this.myPosition.y}`);

        },

        getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1) ) + min;
        },

        async build() {
            this.submitting = true;
            this.searchTerm = "";

            var section = AppState.attendee.Show.Sections[this.sectionCode];

            this.postAnalytics(
                AppState.attendee.Code, 
                `Map:${this.sectionCode}`,
                this.sectionCode,
                section.SectionTypeCode, 
                AppState.attendee.Show.Code);


            try {
                if(AppState.attendee.RoleCode == 'Administrator') {
                    let resources = await this.tryGet(`/api/resources/${AppState.showCode}`);

                    if(resources?.Result != null) {
                        this.resources = resources.Result;
                    }
                }
            } catch {
                this.resources = [];
            }

            await this.reloadMap(true);

            this.submitting = false;

            nextTick(() => this.centerView(true));
           
            this.setupSignalRConnections();
        },

        onScrollShowcase(e) {
            console.log(e)
            if(this.showFloor.Mode == 'Multiplayer') {
                return;
            }

            if(window.localStorage) {
                localStorage.setItem(`mappos:${this.mapCode}`, JSON.stringify({X: this.$refs.showcaseContainer.scrollLeft, Y: this.$refs.showcaseContainer.scrollTop}));
            }
        },

        setupSignalRConnections() {
            if(window.$signalRConnection.notInitialized) {
                // Not quite ready yet...
                return;
            }

            window.$signalRConnection.invoke(
                'JoinArea', 
                this.showFloor.Code, 
                AppState.attendee.Code, 
                AppState.attendee.Show.Code, {
                    X: this.myPosition.x,
                    Y: this.myPosition.y
                });
        },

        onWebinarLiveChanged(args) {
            if(args.EntityTypeCode != "VENDOR"
                && args.EntityTypeCode != "SESSION") {
                return;
            }
        },

        onEntityUpdated(details) {
            if(!details) {
                return;
            }

            if(details.EntityTypeCode == "MAP" && details.EntityCode == this.showFloor.Code) {
                this.reloadMap(false);
            }

            if(details.EntityTypeCode == "VENDOR" && details.EntityCode) {
                if(this.items.vendors[details.EntityCode]) {
                    this.items.vendors[details.EntityCode] = null;
                }
            } 
        },

        async onMoveMapRemoteReceived(typeCode, message) {
            let attendeeCode = message.FromCode;

            if(attendeeCode == AppState.attendeeCode) {
                return;
            }

            let player = this.players.find(x => x.Code == attendeeCode);

            if(!player) {
                player = {
                    Code: message.FromCode,
                    DisplayName: "",
                    Connections: 1,
                    PhotoUrl: null,
                    X: message.Message?.X,
                    Y: message.Message?.Y,
                };


                this.players.push(player);
            } 

            if(player.Connections == 0 || !player.Connections) {
                player.Connections = 1;
            }

            if(!player.DisplayName) {
                try {
                    let attendee = await this.tryGet(`/api/attendees/${message.FromCode}`);

                    if(attendee && attendee.Result) {
                        player.DisplayName = attendee.Result.Attendee.DisplayName;
                    }
                } catch(ex) {
                    console.error("ERROR ON FETCH ATTENDEE NAME,", ex);
                }
            }

            player.X = message.Message.X;
            player.Y = message.Message.Y;
        },

        onHelloGoodbye(message, type, mapCode) {
            if(!mapCode
                || this.showFloor.Code != mapCode) {
                return;
            }

            if(message.FromCode == AppState.attendee.Code) {
                return;
            }

            let player = this.players.find(x => x.Code == message.FromCode);
            console.log(message);
            if(type == 'Hello') {
                if(player) {
                    player.Connections++;
                    player.DisplayName = message.DisplayName;
                } else {
                    player = {
                        Code: message.FromCode,
                        DisplayName: message.DisplayName,
                        Connections: 1,
                        PhotoUrl: null,
                        X: message.Message?.X,
                        Y: message.Message?.Y,
                    };
                    this.players.push(player);
                }
            } else if (type == 'Goodbye') {

                if(player) {
                    player.Connections--;

                    if(player.Connections <= 0) {
                        let index = this.players.indexOf(player);
                        if(index >= 0) {
                            this.players.splice(index, 1);
                        }
                    }
                }
            }
        },

        async joinVoiceArea() {

            let tokenResult = await this.tryGet(`/api/webinar/token/${this.insideArea.ChatCode}`);
            let token = tokenResult.Result.Token;

            VoxeetSDK.initializeToken(token, async() => {
                let tokenResult = await this.tryGet(`/api/webinar/token/${this.insideArea.ChatCode}`);
                let token = tokenResult.Result.Token;
                return token;                
            });

            await this.prepareDeviceList();

            await VoxeetSDK.session.open({
                name: AppState.attendee.DisplayName,
                externalId: `${AppState.attendeeCode}_${Math.random()}`,
                avatarUrl: AppState.attendee.ContactInfo.PhotoUrl || "https://event.tractus.ca/img/person.png"
            });

            try {
                let conference = await VoxeetSDK.conference.create({
                    alias: `${AppState.showCode}_${this.insideArea.ChatCode}`,
                    params: {
                        liveRecording: false,
                        dolbyVoice: true, //!useRawFeed,
                        audioOnly: true,
                        spatialAudioStyle: 'shared'
                    }
                });

                await VoxeetSDK.conference.join(conference, {
                    constraints: {
                        audio: this.voice.selectedMicId || 'default',
                        video: false
                    },
                    spatialAudio: true,
                    dvwc: true// !useRawFeed
                    //simulcast: true
                });

                this.voice.conferenceId = conference.id;

                this.updateVoicePosition();


            } catch(ex) {
                console.error("Failed to connect to session: ", ex);
                this.voice.conferenceId = null;

                try {
                    await VoxeetSDK.conference.leave();
                    await VoxeetSDK.session.close();
                } catch {

                }
            }
            
        },

        async _set() {
            const scale = { x: 1, y: 1, z: 1 };
                const forward = { x: 0, y: 0, z: 1 };
                const up = { x: 0, y: 1, z: 0 };
                const right = { x: 1, y: 0, z: 0 };
                VoxeetSDK.conference.setSpatialDirection(scale, forward, up, right);
        },

        async prepareDeviceList() {
            let deviceCount = await navigator.mediaDevices.enumerateDevices();
            let deviceNamesAccessible = deviceCount[0].label != '';

            if(!deviceNamesAccessible) {
                try {
                    let test = await navigator.mediaDevices.getUserMedia({
                        video: false,
                        audio: true
                    });

                    test.getTracks().forEach(t => t.stop());
                } catch (ex) {

                }
            }


            this.voice.micList = await VoxeetSDK.mediaDevice.enumerateAudioInputDevices();       

            let micDeviceIdToSelect = 'default';
            try {
                micDeviceIdToSelect = localStorage.getItem('last-mic-id');
            } catch {
                micDeviceIdToSelect = 'default';
            }
            
            micDeviceIdToSelect = micDeviceIdToSelect || 'default';

            this.voice.selectedMicId = micDeviceIdToSelect;
        },

        async leaveVoiceArea() {
            await VoxeetSDK.conference.leave();
            await VoxeetSDK.session.close();
            this.voice.conferenceId = null;
        },

        async updateMuteStatus() {
            this.voice.muted = !this.voice.muted;
            VoxeetSDK.conference.mute(VoxeetSDK.session.participant, this.voice.muted);
        },

        async updateMicSelection() {
            if(!VoxeetSDK.conference.current) {
                return;
            }

            console.log('Updating mic selection: ', this.voice.selectedMicId);

            try {
                await VoxeetSDK.mediaDevice.selectAudioInput(this.voice.selectedMicId);

            } catch(ex) {
                console.error(ex);
            }
        }
    },

    data() {
        return {
            resources: [],
            players: [],
            insideArea: null,

            searchTerm: '',
            submitting: false,
            showFilters: false,
            showFloor: {},
            zoomLevel: 1.0,

            myPosition: {
                x: 0,
                y: 0,
                destinationX: 0,
                destinationY: 0,
                reachedX: false,
                reachedY: false,
                vX: 0,
                vY: 0,

                
            },

            editMode: false,
            prospectiveModelCode: '',

            editor: {
                startX: null,
                startY: null,
                endX: null,
                endY: null,

                startW: null,
                startH: null,

                mode: null,
                selectedItem: null,
            },

            items: {
                vendors: {},
            },

            voice: {
                conferenceId: null,
                micList: [],
                selectedMicId: 'default',
                muted: false,
            },
        }
    },
    
    beforeUnmount() {
        window.$bus.$off('entity-updated',this.onEntityUpdated);
        window.$bus.$off('WebinarLiveChange', this.onWebinarLiveChanged);
        window.$bus.$off('connected-to-signalr', this.setupSignalRConnections);
        window.$signalRConnection.invoke('LeaveDiscussionArea', this.showFloor.Code, AppState.attendee.Code, AppState.attendee.Show.Code);
        window.$bus.$off('Received-Hello-Goodbye', this.onHelloGoodbye);
        window.$bus.$off('MoveMap', this.onMoveMapRemoteReceived);

        if(VoxeetSDK.conference.current) {
            this.leaveVoiceArea();
        }
    },

    created: function() {
        this.build();
        window.$bus.$on('entity-updated',this.onEntityUpdated);
        window.$bus.$on('WebinarLiveChange', this.onWebinarLiveChanged);
        window.$bus.$on('connected-to-signalr', this.setupSignalRConnections);
        window.$bus.$on('Received-Hello-Goodbye', this.onHelloGoodbye);
        window.$bus.$on('MoveMap', this.onMoveMapRemoteReceived);
    },
}
</script>