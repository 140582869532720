<template>
    <div class="webinar-settings-panel">
        <div class="header-actions">
            <div class="title"
                 title="Polls">
                    Production Announcements
            </div>
            
            <button @click="emitExitRequest"
                class="header-buttons icomoon">
                                        
            </button>
        </div>

        <div>
            <h3>Send TTS Message</h3>

            <input v-model="ttsMessageToSend"
                    placeholder="Message text">

            <div class="button-group">
                <button @click="sendTtsMessage(ttsMessageToSend, true, false)">
                    Speak
                </button>
                <button @click="sendTtsMessage(ttsMessageToSend, false, true)">
                    Banner
                </button>
                <button @click="sendTtsMessage(ttsMessageToSend, true, true)">
                    Both
                </button>
            </div>

        </div>
        <div>
            <h3>Template Messages</h3>
            <div v-for="t in templateAnnouncements"
                 :key="t"
                 class="auto-announcement-item">
                
                <input readonly :Value="t">

                <div class="button-group">
                    <button @click="sendTtsMessage(t, true, false)">
                        Speak
                    </button>
                    <button @click="sendTtsMessage(t, false, true)">
                        Banner
                    </button>
                    <button @click="sendTtsMessage(t, true, true)">
                        Both
                    </button>
                </div>
            </div>
        </div>  

        <div>
            <h3>Scheduled Messages</h3>
            <div class="button-group">
                <button @click="addScheduledMessage">
                    Add...
                </button>
                <button @click="saveAnnouncements">
                    Save
                </button>
            </div>
            
            <div v-for="item in editAnnouncements"
                 :key="item.Code"
                 @click="selectedAnnouncement = item"
                 class="auto-announcement-item">

                <template v-if="item == selectedAnnouncement">
                   
                    <input v-model="item.Message"
                           placeholder="Message...">
                    

                    <label>
                        <input type="checkbox"
                               v-model="item.AutoTrigger">
                        Auto-Trigger
                    </label>
                    <select v-model="item.PlaybackTimeFromStartSeconds"
                            v-if="indicatedStartTime && item.AutoTrigger">
                        <option v-for="option in playbackTimeOptions"
                                :key="option.Value"
                                :value="option.Value">
                            {{option.Message}}
                        </option>
                    </select>

                    <button @click.stop="selectedAnnouncement = null">
                        Finish
                    </button>
                </template>
                <template v-else>
                    <div>
                        {{item.Message || "Empty message"}}
                    </div>
                    <div v-if="item.AutoTrigger">
                        {{ getAutoTriggerMessage(item) }}
                    </div>
                    <div class="button-group">
                        <button @click.stop="sendTtsMessage(item.Message, true, false)">
                            Speak
                        </button>
                        <button @click.stop="sendTtsMessage(item.Message, false, true)">
                            Banner
                        </button>
                        <button @click.stop="sendTtsMessage(item.Message, true, true)">
                            Both
                        </button>
                    </div>
                </template>
            </div>

        </div>

    </div>
</template>
<script>
import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'

export default {
    watch: {
        autoAnnouncements() {
            this.build()
        }
    },

    mixins: [
        Common,
        Token,
    ],

    data() {
        return {
            templateAnnouncements: [
                "Five minutes to start",
                "Two minutes to start",
                "One minute to start",
                "Fifteen seconds to start",
                "On the air - start",
                "Ten minutes to end",
                "Five minutes to end",
                "Wrap up",
                "Overtime - wrap up",
            ],

            editAnnouncements: [],
            ttsMessageToSend: '',
            selectedAnnouncement: null,
            playbackTimeOptions: [],
        }
    },

    created() {
        let earliestDate = new Date(this.indicatedStartTime);
        let endDateTime = new Date(this.indicatedEndTime);

        let durationSeconds = (endDateTime - earliestDate) / 1000;
        let fiveMinutesAfterEndSeconds = durationSeconds + 300;

        for(let i = -900; i < 0; i += 15) {

            let message = Math.abs(i) <= 180 
                ? `${Math.abs(i)} seconds before start`
                : `${Math.abs(i / 60)} minutes before start`;

            this.playbackTimeOptions.push({
                Value: i, 
                Message: message
            })

            if(i < -180) {
                i += 45;
            } else if(i < -60) {
                i += 15;
            }
        }

        this.playbackTimeOptions.push({
            Value: 0,
            Message: "Session Start"
        });

        for(let i = 300; i < durationSeconds; i += 30) {
            let message = Math.abs(durationSeconds - i) <= 150 
                ? `${(durationSeconds - i)} seconds remain`
                : `${((durationSeconds - i) / 60)} minutes remain`;

            this.playbackTimeOptions.push({
                Value: i, 
                Message: message
            })

            if(i < durationSeconds - 600) {
                i += 270;
            } 
        } 

        this.playbackTimeOptions.push({
            Value: durationSeconds,
            Message: "Session End"
        });

        for(let i = durationSeconds + 60; i < durationSeconds + 300; i += 60) {

            let message = `${Math.abs(((durationSeconds - i) / 60))} minutes overtime`;

            this.playbackTimeOptions.push({
                Value: i, 
                Message: message
            })
        }
    },

    mounted() {
        this.build();
    },

    props: [
        'isUserAdmin',
        'isUserModerator',
        'roomCode',
        'attendee',
        'indicatedEndTime',
        'autoAnnouncements',
        'indicatedStartTime'
    ],

    methods: {
        getAutoTriggerMessage(item) {
            return this.playbackTimeOptions.find(x => x.Value == item.PlaybackTimeFromStartSeconds)?.Message;
        },

        addScheduledMessage() {
            let message = {
                ShowCode: AppState.showCode,
                RoomCode: this.roomCode,
                Message: '',
                Code: this.getAzureCompatibleTimeStamp(),
                PlaybackTimeFromStartSeconds: 0,
                AutoTrigger: false,
            };

            this.editAnnouncements.splice(0, 0, message);

            this.selectedAnnouncement = message;
        },

        removeAnnouncement(announcement) {
            let index = this.editAnnouncements.find(x => x.Code == announcement.Code);

            if(index == -1) {
                return;
            }

            let found = this.editAnnouncements[index];

            if(found == this.selectedAnnouncement) {
                this.selectedAnnouncement = null;
            }

            this.editAnnouncements.splice(index, 1);
        },

        async saveAnnouncements() {
            let uri = `/api/webinar/announcements/${AppState.showCode}/${this.roomCode}`;

            await this.tryPost(
                uri, 
                JSON.stringify(this.editAnnouncements), 
                "application/json");            
        },

        async sendTtsMessage(message, isSpeak, isBanner) {
            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.showCode,
                FromCode: AppState.attendeeCode,
                TypeCode: 'WebinarControl',
                DiscussionAreaCode: this.roomCode,
                DID: this.getDeviceId(),
                Message: {
                    Type: 'ProductionTtsMessage',
                    Message: message,
                    Speak: isSpeak,
                    Banner: isBanner
                },
            });            

        },

        async build() {
            this.editAnnouncements = [...this.autoAnnouncements];
            this.selectedAnnouncement = null;
        },

        emitExitRequest() {
            this.$emit('closerequest');
        }
    }
}
</script>