<template>
    <div v-if="submitting || !session"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else-if="showWebinarView"
         class="webinar-page">
        <vue-title :title="session.Name" :brand="true"></vue-title>
        
        <webinar-view :roomCode="session.DiscussionCode" 
                      
                      :indicatedStartTime="session.SessionStartDateTime"
                      :indicatedEndTime="session.SessionEndDateTime"
                      :entityTypeCode="'SESSION'"
                      :entityCode="session.Code"
                      :entityName="session.Name"
                      :webinarStatus="session.DiscussionWebinarStatus"
                      @exit-request="onWebinarExitRequested"
                      ref="webinarView">

            <template v-slot:holding>
                <div class="webinar-session-holding">

                    <div class="webinar-title">
                        <h1>{{session.Name}}</h1>
                        <h2>{{$filters.moment(session.SessionStartDateTime, 'LLLL')}}</h2>
                    </div>

                    <div class="webinar-holding-website" v-if="session.PrimaryMedia.Uri">
                        <iframe :src="session.PrimaryMedia.Uri">

                        </iframe>
                    </div>

                    <div class="webinar-holding-website" v-else-if="session.PrimaryMedia.ThumbnailUri">
                        <img-g :src="session.PrimaryMedia.ThumbnailUri" />
                    </div>

                </div>
            </template>
        </webinar-view>
    </div>
    <div class="session-detail-page detail-page-container"
         v-else>
        <div v-if="session"
             class="detail-page">
            <vue-title :title="session.Name" :brand="true"></vue-title>

            <media-container v-if="session.PrimaryMedia"
                             :media="session.PrimaryMedia"
                             :entityTypeCode="'SESSION'"
                             :entityCode="session.Code">

            </media-container>

            <div class="detail-page-summary">
                <div class="detail-page-summary-upper">
                    <div class="detail-page-bio">
                        <h1 class="page-name">
                            <div class="online-icon online" 
                                 v-if="session.DiscussionWebinarStatus == 'PublicManual'"></div>
                            {{session.Name}}
                        </h1>

                        <p v-if="session.Location">
                            <span class="icomoon"></span>{{session.Location}}
                        </p>
                        <p>
                            <span class="icomoon"></span>
                            <span>{{$filters.moment(session.SessionStartDateTime, 'LLLL')}}</span>
                        </p>
                        <ul class="horizontal-menu">
                            <button v-if="allowRehearsal"
                                    type="button"
                                    @click="switchWebinarModeForce">
                                <span class="icomoon"></span><span>Join Room</span>
                            </button>

                            <button type="button"
                                    class="icomoon"
                                    :class="[sessionModel.CanEditFavorite ? '' : 'button-noninteractive-fill']"
                                    @click="onToggleFavorite">
                                {{sessionModel.IsFavorite ? '' : ''}}
                            </button>

                            <router-link :to="{name: 'DiscussionForSession', params: { discussionCode: session.DiscussionCode }}"
                                        v-if="session.DiscussionCode"
                                        class="button-outline btn">
                                <span class="icomoon"></span><span>Show Chat</span>
                            </router-link>

                            <button type="button"
                               @click="showCalendarDialog = true">
                                <span class='icomoon'></span><span>Add to Calendar...</span>
                            </button>
                        </ul>

                    </div>
                </div>

                <p v-if="session.ContactInfo.Bio"
                   class="detail-page-bio-text"
                   v-html="biography">
                </p>

                <contact-info-list :contactInfo="session.ContactInfo.ContactInfo"                               
                                   entityType="Session"
                                   :entityCode="code">
                </contact-info-list>
            </div>
 
        </div>

        <router-view class="right-pane" 
                     :typeCode="'Session'" 
                     :code="code"></router-view>

        <div class="iframe-overlay iframe-show"
             v-if="showCalendarDialog"
             @click="dismissAddToCalendar">
            <div>
                <div class="page-side-panel"
                     @click.stop>
                    <div class="iframe-controls">
                        <span>Add to Calendar...</span>

                        <button class="icomoon" 
                                type="button"
                                @click="dismissAddToCalendar">
                            
                        </button>                        
                    </div> 

                    <div>
                        <ul class="vertical-menu">
                            <a class="action-link"
                               :href="outlook365Url"
                               target="_blank"
                               @click="dismissAddToCalendar">
                                <span class='icomoon'></span><span>Add to Outlook (Office 365)</span>
                            </a>

                            <a class="action-link"
                               :href="googleCalendarUrl"
                               target="_blank"
                               @click="dismissAddToCalendar">
                                <span class='icomoon'></span><span>Add to Google Calendar</span>
                            </a>

                            <a class="action-link"
                               @click="downloadCalendarFile">
                                <span class='icomoon'></span><span>Add to Apple Calendar</span>
                            </a>

                            <a class="action-link"
                                @click="downloadCalendarFile">
                                <span class='icomoon'></span><span>Add to Calendar (.ics)</span>
                            </a>
                        </ul>
                    </div>

                </div>
            </div>
        </div>                     
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import moment from 'moment'
import webinarView from './webinar.view.vue';

import { AppState } from './appstate';

export default {
    components: { webinarView },
    props: ['code'],

    mixins: [
        Token,
        Common
    ],   

    watch: {
        code(to, from) {
            this.build();
        }
    },

    computed: {

        session() {
            return this.sessionModel 
                ? this.sessionModel.Session
                : null;
        },

        // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
        googleCalendarUrl() {
            const root = 'https://calendar.google.com/calendar/render';

            const startTimeGoogle = moment(this.session.SessionStartDateTime)
                .format("YYYYMMDDTHHmmssZ");

            const endTimeGoogle = moment(this.session.SessionEndDateTime)
                .format("YYYYMMDDTHHmmssZ");

            const sessionDt = encodeURIComponent(`${startTimeGoogle}/${endTimeGoogle}`);


            const subject = encodeURIComponent(this.session.Name);

            const location = encodeURIComponent(this.session.Location || "Tractus (Online)");

            const body = encodeURIComponent(`<a href='${this.deepLinkUrl}'>Click Here to Join</a>`);

            const url = `${root}?action=TEMPLATE&dates=${sessionDt}&text=${subject}&location=${location}&details=${body}`;
            return url;            
        },

        outlook365Url() {
            return this.getMicrosoftOutlookUrl(true);
        },

        deepLinkUrl() {
            if(this.session.CustomDeepLinkUrl) {
                return this.session.CustomDeepLinkUrl;
            }

            let path = window.location.href.replace(window.location.search, '');
            let loginGlob = btoa(`${AppState.attendee.Code}:${AppState.attendee.SignInPin}:${AppState.show.Code}`);

            let url = `${path}?l=${loginGlob}`;

            return url;
        },

        showWebinarView() {
            return !this.submitting
                && this.session
                && this.session.DiscussionWebinarStatus == "Public"
                    || (this.allowRehearsal && this.forceWebinarMode)
        },

        iFrameSrc() {
            // session.PrimaryMedia.Uri

            if(this.session.PrimaryMedia 
                && this.session.PrimaryMedia.MediaType == 'LiveWebSource') {

                let link = this.session.PrimaryMedia.Uri;

                if(link.indexOf('{{showCode}}') != -1) {
                    link = link.replace('{{showCode}}', AppState.show.Code);
                } 

                if(link.indexOf('{{attendeeCode}}') != -1) {
                    link = link.replace('{{attendeeCode}}', AppState.attendee.Code);
                }

                if(link.indexOf('{{entityTypeCode}}') != -1) {
                    link = link.replace('{{entityTypeCode}}', 'SESSION');
                }

                if(link.indexOf('{{entityCode}}') != -1) {
                    link = link.replace('{{entityCode}}', this.code);
                }

                return link;
            }
        },

        biography() {
            return this.markdown(this.session?.ContactInfo?.Bio);
        },
    },

    async beforeRouteLeave(to, from, next) {
        let isInWebinar = this.session
            && this.session.DiscussionWebinarStatus == 'Public'
            || this.forceWebinarMode;

        let isInZoom = this.session
            && this.session.PrimaryMedia
            && this.session.PrimaryMedia.MediaType == 'LiveWebSource'
            && this.session.PrimaryMedia.Uri.indexOf('zoom.html') != -1;

        let webinarView = this.$refs.webinarView;

        if(webinarView) {

            let isConferenceLive = webinarView.conference;
            let isVideoLive = webinarView.videoUrl && webinarView.videoStartDateTime && !webinarView.isSessionLive;

            isInWebinar = isConferenceLive || isVideoLive;
        }

        if(isInWebinar || isInZoom) {

            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you want to leave this session?",
                    () => {
                        r("Ok");
                    },
                    () => {
                        r("Cancel");
                    },
                    {
                        labels: {
                            confirm: "Confirm Exit",
                            confirmOk: "Yes",
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;
            if(result == 'Cancel') {
                next(false);
                return;
            }

            this.postExitAnalytics();
            next();


        } else {
            this.postExitAnalytics();
            next();
        }
        
    },

    methods: {
        getMicrosoftOutlookUrl(outlook365) {
            const root = outlook365 
                ? 'https://outlook.office.com/calendar/0/deeplink/compose'
                : 'https://outlook.live.com/calendar/0/deeplink/compose';

            const path = encodeURIComponent("/calendar/action/compose");

            const startDt = encodeURIComponent(this.session.SessionStartDateTime);

            const endDt = encodeURIComponent(this.session.SessionEndDateTime);

            const subject = encodeURIComponent(this.session.Name);

            const location = encodeURIComponent(this.session.Location || "Tractus (Online)");

            const body = encodeURIComponent(`<a href='${this.deepLinkUrl}'>Click Here to Join</a>`);

            const url = `${root}?path=${path}&rru=addevent&startdt=${startDt}&enddt=${endDt}&subject=${subject}&location=${location}&body=${body}`;

            return url;
        },

        dismissAddToCalendar() {
            this.showCalendarDialog = false;
        },

        async sendCalendarFileToEmailAsync() {
            let result = await this.tryGet(`/api/calendar/ical/session/${this.code}/send`);

            this.$awn.tip('Calendar invite sent to your primary e-mail address. It should arrive in a few minutes.', {labels: {tip: 'Invite Sent'}});

            this.showCalendarDialog = false;
        },

        async downloadCalendarFile() {
            let dateFileName = moment(this.session.SessionStartDateTime).format(`yyyymmdd_hhmm`);
            let fileName = `${dateFileName}_${this.session.Name.replace(/[/\\?%*:|"<>]/g, '')}.ics`;
            let r = await this.tryGetBinary(`/api/calendar/ical/session/${this.code}`);
            let blob = new Blob([r]);
            console.log(r);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            //document.removeChild(link);
            this.showCalendarDialog = false;
        },
        
        onWebinarExitRequested() {
            this.$router.go(-1);
        },

        postExitAnalytics() {
            this.postAnalytics(
                AppState.attendee.Code,
                'SessionExit',
                this.code,
                'Session',
                AppState.show.Code);
        },

        async build() {
            this.submitting = true && !this.session;            

            try {
                let result = await this.tryGet(`/api/session/${AppState.show.Code}/${this.code}`);
                this.sessionModel = result.Result;
            } catch(Ex) {
                console.error(Ex)
            }

            this.postAnalytics(AppState.attendee.Code,'SessionDetails',this.code,'Session',AppState.show.Code);

            this.allowRehearsal = false;
            
            if (this.session.DiscussionWebinarStatus) {
                
                await this.checkCanRehearse();

            }

            this.submitting = false;
            this.$nextTick(() => {
                if(this.$route.query.atc) {
                    this.showCalendarDialog = true;
                }
            });
        },

        switchWebinarModeForce() {
            this.forceWebinarMode = !this.forceWebinarMode;
        },

        async checkCanRehearse() {
            this.allowRehearsal = false;
        
            if(!this.session.DiscussionCode) {
                return;
            }

            if(this.session.DiscussionWebinarStatus == "PublicManual") {
                this.allowRehearsal = true;
                return;
            }

            try {
                let result = await this.tryGet(`/api/webinar/checkcanrehearse/${AppState.show.Code}/${this.session.DiscussionCode}`);

                if(result.Result == 'canrehearse') {
                    this.allowRehearsal = true;
                }  

            } catch {
                this.allowRehearsal = false;
            }
        },

        onEntityUpdated(details) {
            if(details.EntityTypeCode != "SESSION" || details.EntityCode != this.code) {
                return;
            }

            this.build();
        },        

        onReconnectedToSignalR() {
            //this.build();
        },

        onFavoritesUpdated(favorites) {
            if(!favorites) {
                this.build();
            } else {

                let favorite = favorites.find(x => x.EntityTypeCode == 'SESSION' && x.EntityCode == this.session.Code);

                if(!favorite) {
                    return;
                }

                this.sessionModel.IsFavorite = favorite.IsActive;
            }

        },

       async onToggleFavorite() {
            if(!this.sessionModel.CanEditFavorite) {
                return;
            }

            window.$bus.$emit('request-toggle-favorite', {
                Type: "SESSION",
                Code: this.sessionModel.Session.Code,
                ReadOnly: false,
                AllowEditEntity: this.sessionModel.IsPageAdmin,
                Active: !this.sessionModel.IsFavorite
            })
        },


        onWebinarLiveChanged(args) {
            if(args.EntityTypeCode != "SESSION") {
                return;
            }

            if(args.EntityCode != this.code) {
                return;
            }

            this.sessionModel.Session.DiscussionWebinarStatus = args.WebinarStatus;


            if(this.sessionModel.Session.DiscussionWebinarStatus == 'Private') {
                this.forceWebinarMode = false;
                this.checkCanRehearse();
            }

            if(this.sessionModel.Session.DiscussionWebinarStatus == 'PublicManual') {
                this.allowRehearsal = true;
            }
        }
    },

    data() {
        return {
            checkLiveVideoTimeout: null,
            sessionModel: null,
            streamDetails: null,
            currentVideoSrc: null,
            allowRehearsal: false,
            forceWebinarMode: false,
            showCalendarDialog: false,
        }
    },

    beforeUnmount() {
        window.$bus.$emit('on-leave-video-page', {
            Route: `${AppState.show.Code}/session/${this.code}`
        });
        window.$bus.$off('entity-updated', this.onEntityUpdated);
        window.$bus.$off('connected-to-signalr', this.onReconnectedToSignalR);
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('WebinarLiveChange', this.onWebinarLiveChanged);
    },

    mounted() {
    },

    created: function() {
        this.build();
        window.$bus.$on('connected-to-signalr', this.onReconnectedToSignalR);
        window.$bus.$on('entity-updated', this.onEntityUpdated);
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('WebinarLiveChange', this.onWebinarLiveChanged);
    },
}
</script>