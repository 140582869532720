<template>
    <div class="page-side-panel">
        <div class="header-actions">
            <span class="title">Request Info</span>

            <button class="icomoon" @click="closeRequestBooking()">
                
            </button>
        </div>
        
        <div class="page-side-panel-content send-message"
             v-if="!submitting">
            <div>
                <textarea v-model="bookingRequest.Notes"
                            maxlength="500"
                            placeholder="Type your request here...">

                </textarea>

                <label>
                    <input v-model="bookingRequest.PleaseContact" type="checkbox">Share my E-mail Address
                </label>    

                <label v-if="canBookMeetings">
                    <input v-model="bookMeeting" type="checkbox">Book a Meeting
                </label>    

            </div>
            <template v-if="bookMeeting">
                <div class="input-control">
                    <label>
                        Choose Representative
                    </label>
                    <div class="info-link"
                        :class="{'active': !selectedRepCode}"
                        @click="selectedRepCode = null">

                        <div class="info-details">
                            <div class="info-link-title">
                                Choose for Me
                            </div>
                            <div class="info-link-subtitle">
                                Randomly select an available representative
                            </div>
                        </div>
                    </div>
                    <div v-for="rep in availableReps"
                        :key="rep.Code"
                        class="info-link"
                        :class="{'active': selectedRepCode == rep.Code}"
                        @click="selectedRepCode = rep.Code">

                        <img-g :src="getEntityPhotoFromTypeAndCode('attendee', rep.Code)"
                                fallbackSrc="/img/person.png" />
                        <div class="info-details">
                            <div class="info-link-title">
                                {{rep.DisplayName}}
                            </div>
                            <div class="info-link-subtitle"
                                 v-if="rep.Title">
                                {{rep.Title}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-control">
                    <label>
                        Meeting Time
                    </label>
                    <div v-for="slot in pickableTimeSlots"
                        :key="slot.StartDateTime"
                        class="info-link"
                        :class="{'active': slot.StartDateTime == bookingRequest.MeetingDateTime}"
                        @click="setMeetingSlot(slot)">

                        <div class="info-details">
                            <div class="info-link-title">
                                {{$filters.moment(slot.StartDateTime, 'llll')}} - {{$filters.moment(slot.EndDateTime, 'LT')}}
                            </div>
                            <div class="info-link-subtitle">
                                {{slot.DurationMinutes}} minutes
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="booking-actions">
                <button type="button" @click="closeRequestBooking()">Cancel</button>                   
                <button type="button" @click="sendBookingRequest()">Send Request</button>
            </div>
            <div class="submit-request" v-if="submittingRequest">
                <loading></loading>
            </div>
        </div>
        <div class="page-side-panel-content send-message"
             v-else>
            <loading></loading>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: [
        'attendee', 
        'code', 
        'typeCode', 
        'requestcode'
    ],

    computed: {
        pickableTimeSlots() {
            if(!this.selectedRepCode) {
                return this.slots;
            }

            return this.slots.filter(x => x.BookableWithCodes.indexOf(this.selectedRepCode) != -1);
        }
    },

    methods: {
        setMeetingSlot(slot) {
            if(this.bookingRequest.MeetingDateTime == slot.StartDateTime) {
                this.bookingRequest.MeetingDateTime = null;
                this.bookingRequest.DurationMinutes = null;
                this.bookingRequest.SlotCode = null;
                this.bookingRequest.AssignedToCode = null;
            } else {
                this.bookingRequest.MeetingDateTime = slot.StartDateTime;
                this.bookingRequest.DurationMinutes = slot.DurationMinutes;
                this.bookingRequest.SlotCode = slot.SlotCode
                this.bookingRequest.AssignedToCode = this.selectedRepCode;
            }
        },

        closeRequestBooking() {
            this.goBackOneRoute();
        },

        async sendBookingRequest() {
            this.submittingRequest = true;

            if(this.selectedRepCode) {
                this.bookingRequest.AssignedToCode = this.selectedRepCode;
            }

            try {
                let result = await this.tryPost('/api/appointment/set', JSON.stringify(this.bookingRequest), 'application/json');

                if(result.Result) {
                    window.$bus.$emit('showannouncementinternal', {
                        Body: "Your request for information has been sent.",
                        Subject: "Request Sent"
                    });

                    this.postAnalytics(
                        AppState.attendeeCode,
                        'SendAppointmentRequest:Sent',
                        this.code,
                        this.typeCode,
                        AppState.showCode);


                    this.closeRequestBooking();
                } else {
                    alert("Sorry. We couldn't send your request. Please try again later.");
                }

            } catch {
                alert("Sorry. We couldn't send your request. Please try again later.");
            }
            this.submittingRequest = false;

        },

        async build() {
            this.submitting = true;

            try {
                let slotDetails = await this.tryGet(`/api/appointments/slots/${this.typeCode.toUpperCase()}/${this.code}`);

                this.canBookMeetings = slotDetails.Result.CanBookMeetings || false;
                this.slots = slotDetails.Result?.Slots || [];
                this.availableReps = slotDetails.Result.Representatives || [];

            } catch {
                this.canBookMeetings = false;
                this.slots = [];
                this.availableReps = [];
            }

            this.bookingRequest = {
                EntityTypeCode: this.typeCode.toUpperCase(),
                EntityCode: this.code,
                FromCode: AppState.attendeeCode,
                FromName: AppState.attendee.DisplayName,
                Notes: '',
                SentDateTime: new Date(),
                PleaseContact: false,
                ShowCode: AppState.showCode,

                MeetingDateTime: null,
                MeetingRoomCode: null,
                MeetingStatus: null,
                DurationMinutes: null,
                SlotCode: null,

                AssignedToCode: null,
            };

            this.postAnalytics(
                AppState.attendeeCode,
                'SendAppointmentRequest',
                this.code,
                this.typeCode,
                AppState.showCode);

            this.submitting = false;
        }
    },

    data() {
        return {
            bookingRequest: null,
            submittingRequest: false,
            submitting: true,
            canBookMeetings: false,

            slots: [],
            availableReps: [],
            selectedRepCode: null,

            bookMeeting: false,
        }
    },
    
    mounted() {
        this.build();
    }
}
</script>