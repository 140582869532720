<script>
export default {
    name: 'vue-title',
    props: ['title', 'brand'],

    watch: {
        title: {
            immediate: true,
            handler() {
                this.updateTitle();
            }
        },

        showAlert: {
            immediate: true,
            handler() {
                this.updateTitle();
            }
        }
    },

    data() {
        return {
            showAlert: 0,
        }
    },

    methods: {

        updateTitle() {
            window.$bus.$emit('title-change', this.title);
            document.title = `${(this.showAlert > 0 ? `(${this.showAlert}) ` : '')}${this.title}${this.brand ? " | Tractus Event Passport" : ''}`;

        },

        onBadgeAlertTrigger(details) {
            this.showAlert = details.AnnouncementCount + details.ChatCount;
        }
    },

    render () {
    },

    created() {
        window.$bus.$on('trigger-app-badge-alert', this.onBadgeAlertTrigger);
    },

    beforeUnmount() {
        window.$bus.$off('trigger-app-badge-alert', this.onBadgeAlertTrigger);
    }
}
</script>