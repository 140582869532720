<template>
    <div ref="rootCameraContainer">
        <template v-if="isDebug">
            <div class="presenter-video">
                <img-g src='/img/person.png'
                        fallbackSrc="/img/person.png"/>

                <video src="/test.mp4" playsinline autoplay loop>
                </video>
                <loading-container />
                <div class="presenter-name fade-in-on-create"
                     :style="`scale: ${this.scale};`"
                     v-if="presenterName && showPresenterName || isDebug">
                    <span>
                        {{displayName}}
                    </span>
                </div>
            </div>
        </template>
        <template v-else-if="isConnecting">
            <div class="presenter-video">
                <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${code}/profile`"
                        fallbackSrc="/img/person.png"/>
                <loading-container />
                <div class="connecting-overlay">

                </div>
            </div>
        </template>
        <template v-else>
            <div class="presenter-video" @click="refreshStreamAttach">
                <img-g :src='participant.info.avatarUrl'
                        fallbackSrc="/img/person.png"/>
                <video v-show="stream"
                       playsinline
                       autoplay
                       muted
                       ref="output"
                       :class="{'mirror-video': mirror, 'blur-video': blurStream}">

                </video>
                <div v-if="blurStream"
                     class="stream-blur-placeholder">
                     <span>
                        You are sharing your screen.
                     </span>  
                </div>
                <div class="presenter-name fade-in-on-create"
                     :style="`scale: ${this.scale};`"
                     v-if="presenterName && showPresenterName || isDebug">
                     <span>
                        {{displayName}}
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate'
import { nextTick } from 'vue'

export default {
    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
            presenterName: '',
            scale: 1.0,
        }
    },

    props: [
        'isDebug', 
        'isConnecting', 
        'participant', 
        'stream', 
        'code', 
        'showCode',
        'showPresenterName',
        'mirrorVideo',
        'blurIfMyStream',
    ],

    computed: {
        attendeeCode() {
            return AppState?.attendeeCode;
        },

        displayName() {
            return this.isDebug
                ? "Debug Title Card With a Very Loooooooong Name"
                : this.presenterName;
        },

        mirror() {
            return this.mirrorVideo 
                && this.isMyStream;
        },

        isMyStream() {
            return this.attendeeCode
                && this.participant?.info?.externalId?.indexOf(this.attendeeCode) != -1;
        },

        blurStream() {
            return this.blurIfMyStream && this.isMyStream; 
        }
    },

    watch: {
        stream() {
            this.refreshStreamAttach();
        },

        participant() {
            this.presenterName = this.participant?.info?.name;
        },
    },

    methods: {
        refreshStreamAttach() {
            console.warn("REFRESH_STREAM_ATTACH", this.stream, this.$refs.output, this.participant);
            this.presenterName = this.participant?.info?.name;
            if(this.stream && this.$refs.output) {
                navigator.attachMediaStream(this.$refs.output, this.stream);
            }  
        },

        setupNameObserver() {
            let r = new ResizeObserver(e => {
                let scale = e[0].contentRect.width / 1920.0;

                this.scale = scale;
            });

            r.observe(this.$refs.rootCameraContainer);
        },
    },

    mounted() {
        this.refreshStreamAttach();
        window.$bus.$on('play-blocked-audio', this.refreshStreamAttach);
        this.setupNameObserver();
    },

    beforeUnmount() {
        window.$bus.$off('play-blocked-audio', this.refreshStreamAttach);
    }
}
</script>