<template>
    <div class="bookings-settings">
        <loading v-show="submitting"></loading>
        <div v-show="!submitting"
             class="bookings-settings-editor">

            <div class="button-group"
                 v-if="canAdjustScheduleSettings">
                <button :class="{'active': settingMode == 'schedule'}"
                        type="button"
                        @click="settingMode = 'schedule'">
                    Appointment Slots
                </button>
                <button :class="{'active': settingMode == 'email'}"
                        type="button"
                        @click="settingMode = 'email'">
                    E-Mails
                </button>
            </div>
            <template v-if="!selectedSlot && settingMode == 'email'">
                <h1>E-Mail Settings</h1>

                <div>
                    <div v-for="item in favoriteSettings"
                        :key="item.AttendeeCode"
                        class="info-link">

                        <div class="info-details">
                            <span class="info-link-title">
                                {{item.DisplayName}}
                            </span>
                            <span class="info-link-subtitle">
                                {{item.Email}}
                            </span>

                            <label>
                                <input type="checkbox"
                                    v-model="item.ReceiveAdminEmail"
                                    @change="onUpdateFavoriteSetting(item)">

                                Email New Messages
                            </label>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="canAdjustScheduleSettings && settingMode == 'schedule'">
                <h1 v-show="!selectedSlot">
                    Appointment Slots
                </h1>

                <div class="calendar-holder"
                    v-show="!selectedSlot">

                    <vue-cal :time-cell-height="100"
                            :events="events"
                            ref="calendar"
                            id="calendar"
                            :active-view="'day'"
                            :disable-views='["years", "year", "month"]'
                            :editable-events="{ title: false, drag: true, resize: true, delete: false, create: true }"
                            :snap-to-time="5"
                            :drag-to-create-threshold="0"
                            :sticky-split-labels="true"
                            :watch-real-time="true"
                            :on-event-click="onClickEvent"
                            @ready="scrollToCurrentTime"
                            @event-duration-change="onEventDrop"
                            @event-drag-create="onEventCreate"
                            @event-drop="onEventDrop">

                    </vue-cal>

                </div>
                <div v-if="selectedSlot">
                    <div class="input-control">
                        <label>Meeting Slot Details</label>

                        <b>{{$filters.moment(selectedSlot.StartDateTime, 'LL LT')}} to {{$filters.moment(selectedSlot.EndDateTime, 'LT')}}</b>
                    </div>

                    <div class="dual-input-control">
                        <div class="input-control">
                            <label>Meeting Length</label>
                            <select v-model="selectedSlot.SlotDurationMinutes">
                                <option :value="5">
                                    5 Minutes
                                </option>
                                <option :value="10">
                                    10 Minutes
                                </option>
                                <option :value="15">
                                    15 Minutes
                                </option>
                                <option :value="20">
                                    20 Minutes
                                </option>
                                <option :value="25">
                                    25 Minutes
                                </option>
                                <option :value="30">
                                    30 Minutes
                                </option>
                                <option :value="35">
                                    35 Minutes
                                </option>
                                <option :value="40">
                                    40 Minutes
                                </option>
                                <option :value="45">
                                    45 Minutes
                                </option>
                                <option :value="50">
                                    50 Minutes
                                </option>
                                <option :value="55">
                                    55 Minutes
                                </option>
                                <option :value="60">
                                    60 Minutes
                                </option>
                            </select>
                        </div>

                        <div class="input-control">
                            <label>Buffer Time</label>

                            <select v-model="selectedSlot.SlotBufferMinutes">
                                <option :value="0">
                                    No Buffer
                                </option>
                                <option :value="5">
                                    5 Minutes
                                </option>
                                <option :value="10">
                                    10 Minutes
                                </option>
                                <option :value="15">
                                    15 Minutes
                                </option>
                                <option :value="20">
                                    20 Minutes
                                </option>
                                <option :value="25">
                                    25 Minutes
                                </option>
                                <option :value="30">
                                    30 Minutes
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="input-control">
                        <label>Total Meetings Available</label>

                        <b>{{totalMeetingsForSelectedSlot}} meeting(s)</b>, <b>{{selectedSlot.SlotDurationMinutes}} minutes</b> each with <b>{{selectedSlot.SlotBufferMinutes}} minutes</b> between meetings.
                    </div>                
                    <div class="input-control">
                        <label>Hosts</label>
                        <div v-for="attendee in candidateHosts"
                            :key="attendee.Code"
                            @click="toggleHost(selectedSlot, attendee)"
                            class="info-link"
                            :class="{'active': selectedSlot.HostAttendeeCodes.indexOf(attendee.Code) != -1}">

                            <img-g :src="getEntityPhotoFromTypeAndCode('attendee', attendee.Code)"
                                    fallbackSrc="/img/person.png" />
                            <div class="info-details">
                                <div class="info-link-title">
                                    {{attendee.DisplayName}}
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="input-control">
                        <label>Meeting Location</label>
                        <select v-model="selectedSlot.MeetingTypeCode">
                            <option value="Internal">
                                Tractus Meeting
                            </option>
                            <option value="External">
                                External Meeting Link
                            </option>
                        </select>
                    </div>
                    <div class="input-control"
                        v-if="selectedSlot.MeetingTypeCode == 'External'">
                        <label>Meeting URL (Used for all meetings)</label>

                        <input placeholder="Meeting URL"
                            v-model="selectedSlot.ExternalMeetingUrl">
                    </div>

                    <div class="confirm-buttons">
                        <button @click="confirmDeleteSlot"
                                class="icomoon">
                            
                        </button>
                        <button @click="confirmSlotChanges"
                                class="icomoon">
                            
                        </button>
                    </div>             
                </div>
            </template>
        </div>        
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: [
        'attendee',
        'entityTypeCode',
        'entityCode',
        'canAdjustScheduleSettings'
    ],

    computed: {
        events() {
            return this.slots.map(x => {

                let startDate = new Date(x.StartDateTime);
                let endDate = new Date(x.EndDateTime);

                let totalMinutes = Math.floor((endDate - startDate) / 1000 / 60);

                let totalSlotsAvailable = Math.floor(totalMinutes / (x.SlotDurationMinutes + x.SlotBufferMinutes));

                let hosts = [];

                x.HostAttendeeCodes.forEach(y => {
                    let host = this.candidateHosts.find(z => z.Code == y);

                    if(!host) {
                        return;
                    }

                    hosts.push(`<div>${host.DisplayName}</div>`);
                });

                return {
                    slot: x,
                    start: new Date(x.StartDateTime),
                    end: new Date(x.EndDateTime),
                    title: `${totalSlotsAvailable} x ${x.SlotDurationMinutes} minute slots`,
                    content: `<i>Hosts:</i>${hosts.length > 0 ? hosts.join('') : '<div>No hosts assigned</div>'}`
                }
            });

        },

        totalMeetingsForSelectedSlot() {
            if(!this.selectedSlot) {
                return '';
            }

            let x = this.selectedSlot;

            let startDate = new Date(x.StartDateTime);
            let endDate = new Date(x.EndDateTime);

            let totalMinutes = Math.floor((endDate - startDate) / 1000 / 60);

            let totalSlotsAvailable = Math.floor(totalMinutes / (x.SlotDurationMinutes + x.SlotBufferMinutes));

            return totalSlotsAvailable;
        }
    },

    data() {
        return {
            slots: [],
            candidateHosts: [],
            selectedSlot: null,
            favoriteSettings: [],
            settingMode: '',
        }
    },

    methods: {
        async onUpdateFavoriteSetting(favorite) {
            await this.tryPost(`/api/favorites/settings/${this.entityTypeCode}/${this.entityCode}/toggle`,
                JSON.stringify(favorite),
                'application/json');
        },

        async confirmDeleteSlot() {

            if(!this.selectedSlot.IsNew) {
                let result = confirm("Are you sure you want to delete this meeting slot, and ALL associated booked meetings? This cannot be undone.");

                if(!result) {
                    return;
                }
            }

            if(!this.selectedSlot.IsNew) {
                this.submitting = true;
                await this.tryDelete(`/api/appointments/settings/${this.entityTypeCode}/${this.entityCode}/slot/${this.selectedSlot.SlotCode}`),
                this.submitting = false;

                this.scrollToCurrentTime();
            }

            let slotIndex = this.slots.indexOf(this.selectedSlot);

            if(slotIndex != -1) {
                this.slots.splice(slotIndex, 1);
            }

            this.selectedSlot = null;

        },

        async confirmSlotChanges() {
            await this.saveSlotChangesAsync(this.selectedSlot);

            if(this.selectedSlot.IsNew) {
                this.selectedSlot.IsNew = false;
            }

            this.selectedSlot = null;

            //this.scrollToCurrentTime();
        },

        async saveSlotChangesAsync(slot) {
            this.submitting = true;

            await this.tryPost(`/api/appointments/settings/${this.entityTypeCode}/${this.entityCode}/slot`,
                JSON.stringify(slot),
                'application/json');

            this.submitting = false;
        },

        toggleHost(slot, hostCode) {
            let index = slot.HostAttendeeCodes.indexOf(hostCode.Code);

            if(index == -1) {
                slot.HostAttendeeCodes.push(hostCode.Code);
            } else {
                slot.HostAttendeeCodes.splice(index, 1);
            }

        },

        async build() {
            this.submitting = true;

            let result = await this.tryGet(`/api/appointments/settings/${this.entityTypeCode}/${this.entityCode}`);

            this.slots = result.Result?.Slots || [];
            this.candidateHosts = result.Result?.CandidateHostAttendees || [];


            let favoriteSettings = 
                await this.tryGet(`/api/favorites/settings/${this.entityTypeCode}/${this.entityCode}`);

            this.favoriteSettings = favoriteSettings.Result;

            if(this.canAdjustScheduleSettings) {
                this.settingMode = 'schedule';
            } else {
                this.settingMode = 'email';
            }

            this.submitting = false;

            this.scrollToCurrentTime();
        },

        scrollToCurrentTime() {
            if(!this.canAdjustScheduleSettings) {
                return;
            }

            this.$nextTick(() => {
                const calendar = document.querySelector("#calendar .vuecal__bg");
                const hours = new Date().getHours() + new Date().getMinutes() / 60
                calendar.scrollTo({ top: hours * 160 })
            });
        },

        onClickEvent(e) {
            this.selectedSlot = null;

            this.$nextTick(() => {
                this.selectedSlot = e.slot;
            });
        },

        onEventCreate(e) {
            let newSlot = {
                ShowCode: AppState.showCode,
                SlotCode: this.getAzureCompatibleTimeStamp(),
                EntityTypeCode: this.entityTypeCode,
                EntityCode: this.entityCode,

                StartDateTime: e.start,
                EndDateTime: e.end,

                SlotDurationMinutes: 25,
                SlotBufferMinutes: 5,
                HostAttendeeCodes: this.candidateHosts.map(x => x.Code),
                MeetingTypeCode: 'Internal',
                ExternalMeetingUrl: '',
                IsNew: true,
            };

            this.slots.push(newSlot);

            this.selectedSlot = newSlot;
        },        

        async onEventDrop(e, ee) {

            e.event.slot.StartDateTime = e.event.start;
            e.event.slot.EndDateTime = e.event.end;

            await this.saveSlotChangesAsync(e.event.slot);
        },
    },

    created() {
        this.submitting = true;
    },

    mounted() {
        this.build();
    }
}
</script>