<template>
    <div v-if="submitting || !attendee"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else class="list-page-full">
        <vue-title :title="'My Appointments'" :brand="true"></vue-title>
        <div class="list-page-contents">
            <vue-cal :time-cell-height="160"
                    :events="appointments"
                    ref="calendar"
                    id="calendar"
                    :active-view="'week'"
                    :disable-views='["years", "year", "month"]'
                    :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
                    :snap-to-time="5"
                    :drag-to-create-threshold="0"
                    :sticky-split-labels="true"
                    :watch-real-time="true"
                    :on-event-click="onClickEvent"
                    @cell-click="deselectSelectedAppointment"
                    @ready="scrollToCurrentTime">

            </vue-cal>
        </div>

        <div v-if="selectedAppointment">
            <appointment-details :attendee="attendee"
                                 :appointment="selectedAppointment.meeting"
                                 @cancel-meeting="onCancelMeeting">
            </appointment-details>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

export default {
    props: ['attendee', 'code'],
    mixins: [
        Token,
        Common
    ],   

    watch: {
        code() {
            this.updateSelectedEvent(this.code);
        }
    },

    methods: {
        deselectSelectedAppointment() {
            if(!this.selectedAppointment) {
                return;
            }


            this.$router.replace({params: {code: null}});
        },

        async onCancelMeeting(meeting) {
            this.submitting = true;

            try {
                await this.tryDelete(`/api/appointments/my/${meeting.Id}`);
            } catch {
                this.$awn.alert("Could not cancel meeting at this time. Please try again later.");
            }

            if(meeting == this.selectedAppointment) {
                this.$router.replace({params: {code: null}});
            }

            await this.build();
        },

        async build() {
            // this.postAnalytics(
            //         AppState.attendeeCode,
            //         'Agenda',
            //         this.sectionCode,
            //         'Section', 
            //         AppState.showCode);
            let results = await this.tryGet('/api/appointments/my');

            this.appointments = results.Result.map(x => {
                return {
                    meeting: x,
                    start: new Date(x.MeetingDateTime),
                    end: new Date(new Date(x.MeetingDateTime).getTime() + (60000 * x.DurationMinutes)),
                    title: x.MeetingHostName, 
                    content: x.DisplayTitle
                }
            });

            if(this.code) {
                this.updateSelectedEvent(this.code);
            }

            this.submitting = false;

            this.scrollToCurrentTime();
        },

        scrollToCurrentTime() {

            this.$nextTick(() => {
                if(this.selectedAppointment) {
                    this.$refs.calendar.selectedDate = this.selectedAppointment.start;

                    const calendar = document.querySelector("#calendar .vuecal__bg");
                    const hours = this.selectedAppointment.start.getHours()
                    calendar.scrollTo({ top: hours * 160 })

                } else {

                    const calendar = document.querySelector("#calendar .vuecal__bg");
                    const hours = new Date().getHours() + new Date().getMinutes() / 60
                    calendar.scrollTo({ top: hours * 160 })

                }

            });
        },

        onClickEvent(e) {
            this.$router.push({
                params: {
                    code: e.meeting.Id
                }
            })
        },

        async updateSelectedEvent(code) {
            if(!code) {
                this.selectedAppointment = null;
                return;
            }

            if(this.appointments.length == 0) {
                await this.build();
            }

            this.selectedAppointment = this.appointments.find(x => x.meeting.Id == code);

            if(!this.selectedAppointment) {
                this.$router.replace({params: {code: null}});
            } 
        },

        async onAppointmentUpdated(e) {
            await this.build();
        },

        async onAppointmentDeleted(e) {
            
            if(e && this.selectedAppointment && e.Id == this.selectedAppointment.meeting.Id) {
                await this.updateSelectedEvent(null);
            }

            await this.build();
        }
    },

    data() {
        return {
            appointments: [],
            submitting: true,
            selectedAppointment: null,
        }
    },

    beforeUnmount() {
        window.$bus.$off('On-Appointment-Updated', this.onAppointmentUpdated);
        window.$bus.$off('On-Appointment-Deleted', this.onAppointmentDeleted);
    },

    mounted() {
        window.$bus.$on('On-Appointment-Updated', this.onAppointmentUpdated);
        window.$bus.$on('On-Appointment-Deleted', this.onAppointmentDeleted);
        this.build();
    },
}
</script>