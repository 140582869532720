<template>
    <div class="webinar-settings-panel">
        <div class="header-actions">
            <div class="title"
                 title="Polls">
                    Settings
            </div>
            
            <button @click="emitExitRequest"
                class="header-buttons icomoon">
                                        
            </button>
        </div>

        <div>
            <template v-if="entityTypeCode">
                <h3>Room Status</h3>
                <div class="button-group">
                    <button @click="changeDoorState('Public')"
                            :class="{'active': webinarStatus == 'Public'}">
                        Open
                    </button>
                    <button @click="changeDoorState('PublicManual')"
                            v-if="entityTypeCode == 'SESSION'"
                            :class="{'active': webinarStatus == 'PublicManual'}">
                        Open (Manual)
                    </button>
                    <button :class="{'active': webinarStatus == 'Private'}"
                            @click="changeDoorState('Private')">
                        Closed
                    </button>
                </div>
            </template>
            <template v-if="entityTypeCode && entityTypeCode == 'SESSION'">
                <h3>Ended Status</h3>
                <div class="button-group">
                    <button @click="changeEndedStatus(true)"
                            :class="{'active': isEnded}">
                        Ended
                    </button>
                    <button @click="changeEndedStatus(false)"
                            :class="{'active': !isEnded}">
                        Not Ended
                    </button>
                </div>
            </template>

            <template v-if="showSessionControls && entityTypeCode == 'SESSION'">
                <h3>Room Mode</h3>
                <div class="button-group">
                    <button @click="changeMode(true)"
                            :class="{'active': isOpen}">
                        Live Stream
                    </button>
                    <button @click="changeMode(false)"
                            :class="{'active': !isOpen}">
                        Waiting/Video
                    </button>
                </div>

                <h3>Session Timer</h3>
                <div class="button-group">
                    <button @click="toggleTimer(true)"
                            :class="{'active': showTimer}">
                        Show Timer
                    </button>
                    <button @click="toggleTimer(false)"
                            :class="{'active': !showTimer}">
                        Hide Timer
                    </button>
                </div>

                <h3>Waiting Room Video URL</h3>

                <input v-model="newVideoUrl"
                    placeholder="HLS Playlist URL">

                <div class="button-group" v-if="newVideoUrl">
                    <button :class="{'active': videoStartDateTime && showVideo}"
                            @click="startHlsVideoPlaybackAsync">
                        Play
                    </button>
                    <button @click="scrubHlsVideoPlaybackAsync">
                        Seek
                    </button>
                    <button :class="{'active': !showVideo || !videoStartDateTime}"
                            @click="stopHlsVideoPlaybackAsync">
                        Stop
                    </button>
                    <button @click="resetVideoUrl">
                        Reset
                    </button>
                </div>
            </template>
            <template v-if="entityTypeCode && isUserModerator || isUserAdmin">
                <h3>Room Mode</h3>

                <div class="button-group">

                    <button :class="{'active': !nonModeratorIsParticipant}"
                            @click="toggleParticipantVideo(false)">
                        Webinar
                    </button>
                    <button :class="{'active': nonModeratorIsParticipant}"
                            @click="toggleParticipantVideo(true)">
                        Meeting
                    </button>
                </div>
            </template>

            <h3>Show Non-Video Participants</h3>

            <div class="button-group">

                <button :class="{'active': !hidePresenterIfVideoOff}"
                        @click="toggleHideNonVideoParticipant(false)">
                    Yes
                </button>
                <button :class="{'active': hidePresenterIfVideoOff}"
                        @click="toggleHideNonVideoParticipant(true)">
                    No
                </button>
            </div>

            <h3>Allow Reactions</h3>

            <div class="button-group">

                <button :class="{'active': allowReactions}"
                        @click="toggleReactions(true)">
                    Yes
                </button>
                <button :class="{'active': !allowReactions}"
                        @click="toggleReactions(false)">
                    No
                </button>
            </div>

            <template v-if="isUserAdmin">
                <div class="button-group">
                    <button @click="emitShowTtsPanel">
                        Show TTS Panel
                    </button>
                </div>
            </template>

            <template v-if="isUserAdmin && entityTypeCode">
                <h3>Production Chat</h3>
                <div class="button-group">
                    <button @click="emitLaunchProductionChat">
                        Show Production Chat
                    </button>
                </div>
            </template>

            <template v-if="isUserAdmin && entityTypeCode">
                <h3>RTMP</h3>
                <input v-model="rtmpUrl"
                    placeholder="RTMP URL">

                <div class="button-group">
                    <button @click="changeRtmpStatus(true)">
                        Start
                    </button>
                    <button @click="changeRtmpStatus(false)">
                        Stop
                    </button>
                </div>
            </template>

        </div>
    </div>
</template>
<script>
import { isProxy, toRaw } from 'vue'

import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'

export default {
    computed: {
        roomStatus() {
            switch(this.webinarStatus)
            {
                case 'Public':
                    return 'Open';
                case 'PublicManual':
                    return 'Open (Manual)'
                default:
                    return 'Closed'
            }
        },
    },

    watch: {
        videoUrl() {
            this.newVideoUrl = this.videoUrl;
        }
    },

    mixins: [
        Common,
        Token,
    ],

    data() {
        return {
            newVideoUrl: '',
            ttsMessageToSend: '',
            rtmpUrl: '',
        }
    },

    mounted() {
        this.newVideoUrl = this.videoUrl;
    },

    props: [
        'showSessionControls',
        'isOpen',
        'entityTypeCode',
        'entityCode',
        'hidePresenterIfVideoOff',
        'showTimer',
        'nonModeratorIsParticipant',
        'webinarStatus',
        'isUserAdmin',
        'isUserModerator',
        'videoUrl',
        'isRecording',
        'isEnded',
        'roomCode',
        'showVideo',
        'videoStartDateTime',
        'allowReactions',
        'attendee',
        'isStreamingRtmp',
        'conference',
        'customLayoutCss',
    ],

    methods: {
        emitShowTtsPanel() {
            this.$emit('toggle-panel', 'tts');
        },

        async changeDoorState(doorState) {
            let message = '';

            if(this.entityTypeCode == 'SESSION') {

                switch(doorState) {
                    case 'Public':
                        message = 'Attendees on the session page will be moved into the webinar area. Is this OK?';
                        break;
                    case 'PublicManual':
                        message = 'Attendees on the session page can access the webinar via the "Join Room" button. They will not be automatically moved into the room. Is this OK?';
                        break;
                    case 'Private':
                    default:
                        message = 'This will close the webinar for everyone and move them back to the session page. Is this OK?';
                        break;

                }
            }
            else {

                switch(doorState) {
                    case 'Public':
                        message = 'Attendees on this page will be moved into the webinar area. Is this OK?';
                        break;
                    case 'Private':
                    default:
                        message = 'This will close the webinar for everyone and move them back to your page. Is this OK?';
                        break;

                }
            }

            let promise = new Promise(r => {
                this.$awn.confirm(
                    message,
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('Reject');
                    },
                    {
                        labels: {
                            confirm: "OK",
                            cancel: 'Cancel'
                        }
                    }
                )
            });

            let result = await promise;

            if(result != 'Yes') {
                return;
            }

            await this.tryPost(
                `/api/webinar/opendoor/${AppState.showCode}/${this.entityTypeCode}/${this.entityCode}/${doorState}`, 
                JSON.stringify({}), 
                "application/json");
        },

        async changeEndedStatus(isEnded) {
            let state = this.getCurrentStateForPost();

            state.IsEnded = isEnded;

            await this.postUpdateToWebinarAsync(state);
        },

        async resetVideoUrl() {
            let state = this.getCurrentStateForPost();

            state.ShowVideo = false;
            state.VideoStartDateTime = null;
            state.VideoUrl = '';

            await this.postUpdateToWebinarAsync(state);
        },

        async changeMode(isOpen) {
            let state = this.getCurrentStateForPost();

            state.ShowVideo = false;
            state.VideoStartDateTime = null;
            state.IsOpen = isOpen;
            state.IsEnded = false;

            await this.postUpdateToWebinarAsync(state);
        },

        emitLaunchProductionChat() {
            this.$emit('launch-production-chat');
        },

        async toggleReactions(direction) {
            let state = this.getCurrentStateForPost();

            state.AllowReactions = direction;

            await this.postUpdateToWebinarAsync(state);
        },

        async toggleHideNonVideoParticipant(hidePresenterIfVideoOff) {
            let state = this.getCurrentStateForPost();

            state.HidePresenterIfVideoOff = hidePresenterIfVideoOff;

            await this.postUpdateToWebinarAsync(state);
        },

        async toggleTimer(showTimer) {
            let state = this.getCurrentStateForPost();

            state.ShowTimer = showTimer;

            await this.postUpdateToWebinarAsync(state);
        },

        async toggleParticipantVideo(canAttendeesBeParticipants) {
            let state = this.getCurrentStateForPost();

            state.NonModeratorIsParticipant = canAttendeesBeParticipants;

            await this.postUpdateToWebinarAsync(state);
        },

        async startHlsVideoPlaybackAsync() {
            let state = this.getCurrentStateForPost();

            state.VideoStartDateTime = new Date();
            state.ShowVideo = true;

            await this.postUpdateToWebinarAsync(state);
        },

        async stopHlsVideoPlaybackAsync() {
            let state = this.getCurrentStateForPost();

            state.VideoStartDateTime = null;
            state.ShowVideo = false;

            await this.postUpdateToWebinarAsync(state);
        },

        async scrubHlsVideoPlaybackAsync() {
            this.$emit('scrub-video');
        },


        async openCallToAudience() {

            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you ready to go live? This will open the session to all attendees.",
                    () => {
                        r('Live');
                    },
                    () => {
                        r('Reject');
                    },
                    {
                        labels: {
                            confirm: "Go Live",
                            cancel: 'Cancel'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'Live';

            if(!shouldGoLive) {
                return false;
            }

            let state = this.getCurrentStateForPost();

            state.IsOpen = true;
            state.VideoStartDateTime = null;
            state.ShowVideo = false;

            await this.postUpdateToWebinarAsync(state);
        },

        async closeCallToAudience(finishSession) {
            let message = 
                finishSession 
                    ? "Are you sure you want to end the session? This will also stop the recording."
                    : "Are you sure you want to close the session? All attendees will be placed back in the waiting room.";

            let promise = new Promise(r => {
                this.$awn.confirm(
                    message,
                    () => {
                        r('End');
                    },
                    () => {
                        r('Reject');
                    },
                    {
                        labels: {
                            confirm: "End Session",
                            cancel: 'Cancel'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'End';

            if(!shouldGoLive) {
                return false;
            }

            if(finishSession && this.recording) {
                await this.startOrStopRecording();
            } 

            let state = this.getCurrentStateForPost();

            state.IsOpen = false;
            state.VideoStartDateTime = null;
            state.ShowVideo = false;
            state.IsEnded = finishSession;

            await this.postUpdateToWebinarAsync(state);
        },

        async postUpdateToWebinarAsync(stateToEmit) {
            console.log("POST")
            this.$emit('save-state', stateToEmit);
        },

        getCurrentStateForPost() {
            let currentState = {
                HidePresenterIfVideoOff: this.hidePresenterIfVideoOff,
                IsRecording: this.isRecording,
                IsEnded: this.isEnded,
                IsOpen: this.isOpen,
                RoomCode: this.roomCode,
                ShowTimer: this.showTimer,
                VideoUrl: this.newVideoUrl,
                VideoStartDateTime: this.videoStartDateTime,
                NonModeratorIsParticipant: this.nonModeratorIsParticipant,
                ShowVideo: this.showVideo,
                AllowReactions: this.allowReactions,
                IsStreaming: this.isStreamingRtmp,
                CustomLayoutCss: this.customLayoutCss,
            }

            return currentState;
        },

        emitExitRequest() {
            this.$emit('closerequest');
        },

        async build() {
            if(AppState.attendee.RoleCode == 'Administrator') {
                let rtmpDetails = await this.tryGet(`/api/webinar/rtmp/${AppState.showCode}/${this.roomCode}`);

                if(!rtmpDetails.Result) {
                    return;
                }

                this.rtmpUrl = rtmpDetails.Result?.RtmpUrl;
            }
        },

        async changeRtmpStatus(isStart) {
            let mode = isStart ? "start" : "stop";

            let conf = toRaw(this.conference);
            
            let url = `/api/webinar/rtmp/${AppState.showCode}/${this.roomCode}/${conf.id}/${mode}`;

            await this.tryPost(url, JSON.stringify({
                RtmpUrl: this.rtmpUrl
            }), "application/json");
        }
    },

    mounted() {
        this.build();
    }
}
</script>